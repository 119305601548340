import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const saveScheduleUser = async (
  accessToken: string,
    scheduleId: number,
    roleId: number,
    userId: number
) => {
    try {       
    const data = await fetchSecureData(
      "POST",
        `/api/ScheduleUser/Add?scheduleId=${scheduleId}&roleId=${roleId}&userId=${userId}`,
      accessToken
    );
    const scheduleUser = ((await data.json()) as any).value as IUserProfile;
    return scheduleUser;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/ScheduleUser/Add?scheduleId=${scheduleId}&roleId=${userId}&userId=${userId}`
    );
  }
  return {} as IUserProfile;
};

export default saveScheduleUser;

import { AnyAction } from "redux";
import { SET_SCHEDULE_EVENTS_LOADED } from "../actions/loadingScheduleEvents";

export const loadingScheduleEvents = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_SCHEDULE_EVENTS_LOADED:
      return action.scheduleEventsLoaded;
    default:
      return state;
  }
};

export default loadingScheduleEvents;

import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardFooter, Input, Label, FormGroup } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import { handleSaveSchedule } from "../../actions/schedules"; 

import AddTextField from "../../components/molecules/AddTextField";
import { settings } from "../../constants/index";
import { IStore } from "../../models/IStore";
import ISchedule from "../../models/ISchedule";
import { validateScheduleName } from "../../utils/index";
import { addEditRoleUserId } from "../../actions/editRoleUserIds";
import { right } from "@popperjs/core";

export interface IAddScheduleDetailsProps {
    accessToken: string;
    userId: number;
    schedules: ISchedule[];
    onHandleSaveSchedule: (
        accessToken: string,
        name: string,
        isActive: boolean,
        isEnable:boolean,
        userId: number
    ) => void;
}
export interface IAddScheduleDetailsState {
    name: string;
    isActive: boolean;    
   
}

class AddScheduleDetails extends React.Component<
    IAddScheduleDetailsProps,
    IAddScheduleDetailsState
> {
    public state = {
        name: "",
        // tslint:disable-next-line:object-literal-sort-keys
        isActive: false 
    };


    constructor(props: IAddScheduleDetailsProps) {
        super(props);
        this.save = this.save.bind(this);
        this.readyToSave = this.readyToSave.bind(this);
        this.isActiveChange = this.isActiveChange.bind(this);     
    }
    public readyToSave = () => {
        const { name } = this.state;
        if (
            name === undefined ||
            name === "" ||
            !validateScheduleName(name)
        ) {
            return false;
        }
        return true;
    };
    public save = (e: React.MouseEvent) => {
        const { accessToken, userId } = this.props;
        const { name, isActive } = this.state;
        // do save
        if (this.readyToSave()) {
            let isEnable = true;
            this.props.onHandleSaveSchedule(accessToken, name, isActive, isEnable, userId);
        }

        // callback and do next
    };
    public isActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isActive = e.target.checked;
        this.setState(() => ({ isActive }));
    };
    public render() {
        const { schedules } = this.props;
        const { allowDisableSchedules } = settings;
        const { name, isActive } = this.state;
        return (
            <Card>
                <CardTitle><h1>Add New Subscription</h1></CardTitle>
                <CardBody>
                    <AddTextField
                        noDuplicates={true}
                        isValid={validateScheduleName}
                        title="Subscription Name"
                        value={name}
                        existingValues={schedules.map(schedule => schedule.name)}
                        // tslint:disable-next-line:jsx-no-lambda
                        callback={(email: string) =>
                            this.setState(() => ({ name: email }))
                        }
                    /> 
                    <div style={{ margin: "0 0 0 1.30em" }}>
                                <Input
                                    type="checkbox"
                            defaultChecked={isActive}
                                    onChange={this.isActiveChange}
                            id="isActive"
                            name="isActive"
                                />
                        <Label for="isActive">Is Active</Label>                      
                        </div> 
                </CardBody>
                <CardFooter>
                    {
                        <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                            Save Changes
                        </Button>
                    }
                </CardFooter>
            </Card>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        schedules: state.schedules,
        userId: state.userProfile.id
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleSaveSchedule: (
        accessToken: string,
        name: string,
        isActive: boolean,
        isEnabled: boolean,
        userId: number
         
    ) => {
        dispatch(handleSaveSchedule(accessToken, name, isActive, isEnabled, userId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddScheduleDetails);

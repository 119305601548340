import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils/index";

export const savePaginatedReport = async (
  accessToken: string,
  name: string,
  displayName: string,
  groupId: string,
  reportId: string,  
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/PbiPaginatedReport/Add?name=${name}&displayName=${displayName}&groupId=${groupId}&reportId=${reportId}&isEnabled=${isEnabled}`,
      accessToken
    );
    const paginatedReport = ((await data.json()) as any).value as IPbiPaginatedReport;
      return paginatedReport;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/PbiPaginatedReport/Add?name=${name}&displayName=${displayName}&groupId=${groupId}&reportId=${reportId}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IPbiPaginatedReport;
};

export default savePaginatedReport;

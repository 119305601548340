
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    FormFeedback,    
    Input    
} from "reactstrap";
 
import { handleLoadPaginatedReports } from "../../actions/paginatedReports";
import DataLoading from "../../components/organisms/DataLoading";
import paths from "../../constants";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { IStore } from "../../models/IStore"; 
import FavoritesIcon from "../molecules/FavoritesIcon";
import NotFavoritesIcon from "../molecules/NotFavoritesIcon";
import IUserFavorite from "../../models/IUserFavorite"; 
import { handleAddFavorite, handleDeleteFavorite } from "../../actions/userFavorites";



export interface IListUserPaginatedReportsPageProps {
    accessToken: string;
    onHandleLoadPaginatedReports: (accessToken: string) => void;
    paginatedReports: IPbiPaginatedReport[];
    paginatedReportsLoaded: boolean;
    userId: number;
    activeRoleId: number;
    userFavoritesPaginatedReports: IUserFavorite[];
    onHandleAddFavorite: (accessToken: string, favorite: IUserFavorite) => void;
    onHandleDeleteFavorite: (accessToken: string, favoriteId: number) => void;
}

export interface IListUserPaginatedReportsPageState {
    filteredPaginatedReports: IPbiPaginatedReport[];
    matchedNameExact: boolean;
    name: string;
    noMatchingNames: boolean;
    userFavorites: IUserFavorite[];
}

class ListUserPaginatedReportsPage extends React.Component<
    IListUserPaginatedReportsPageProps,
    IListUserPaginatedReportsPageState
> {
    public isLoaded = false;
    public state = {
        filteredPaginatedReports: [] as IPbiPaginatedReport[],
        matchedNameExact: false,
        name: "",
        noMatchingNames: false,
        userFavorites: [] as IUserFavorite[]
    };

    constructor(props: IListUserPaginatedReportsPageProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.state.userFavorites = props.userFavoritesPaginatedReports;
    }




    public handleInputChange(e: any): void {
        e.preventDefault();
        const { paginatedReports } = this.props;

        const searchPaginatedReportDisplayName = e.target.value.toLowerCase();
        if (!searchPaginatedReportDisplayName) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        } else {
            const filteredPaginatedReports: IPbiPaginatedReport[] = paginatedReports.filter(
                (paginatedReport: IPbiPaginatedReport) =>
                    paginatedReport.displayName
                        .toLowerCase()
                        .includes(searchPaginatedReportDisplayName)
            );
            if (filteredPaginatedReports.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredPaginatedReports.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredPaginatedReports
            });
        }
    }



    public toggleFavorite = (paginatedReportId: number) => {
        const {
            accessToken,
            userId,
            activeRoleId,
            onHandleAddFavorite,
            onHandleDeleteFavorite
        } = this.props;


        if (this.state.userFavorites.find(fav => fav.reportId === paginatedReportId)) {

            const newUserFavorites: IUserFavorite[] = this.state.userFavorites.filter(fav => fav.reportId === paginatedReportId);
            onHandleDeleteFavorite(accessToken, newUserFavorites[0].id);


           // this.setState({ userFavorites: newUserFavorites  });

        
        }
        else {
            const newFav = {
                userProfileId: userId,
                roleId: activeRoleId,
                reportId: paginatedReportId,
                reportType: 'PAGINATED_REPORT',
                isEnabled: true
            } as IUserFavorite

            onHandleAddFavorite(accessToken, newFav);

            //this.setState({ userFavorites: newUserFavorites }, () => {
            //    console.log(this.state.userFavorites.length)
            //});

          
        };

        
         

    };
     


    public async componentDidMount() {
        const {
            accessToken,
            onHandleLoadPaginatedReports,
            paginatedReports,
            paginatedReportsLoaded
        } = this.props;
        this.isLoaded = true;
        if (this.isLoaded) {
            if (!paginatedReportsLoaded) {
                onHandleLoadPaginatedReports(accessToken);
            }
            this.setState({ filteredPaginatedReports: paginatedReports });
        }
    }

    public componentDidUpdate(prevProps: IListUserPaginatedReportsPageProps) {
        const { paginatedReports, userFavoritesPaginatedReports } = this.props;

        if (prevProps.paginatedReports !== paginatedReports) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        }

         if (prevProps.userFavoritesPaginatedReports !== userFavoritesPaginatedReports) {
            this.setState({
                userFavorites: userFavoritesPaginatedReports 
            })
    }
        }

    public render() {
        const { paginatedReports, paginatedReportsLoaded } = this.props;
        const {
            noMatchingNames,
            matchedNameExact,
            name,
            filteredPaginatedReports, userFavorites
        } = this.state;
        return (
            <React.Fragment>
                <h1 style={{ backgroundColor: "white" }}>Reports:</h1>
                {!paginatedReportsLoaded && <DataLoading dataTitle="Reports" />}
                {paginatedReportsLoaded && (
                    <React.Fragment>
                        <Card Style="width: 48rem; border-radius: 20% center">
                            <CardTitle tag="span">
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Search Report"
                                    onChange={this.handleInputChange}
                                    valid={matchedNameExact}
                                    invalid={noMatchingNames}
                                />
                                <FormFeedback valid={true}>
                                    {matchedNameExact && (
                                        <span>
                                            Paginated Report found! Select to edit
                                            {name}
                                        </span>
                                    )}
                                </FormFeedback>
                                <FormFeedback invalid="true">
                                    {noMatchingNames && (
                                        <span>No Reports match name entered </span>
                                    )}
                                </FormFeedback>
                            </CardTitle>
                            <CardBody>
                                {paginatedReports.length > 0 &&
                                    filteredPaginatedReports.map((paginatedReport: IPbiPaginatedReport) => (
                                        <div key={paginatedReport.id}>
                                            <span className="mr-2" onClick={() => this.toggleFavorite(paginatedReport.id)}>
                                                {
                                                    userFavorites.find(fav => fav.reportId === paginatedReport.id) ?
                                                        <FavoritesIcon />
                                                        :
                                                        <NotFavoritesIcon />
                                                }
                                            </span>

                                            <Link to={paths.pbiPaginatedReports.concat(paginatedReport.id.toString())}>
                                                {paginatedReport.displayName}
                                            </Link>
                                        </div>



                                    ))}
                            </CardBody>
                        </Card>

                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {

    return {
        accessToken: state.auth0.accessToken,
        paginatedReports: state.userPaginatedReports,
        paginatedReportsLoaded: state.loadingPaginatedReports,
        userFavoritesPaginatedReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "PAGINATED_REPORT") : state.userFavorites,
        userId: state.userProfile.id,
        activeRoleId: state.activeRoleId,
       // userFavorites: state.userFavorites.filter(fav => fav.reportType === "PAGINATED_REPORT")
    };
};

const mapDispatchToProps = (dispatch: any) => ({

    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },

    onHandleAddFavorite: (accessToken: string, newFav: IUserFavorite) => {             
            dispatch(handleAddFavorite(accessToken, newFav));
        },
     
    onHandleDeleteFavorite: (accessToken: string, id: number) => {
        dispatch(handleDeleteFavorite(accessToken, id));
    },

 
                
});






export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListUserPaginatedReportsPage);

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading
} from "reactstrap";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { IStore } from "../../models/IStore";
import paths from "../../constants";
import IUserFavorite from "../../models/IUserFavorite";
import FavoritesIcon from "./FavoritesIcon";
import Icon from "./Icon";
import Graph from "../../assets/icons/noun_chart bar_black.svg";


export interface IUserPaginatedReportsCardProps {
    paginatedReports: IPbiPaginatedReport[];
    userFavoritesPaginatedReports: IUserFavorite[];
}

const UserPaginatedReportsCard: React.SFC<IUserPaginatedReportsCardProps> = (
    props: IUserPaginatedReportsCardProps
) => {
    const { paginatedReports, userFavoritesPaginatedReports } = props;


    const filteredPaginatedReports = paginatedReports.filter(el => {
        return userFavoritesPaginatedReports.some(f => {
            return f.reportId === el.id;
        });
    });

    return (
        <React.Fragment>
            <ListGroup>
                {/*<ListGroupItemHeading style={{ backgroundColor: "white" }}>*/}
                {/*    <div className="textH2"> <Icon icon={Graph} text="Report" id="report" /> Your Favorite  Reports</div>*/}
                {/*</ListGroupItemHeading>*/}
                {filteredPaginatedReports.map(paginatedReport => (
                    <ListGroupItem action={true} variant="Light" key={paginatedReport.id}>
                        <Link to={paths.pbiPaginatedReports.concat(paginatedReport.id.toString())}>
                            <FavoritesIcon /> {paginatedReport.displayName}
                        </Link>
                    </ListGroupItem>
                ))}
               {/* {(paginatedReports === undefined || (paginatedReports !== undefined && paginatedReports.length === 0)) && (<span>You have no available paginatedReports</span>)}*/}
               
            </ListGroup>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        paginatedReports: state.userPaginatedReports,
        userFavoritesPaginatedReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "PAGINATED_REPORT") : state.userFavorites
    };

};

export default connect(mapStateToProps)(UserPaginatedReportsCard);

import * as React from "react";

import Role from "../../assets/icons/PeoplePassengers.svg";
import Icon from "./Icon";

const RoleIcon = () => {
  return (
    <React.Fragment>
          <Icon icon={Role} text="Site Administration" id="adminManage" />
    </React.Fragment>
  );
};

export default RoleIcon;

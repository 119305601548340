import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Table
} from "reactstrap";
import { handleLoadPaginatedReports } from "../../../actions/paginatedReports";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IPbiPaginatedReport from "../../../models/IPbiPaginatedReport";
import { IStore } from "../../../models/IStore";
 

export interface ISearchPaginatedReportsPageProps {
    accessToken: string;
    onHandleLoadPaginatedReports: (accessToken: string) => void;
    paginatedReports: IPbiPaginatedReport[];
    paginatedReportsLoaded: boolean;
}

export interface ISearchPaginatedReportsPageState {
    filteredPaginatedReports: IPbiPaginatedReport[];
    matchedNameExact: boolean;
    name: string;
    noMatchingNames: boolean;
}
class SearchPaginatedReportsPage extends React.Component<
    ISearchPaginatedReportsPageProps,
    ISearchPaginatedReportsPageState
> {
    public isLoaded = false;
    public state = {
        filteredPaginatedReports: [] as IPbiPaginatedReport[],
        matchedNameExact: false,
        name: "",
        noMatchingNames: false
    };
    constructor(props: ISearchPaginatedReportsPageProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    public handleInputChange(e: any): void {
        e.preventDefault();
        const { paginatedReports } = this.props;

        const searchPaginatedReportDisplayName = e.target.value.toLowerCase();
        if (!searchPaginatedReportDisplayName) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        } else {
            const filteredPaginatedReports: IPbiPaginatedReport[] = paginatedReports.filter(
                (paginatedReport: IPbiPaginatedReport) =>
                    paginatedReport.displayName
                        .toLowerCase()
                        .includes(searchPaginatedReportDisplayName)
            );
            if (filteredPaginatedReports.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredPaginatedReports.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredPaginatedReports
            });
        }
    }

    //public async componentWillMount() {
    //    const {
    //        accessToken,
    //        onHandleLoadPaginatedReports,
    //        paginatedReports,
    //        paginatedReportsLoaded
    //    } = this.props;
     
    //        if (!paginatedReportsLoaded) {
    //            onHandleLoadPaginatedReports(accessToken);
          
    //        this.setState({ filteredPaginatedReports: paginatedReports });
    //    }
    //}

    public componentDidMount() {
        const { accessToken, onHandleLoadPaginatedReports, paginatedReports, paginatedReportsLoaded } = this.props;
        this.isLoaded = true;
        if (this.isLoaded) {
            if (!paginatedReportsLoaded) {
                onHandleLoadPaginatedReports(accessToken);
            }
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        }
    }


    public componentDidUpdate(prevProps: ISearchPaginatedReportsPageProps) {
        const { paginatedReports } = this.props;
        if (prevProps.paginatedReports !== paginatedReports) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        }
    }

    public render() {
        const { paginatedReports, paginatedReportsLoaded } = this.props;
        const {
            noMatchingNames,
            matchedNameExact,
            name,
            filteredPaginatedReports
        } = this.state;
        return (
            <React.Fragment>
                <h1 style={{ backgroundColor: "white" }}>Report Administration</h1>
                {!paginatedReportsLoaded && <DataLoading dataTitle="Reports" />}
                {paginatedReportsLoaded && (
                    <React.Fragment>
                        <FormGroup>
                            <Card Style="width: 48rem; border-radius: 20% center">
                                <CardTitle>
                                    <Label for="name">Select Report To Edit</Label>
                                </CardTitle>
                                <CardBody tag="span">
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Filter Report"
                                        onChange={this.handleInputChange}
                                        valid={matchedNameExact}
                                        invalid={noMatchingNames}
                                    />
                                    <FormFeedback valid={true}>
                                        {matchedNameExact && (
                                            <span>
                                                Report found! Select to edit
                                                {name}
                                            </span>
                                        )}
                                    </FormFeedback>
                                    <FormFeedback invalid="true">
                                        {noMatchingNames && (
                                            <span>No Reports match name entered </span>
                                        )}
                                    </FormFeedback>
                                </CardBody>
                            </Card>
                        </FormGroup>
                        <Card>
                            <CardBody>
                                <Table striped={true} bordered> 
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Display Name</th>
                                            <th>Name</th>
                                            <th><div style={{ textAlign: "center" }}>Available for Scheduling</div></th>
                                            <th><div style={{ textAlign: "center" }}>Enabled</div></th>
                                            <th><div style={{ textAlign: "center" }}>Parameters Count</div></th>
                                            <th><div style={{ textAlign: "center" }}>Create Report Parameters Message</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedReports.length > 0 &&
                                            filteredPaginatedReports.map((paginatedReport: IPbiPaginatedReport) => (
                                                                                                 
                                                    <tr key={paginatedReport.id}>

                                                        <td>{paginatedReport.id}</td>
                                                        <td> <Link
                                                            to={paths.editPaginatedReport.concat(paginatedReport.id.toString())}
                                                        >
                                                            <span>{paginatedReport.displayName}</span>
                                                        </Link></td>
                                                      <td>{paginatedReport.name}</td>
                                                    <td><div style={{ textAlign: "center" }}><Input type="checkbox" checked={paginatedReport.isForScheduling} readOnly={true} /></div></td>
                                                    <td><div style={{ textAlign: "center" }}> <Input type="checkbox" checked={paginatedReport.isEnabled} readOnly={true} /></div></td>
                                                    <td><div style={{ textAlign: "center" }}> {paginatedReport.schedulingParametersCount}</div></td>
                                                    <td>{paginatedReport.createParametersMessage !== null && paginatedReport.createParametersMessage !== undefined && paginatedReport.createParametersMessage.includes('Error') ? <p className='text-danger' >{paginatedReport.createParametersMessage} </p> : <p className='text-success'>{paginatedReport.createParametersMessage} </p>}</td>
                                                    </tr>
                                                
                                            ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        paginatedReports: state.paginatedReports,
        paginatedReportsLoaded: state.loadingPaginatedReports
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPaginatedReportsPage);

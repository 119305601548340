import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { handleDeleteSchedule, handleDuplicateSchedule, handleLoadSchedules, handleExecuteSchedule } from "../../actions/schedules";
import DataLoading from "../../components/organisms/DataLoading";
import paths from "../../constants/index";
import ISchedule from "../../models/ISchedule";
import { IStore } from "../../models/IStore";
import IScheduleEvent from "../../models/IScheduleEvent";
import IScheduleRoleUser from "../../models/IScheduleRoleUser";
import { format, isBefore } from 'date-fns';
import { executeSchedule } from "../../api/executeSchedule";
import { IRole } from "../../models/IRole";
import { ICurrencyCode } from "../../models/ICurrencyCode";
 



export interface ISearchSchedulesPageProps {
    accessToken: string;
    schedules: ISchedule[];
    schedulesLoaded: boolean;
    scheduleEvents: IScheduleEvent[];
    userRolesWithUserProfiles: IScheduleRoleUser[];
    userProfileId: number;
    currencyCodes: ICurrencyCode[];
    activeUserRole: IRole;


    onHandleLoadSchedules: (accessToken: string, userId: number ) => void;
    onHandleDeleteSchedule: (accessToken: string, scheduleId: number) => void;
    onHandleCopySchedule: (accessToken: string, scheduleId: number, userId: number) => void;
    onHandleExecuteSchedule: (accessToken: string, eventID: number, scheduleId: number, userId: number) => void;
     
}

export interface ISearchSchedulesPageState {
    filteredSchedules: ISchedule[];
    matchedNameExact: boolean;
    name: string;
    noMatchingNames: boolean;
    showDeleteModal: boolean;
    showRunModal: boolean;
    scheduleIdForDelete: number;
    scheduleIdForRun: number;
    executeMessage: string;
    currency: ICurrencyCode;
}

class SearchSchedulesPage extends React.Component<
    ISearchSchedulesPageProps,
    ISearchSchedulesPageState
> {
    public isLoaded = false;
    public state = {
        filteredSchedules: [] as ISchedule[],
        matchedNameExact: false,
        name: "",
        noMatchingNames: false,
        showDeleteModal: false,
        showRunModal: false,
        scheduleIdForDelete: 0,
        scheduleIdForRun: 0,
        executeMessage: "",
        currency: {} as ICurrencyCode
    };

    constructor(props: ISearchSchedulesPageProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.removeSchedule = this.removeSchedule.bind(this);
        this.copySchedule = this.copySchedule.bind(this);
        this.getEventName = this.getEventName.bind(this);
        this.getStatusName = this.getStatusName.bind(this);
        this.getUserName = this.getUserName.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleRunModal = this.toggleRunModal.bind(this);
        this.toggleDeleteModalEmpty = this.toggleDeleteModalEmpty.bind(this);
        this.toggleRunModalEmpty = this.toggleRunModalEmpty.bind(this);


        this.getSubscriptionName = this.getSubscriptionName.bind(this);  
        this.getDateAsString = this.getDateAsString.bind(this);

        this.runSchedule = this.runSchedule.bind(this);
    }

     

    public runSchedule = async (scheduleId: number) => {

        const { accessToken, onHandleExecuteSchedule, userProfileId } = this.props;
        const { filteredSchedules } = this.state;
        const schedule = filteredSchedules.find(x => x.id === scheduleId);

        if (schedule !== undefined) {
         //   await onHandleExecuteSchedule(accessToken, schedule.scheduleEventId, schedule.id, userProfileId);

            const result = await executeSchedule(accessToken, schedule.scheduleEventId, scheduleId, userProfileId);
           
            this.setState(() => ({ executeMessage: result }));
        }
         
       //this.toggleDeleteModalEmpty();
    }


    
    public toggleRunModal(scheduleId: number) {
        this.setState({ showRunModal: !this.state.showRunModal });
        this.setState({ scheduleIdForRun: scheduleId });
    }


    public toggleDeleteModal(scheduleId: number) {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
        this.setState({ scheduleIdForDelete: scheduleId });
    }


    public toggleRunModalEmpty() {

        this.setState({ showRunModal: !this.state.showRunModal }); 
        this.setState(() => ({ executeMessage: "" }));
    }

    public toggleDeleteModalEmpty() {

        this.setState({ showDeleteModal: !this.state.showDeleteModal });

    }

    public getDateAsString(date: Date) {

        let result = "";
        if (date !== undefined && date !== null) {
            result = format(new Date(date), 'MM/dd/yyyy');
        }
        else {
            result = format(new Date(), 'MM/dd/yyyy');
        }

        return result;
    }


    public removeSchedule = async () => {

        const { scheduleIdForDelete } = this.state;
        const { accessToken, onHandleDeleteSchedule, onHandleLoadSchedules, userProfileId } = this.props;
        await onHandleDeleteSchedule(accessToken, scheduleIdForDelete);
        onHandleLoadSchedules(accessToken, userProfileId);
        this.toggleDeleteModalEmpty();
    }

    public copySchedule = async (scheduleId: number) => {
        const { accessToken, onHandleCopySchedule, userProfileId, onHandleLoadSchedules } = this.props;
        await onHandleCopySchedule(accessToken, scheduleId, userProfileId);
        onHandleLoadSchedules(accessToken, userProfileId);
    }


    public getStatusName = (schedule: ISchedule) => {
        var status: string;
     
        if (isBefore(new Date (schedule.lastRunDate), new Date())) {
            status = "Expired";
        }
        else {
            if (schedule.isActive) {
                status = "Active";
            }
            else {
                status = "Paused";
            }
        }

        return status;

    };


    public getEventName = (schedule: ISchedule) => {
        const { scheduleEvents } = this.props;
        return scheduleEvents.find(x => x.id === schedule.scheduleEventId)?.name;

    };

    public getUserName = (userProfileId: number) => {
        const { userRolesWithUserProfiles } = this.props;
        return userRolesWithUserProfiles.find(x => x.userProfileId === userProfileId)?.emailAddress;

    };

    public getSubscriptionName = (scheduleId: number) => {
        const { schedules } = this.props;
        return schedules.find(x => x.id === scheduleId)?.name;

    };

    public handleInputChange(e: any): void {
        e.preventDefault();
        const { schedules } = this.props;

        const searchScheduleDisplayName = e.target.value.toLowerCase();
        if (!searchScheduleDisplayName) {
            this.setState({
                filteredSchedules: schedules
            });
        } else {
            const filteredSchedules: ISchedule[] = schedules.filter((schedule: ISchedule) =>
                schedule.name.toLowerCase().includes(searchScheduleDisplayName)
            );
            if (filteredSchedules.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredSchedules.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredSchedules
            });
        }
    }

    public async componentDidMount() {
        const {accessToken, onHandleLoadSchedules, schedules, schedulesLoaded,
            activeUserRole,
            currencyCodes, userProfileId } = this.props;
        this.isLoaded = true;
        if (this.isLoaded) {
           // if (!schedulesLoaded) {
                onHandleLoadSchedules(accessToken, userProfileId);
          //  }
            this.setState({ filteredSchedules: schedules });
        }
        if (activeUserRole === undefined) {

            const currency = (currencyCodes.find(
                cc => cc.id === 1
            ) as ICurrencyCode);

            throw new Error(
                "At least one role has not been defined, so default CurrencyCode to AUD"
            );
        } else {
            const currency = (currencyCodes.find(
                cc => cc.id === activeUserRole.currencyCodeId
            ) as ICurrencyCode);
            await this.setState(() => ({ currency }));
        }
    }

    public componentDidUpdate(prevProps: ISearchSchedulesPageProps) {
        const { schedules } = this.props;
        if (prevProps.schedules !== schedules) {
            this.setState({ filteredSchedules: schedules });
        }
    }

    public getFormattedDateStr(dateStr: string) {
        const { currency } = this.state;

        if (dateStr === undefined) return '';
        if (dateStr === '') return '';


        var result = '';
        var delemeter = '/';
        if (dateStr.includes("-")) {
            delemeter = '-';
        }

        var dateItems = dateStr.split(delemeter);
        var year = parseInt(dateItems[0]);
        var month = parseInt(dateItems[1]);
        var day = parseInt(dateItems[2]);


        if (currency.dateFormat === 'MM/dd/yyyy') {
            result = [
                month,
                day,
                year
            ].join('/');
        }

        if (currency.dateFormat === 'dd/MM/yyyy') {
            result = [
                day,
                month,
                year
            ].join('/');
        }

        if (currency.dateFormat === 'yyyy-MM-dd') {
            result = [
                year,
                month,
                day
            ].join('-');
        }

        return result;
    }


        



    public manualSave(scheduleId:number) {

        const url = `http://localhost:7168/api/Function1?scheduleId=` + scheduleId;
        //window.open(window.location.href, '_blank');

        window.open(url, "_blank");

    }



 
    public render() {
        const { schedules, schedulesLoaded } = this.props;
        const {
            noMatchingNames,
            matchedNameExact,
            name,
            filteredSchedules,
            scheduleIdForDelete,
            scheduleIdForRun,
            executeMessage
            , currency
        } = this.state;

        if (schedules.length > 0) {
            schedules.sort((a, b) =>
                a.id < b.id ? 1 : -1
            ); 
        }
        var i = 0;
        var showResult:boolean = false;
        if (executeMessage !== '') {
            showResult = true; 
        }
        return (
            <React.Fragment>
                <h1 style={{ backgroundColor: "white" }}>Subscriptions Administration</h1>
                {!schedulesLoaded && <DataLoading dataTitle="Schedules" />}
                {schedulesLoaded && (
                    <React.Fragment>
                        <Modal
                            isOpen={this.state.showDeleteModal}
                            toggle={this.toggleDeleteModalEmpty}
                        >
                            <ModalHeader toggle={this.toggleDeleteModalEmpty}>
                                Delete Subscription
                            </ModalHeader>
                            <ModalBody>
                                Are you sure you want to delete subscription: <br /><b>"{this.getSubscriptionName(scheduleIdForDelete)}"</b>{"? "}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.removeSchedule}>
                                    Confirm Delete
                                </Button>{" "}
                                <Button color="secondary" onClick={this.toggleDeleteModalEmpty}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                        
                        <Modal
                            isOpen={this.state.showRunModal}
                            toggle={this.toggleRunModalEmpty}
                        >
                            <ModalHeader toggle={this.toggleRunModalEmpty}>
                                Run Subscription
                            </ModalHeader>
                            <ModalBody>
                                {!showResult &&(
                                   <div>Are you sure you want to execute subscription < br /><b>"{this.getSubscriptionName(scheduleIdForRun)}"</b><br /> and deliver reports to all recipients ?</div>
                                )}
                                {showResult && (
                                    <div> {executeMessage }</div>
                                )}

                            </ModalBody>
                            <ModalFooter>
                                {!showResult && (
                                    <div >
                                        <Button className='runScheduleModal' color="danger" onClick={() => this.runSchedule(scheduleIdForRun)}>Run</Button>
                                        <Button className='runScheduleModal' color="secondary" onClick={this.toggleRunModalEmpty}>Cancel</Button>
                                        </div>
                                 )} 
                                {showResult && (
                                    <div>
                                        <Button color="success" onClick={this.toggleRunModalEmpty}>OK</Button> 
                                    </div>
                                )}
                            </ModalFooter>
                        </Modal>

                        <FormGroup>
                            <Card>
                                <CardBody tag="span">
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Search Subscription"
                                        onChange={this.handleInputChange}
                                        valid={matchedNameExact}
                                        invalid={noMatchingNames}
                                    />
                                    <FormFeedback valid={true}>
                                        {matchedNameExact && (
                                            <span>
                                                Subscription found! Select to edit
                                                {name}
                                            </span>
                                        )}
                                    </FormFeedback>
                                    <FormFeedback invalid="true">
                                        {noMatchingNames && (
                                            <span>No schedules match name entered </span>
                                        )}
                                    </FormFeedback>
                                </CardBody>
                            </Card>
                        </FormGroup>
                        <Card>
                            <CardBody>
                                <Table striped={true} bordered>
                                    <thead>
                                        <tr key={i++}>
                                            <th>#</th>
                                            <th>Subscriptions</th>
                                            <th>Status</th>
                                            <th>Event</th>
                                            <th>Start Day</th>
                                            <th>End Day</th>
                                            <th>Created by</th>
                                            <th>Edited by</th>
                                            <th>Job Id</th>
                                            <th>Job Run Date</th>
                                            <th>Job Name</th>
                                            <th>Job Status</th>
                                            <th>Job Run Count</th>
                                            <th></th>
                                            <th></th>
                                            <th></th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedules.length > 0 &&
                                            filteredSchedules.map((schedule: ISchedule) => (
                                                <tr key={i++}>
                                                    <td>{schedule.id}</td>
                                                    <td><Link to={paths.editSchedule.concat(schedule.id.toString())}>{schedule.name}</Link></td>
                                                    {
                                                        this.getStatusName(schedule) === 'Active' ? <td><p className='text-success'>Active</p></td>:''
                                                    }
                                                    {
                                                        this.getStatusName(schedule) === 'Paused' ? <td><p className='text-danger'>Paused</p></td>:''
                                                    }
                                                    {
                                                        this.getStatusName(schedule) === 'Expired' ? <td><p className='text-secondary'>Expired</p></td>:''
                                                    } 
                                                    <td>{this.getEventName(schedule)}</td>
                                                    {/*    <td>{Moment(schedule.firstRunDate).format('L')}</td>*/}
                                                    {/*<td>{Moment(schedule.lastRunDate).format('L')}</td>*/} 
                                                    <td>{this.getFormattedDateStr(schedule.firstRunDate) } </td>
                                                    <td>{this.getFormattedDateStr(schedule.lastRunDate)}</td>
                                                    <td>{this.getUserName(schedule.createdBy)}</td>
                                                    <td>{this.getUserName(schedule.updatedBy)}</td>
                                                    <td>{schedule.jobId}</td>
                                                    <td>{this.getFormattedDateStr(schedule.jobRunDate)} </td>
                                                    <td>{schedule.jobName}</td>                                                    
                                                    {
                                                        schedule.jobStatus === 'FATALFAIL' ? <td><p className='text-danger'>{schedule.jobStatus}</p></td> : <td><p className='text-success'>{schedule.jobStatus}</p></td> 
                                                    }
                                                    
                                                    <td>{schedule.jobRunCount}</td>                                                      
                                                    <td><Button color="secondary" size="sm" onClick={() => this.manualSave(schedule.id)}>Log</Button></td>
                                                    {/* <td><Button color="danger" size="sm" onClick={() =>this.removeSchedule(schedule.id)} >Delete</Button></td>*/}
                                                    <td><Button color="danger" size="sm" onClick={() => this.toggleDeleteModal(schedule.id)} >Delete</Button></td>
                                                    <td><Button color="info" size="sm" onClick={() => this.copySchedule(schedule.id)}>Copy</Button></td>
                                                    <td><Button color="info" size="sm" onClick={() => this.toggleRunModal(schedule.id)}>Run</Button></td>
                                                </tr>

                                            ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        schedules: state.schedules,
        schedulesLoaded: state.loadingSchedules,
        scheduleEvents: state.scheduleEvents,
        userRolesWithUserProfiles: state.userRolesWithUserProfiles,
        userProfileId: state.userProfile.id,
           activeUserRole: state.userRoles.find(
               role => role.id === state.activeRoleId
           ) as IRole,
        currencyCodes: state.currencyCodes
    };
};

const mapDispatchToProps = (dispatch: any) => ({

    onHandleLoadSchedules: (accessToken: string, userId: number) => {
        dispatch(handleLoadSchedules(accessToken, userId));
    },

    onHandleDeleteSchedule: (
        accessToken: string,
        scheduleId: number
    ) => {
        dispatch(handleDeleteSchedule(accessToken, scheduleId));
    },

    onHandleCopySchedule: (
        accessToken: string,
        scheduleId: number,
        userId: number
    ) => {
        dispatch(handleDuplicateSchedule(accessToken, scheduleId, userId));
    },

    onHandleExecuteSchedule: (
        accessToken: string,
        eventId:number,
        scheduleId: number,
        userId: number
    ) => {
        dispatch(handleExecuteSchedule(accessToken, eventId, scheduleId, userId));
    }


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchSchedulesPage);

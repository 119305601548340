import * as React from "react";
import Manage from "../../assets/icons/bookmark.svg";
import Icon from "./Icon";

const BookmarkIcon = () => {
    var smallStyle = {
        height: 18,
        margin: "0px 8px 0px 0px" 
    }; 

  return (
    <React.Fragment>
          <Icon icon={Manage} text="Bookmark Icon" id="bookmarkIcon" style={smallStyle} />
    </React.Fragment>
  );
};

export default BookmarkIcon;

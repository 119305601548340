import IScheduleEvent from "../models/IScheduleEvent";
import { fetchSecureData } from "../utils/index";

export const getScheduleEvents = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    "/api/ScheduleEvent/GetScheduleEvents",
    accessToken
  );
  const scheduleEvents = ((await data.json()) as any).value as IScheduleEvent[];
  if (scheduleEvents !== undefined) {
    return scheduleEvents;
  } else {
    return [] as IScheduleEvent[];
  }
};

export default getScheduleEvents;

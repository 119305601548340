import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import { handleLoadUserProfiles } from "../../../actions/profiles";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";

export interface ISearchUsersPageProps {
  accessToken: string;
  onHandleLoadUsers: (accessToken: string) => void;
  users: IUserProfile[];
  usersLoaded: boolean;
}

export interface ISearchUsersPageState {
  emailAddress: string;
  filteredUsers: IUserProfile[];
  matchedEmailExact: boolean;
  noMatchingEmails: boolean;
}

class SearchUsersPage extends React.Component<
  ISearchUsersPageProps,
  ISearchUsersPageState
> {
  public isLoaded = false;
  public state: ISearchUsersPageState = {
    emailAddress: "",
    filteredUsers: [] as IUserProfile[],
    matchedEmailExact: false,
    noMatchingEmails: false
  };
  constructor(props: ISearchUsersPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { users } = this.props;

    const searchEmail = e.target.value.toLowerCase();
    if (!searchEmail) {
      this.setState({
        filteredUsers: users
      });
    } else {
      const filteredUsers: IUserProfile[] = users.filter((user: IUserProfile) =>
        user.emailAddress.toLowerCase().includes(searchEmail)
      );
      if (filteredUsers.length === 1) {
        this.setState({ matchedEmailExact: true, noMatchingEmails: false });
      } else if (filteredUsers.length === 0) {
        this.setState({ noMatchingEmails: true, matchedEmailExact: false });
      } else {
        this.setState({ matchedEmailExact: false, noMatchingEmails: false });
      }
      this.setState({
        filteredUsers
      });
    }
  }
  public componentDidMount() {
    const { accessToken, onHandleLoadUsers, users, usersLoaded } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!usersLoaded) {
        onHandleLoadUsers(accessToken);
      }
      this.setState({ filteredUsers: users });
    }
  }

  public componentDidUpdate(prevProps: ISearchUsersPageProps) {
    // const { users } = this.props;
    // if (prevProps.users !== users) {
    //   this.setState({ filteredUsers: users });
    // }
  }
  public render() {
    const { users, usersLoaded } = this.props;
    const {
      noMatchingEmails,
      matchedEmailExact,
      emailAddress,
      filteredUsers
    } = this.state;

    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>User Administration</h1>
        {!usersLoaded && <DataLoading dataTitle="Users" />}
        {usersLoaded && (
          <React.Fragment>
            <FormGroup>
                        <Card Style="width: 48rem; border-radius: 20% center">
                <CardTitle>
                  <Label for="emailAddress">Select Email Address To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="email"
                    name="emailAddress"
                    id="emailAddress"
                    placeholder="Filter user email address"
                    onChange={this.handleInputChange}
                    valid={matchedEmailExact}
                    invalid={noMatchingEmails}
                  />
                  <FormFeedback valid={true}>
                    {matchedEmailExact && (
                      <span>
                        User Found! Click to edit
                        {emailAddress}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingEmails && (
                      <span>No users match email entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
                    <Card Style="width: 48rem; border-radius: 20% center">
              <CardBody>
                {users.length > 0 &&
                  filteredUsers.map((user: IUserProfile) => (
                    <div key={user.id}>
                      <Link to={paths.editUser.concat(user.id.toString())}>
                        <span>{user.emailAddress}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    users: state.profiles,
    usersLoaded: state.loadingUsers
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadUsers: (accessToken: string) => {
    dispatch(handleLoadUserProfiles(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchUsersPage);

import * as React from "react";

export interface INavListItemProps {
  children: React.ReactNode;
}

const NavListItem: React.SFC<INavListItemProps> = (
  props: INavListItemProps
) => {
  const { children } = props;
    return <li className="nav-item mx-4">{children}</li>;
};

export default NavListItem;

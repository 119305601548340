import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {

    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
    ButtonDropdown,
    Table,
    Col,
    Row,
    Input
} from "reactstrap";

import paths from "../../constants";
import Text from "../atoms/Text";

import "../../assets/styles/Bookmarks.css";
import Navbar from "../atoms/Navbar";
import NavListItem from "../atoms/NavListItem";
import BookmarkActiveIcon from "../molecules/BookmarkActiveIcon";
import BookmarkIcon from "../molecules/BookmarkIcon";

import { IStore } from "../../models/IStore";
import IPbiBookmark from "../../models/IPbiBookmark";
import IRole from "../../models/IRole";
import IUserProfile from "../../models/IUserProfile";

import { handleSaveBookmark, handleDeleteBookmark, handleUpdateBookmark } from "../../actions/bookmarks";


import FavoritesIcon from "../molecules/FavoritesIcon";
import NotFavoritesIcon from "../molecules/NotFavoritesIcon";
import DeleteIcon from "../molecules/DeleteIcon";
import RenameIcon from "../molecules/RenameIcon";
import UpdateIcon from "../molecules/UpdateIcon";



export interface IBookmarkDropDownMenuProps extends RouteComponentProps {
    accessToken: string;
    dashboardId: number;
    activeUserRole: IRole;
    activeUser: IUserProfile,
    bookmarks: IPbiBookmark[],
    loadingEmailedPbiDashboard: boolean,

    onHandleLoadBookmarks: (accessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => void;
    onHandleUpdateBookmark: (accessToken: string, bookmark: IPbiBookmark) => void;
    onHandleDeleteBookmark: (accessToken: string, id: number) => void;
    onHandleSaveBookmark: (
        accessToken: string,
        bookmark: IPbiBookmark
    ) => void;

    getBookmarkCallback: () => Promise<string>;
    setBookmarkCallback: (id: number) => void;
    clearBookmarkCallback: () => void;
}


export interface IBookmarkDropDownMenuState {
    capturedBookmark: string;
    bookmarks: IPbiBookmark[];
    activeBookmarkId: number;
    dropdownOpen: boolean;
    leaveDropdownOpen: boolean;
    isEditMode: boolean;
    editBookmarkId: number;
}



class BookmarkDropDownMenu extends React.Component<IBookmarkDropDownMenuProps, IBookmarkDropDownMenuState> {

    public state: any = {
        isReady: false,
        capturedBookmark: "",
        bookmarks: [] as IPbiBookmark[],
        activeBookmark: 0,
        dropdownOpen: false,
        leaveDropdownOpen: false,
        isEditMode: false,
        editBookmarkId: 0
    };

    constructor(props: IBookmarkDropDownMenuProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.addBookmark = this.addBookmark.bind(this);
        this.renameBookmark = this.renameBookmark.bind(this);
        this.deleteBookmark = this.deleteBookmark.bind(this);
        this.makeDefault = this.makeDefault.bind(this);
        this.setBookmark = this.setBookmark.bind(this);
        this.getActiveStyle = this.getActiveStyle.bind(this);
        this.clearBookmarks = this.clearBookmarks.bind(this);
        this.nameChange = this.nameChange.bind(this);
        //    this.nameFocus = this.nameFocus.bind(this);
        this.nameBlur = this.nameBlur.bind(this);
        this.showName = this.showName.bind(this);
        this.nameSave = this.nameSave.bind(this);
    }

    public toggle() {
        const {
            isEditMode,
            dropdownOpen
        } = this.state;


        if (isEditMode === true && dropdownOpen === true) {
            this.setState({ dropdownOpen: true })
        }
        else {
            this.setState({ dropdownOpen: !dropdownOpen })
        }
    }

    public makeDefault = async (id: number) => {
        const { accessToken, onHandleUpdateBookmark, bookmarks } = this.props;

        let bkmrkArr: IPbiBookmark[] = bookmarks.filter(
            b => b.isDefault === true) as IPbiBookmark[];

        if (bkmrkArr.length > 0) {
            for (var i = 0; i < bkmrkArr.length; i++) {
                var bkmrk = bkmrkArr[i];
                bkmrk.isDefault = false;

                onHandleUpdateBookmark(
                    accessToken,
                    bkmrk
                );
            }
        }

        let bkmrkDefault: IPbiBookmark = bookmarks.find(
            b => b.id === id) as IPbiBookmark;

        bkmrkDefault.isDefault = true;

        onHandleUpdateBookmark(
            accessToken,
            bkmrkDefault
        );

        //this.setState(() => ({ bookmarks }));

        //this.props.setBookmarkCallback(bkmrkDefault.id)
        //this.setState(() => ({ activeBookmarkId: id }));
    }

    public deleteBookmark = async (id: number) => {
        const { accessToken, onHandleDeleteBookmark, bookmarks } = this.props;

        onHandleDeleteBookmark(
            accessToken,
            id
        );
        this.setState(() => ({ bookmarks }));
    }

    public refreshBookmark = async (id: number) => {
        const { accessToken, onHandleUpdateBookmark, bookmarks } = this.props;

        let bkmrk: IPbiBookmark = bookmarks.find(
            b => b.id === id) as IPbiBookmark;
        bkmrk.capturedBookmark = await (this.props.getBookmarkCallback());

        onHandleUpdateBookmark(
            accessToken,
            bkmrk
        );
        this.setState(() => ({ bookmarks }));
    }

    public addBookmark = async () => {
        const { accessToken, dashboardId, activeUserRole, activeUser, onHandleSaveBookmark, bookmarks } = this.props;

        let bmrkName = "Bookmark ";

        if (bookmarks === undefined || bookmarks === null) {

            bmrkName = bmrkName + "1";
        }
        else {
            bmrkName = bmrkName + ((bookmarks as IPbiBookmark[]).length + 1).toString();
        }

        const bookmark = await (this.props.getBookmarkCallback());

        const bkmrk: IPbiBookmark = {
            id: 0,
            name: bmrkName,
            capturedBookmark: bookmark,
            userProfileId: activeUser.id,
            roleId: activeUserRole.id,
            pbiDashboardId: dashboardId,
            isDefault: false,
            isEnabled: true
        }

        var a = onHandleSaveBookmark(
            accessToken,
            bkmrk
        );
        this.setState(() => ({ activeBookmarkId: -1 }));

    }

    public async componentDidUpdate(prevProps: IBookmarkDropDownMenuProps) {
        const {
            bookmarks,
            loadingEmailedPbiDashboard
        } = this.props;
        const { activeBookmarkId } = this.state;

        if (bookmarks.length > prevProps.bookmarks.length && loadingEmailedPbiDashboard===false) {

            //If a new Bookmark was added
            if (activeBookmarkId < 0) {
                bookmarks.sort((a, b) =>
                    a.id > b.id ? 1 : -1
                );

                this.setState(() => ({ activeBookmarkId: bookmarks[bookmarks.length - 1].id }));
                this.setState(() => ({ isEditMode: true }));
                this.setState({ dropdownOpen: true })
                this.setState(() => ({ editBookmarkId: bookmarks[bookmarks.length - 1].id }));
            }
            else {

                let bkmrkArr: IPbiBookmark[] = bookmarks.filter(
                    b => b.isDefault === true) as IPbiBookmark[];

                if (bkmrkArr.length > 0) {
                    this.setState(() => ({ activeBookmarkId: bkmrkArr[0].id }));

                }
            }


        }

    }

    //public componentDidMount() {
    //    const {
    //        bookmarks
    //    } = this.props;

    //    let bkmrkArr: IPbiBookmark[] = bookmarks.filter(
    //        b => b.isDefault === true) as IPbiBookmark[];

    //    if (bkmrkArr.length > 0) {
    //        this.setState(() => ({ activeBookmarkId: bkmrkArr[0].id }));
    //    }
    //}

    public componentWillUnmount() {
        const {
            activeBookmarkId,
            isEditMode,
            editBookmarkId
        } = this.state;
        //onHandleClearLoadedPredicates();
        //onHandleClearActiveKpiPredicateId();

        this.setState(() => ({ activeBookmarkId: 0 }));
        this.setState(() => ({ isEditMode: false }));
        this.setState(() => ({ editBookmarkId: 0 }));
        this.setState(() => ({ bookmarks: [] as IPbiBookmark[] }));

    }

    public setBookmark = async (id: number) => {

        this.props.setBookmarkCallback(id);
        this.setState(() => ({ activeBookmarkId: id }));
    }

    public getActiveStyle(id: number) {
        const { activeBookmarkId } = this.state;
        if (id === activeBookmarkId) {
            return "bookmark-dropdown-item-active";            

        } else {
            return "bookmark-dropdown-item";
        }
    }

    public clearBookmarks() {
        this.props.clearBookmarkCallback();
        this.setState(() => ({ activeBookmarkId: 0 }));
    }

    public renameBookmark = async (id: number) => {

        this.setState(() => ({ isEditMode: true }));
        this.setState(() => ({ editBookmarkId: id }));
    }


    public nameChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        e.preventDefault();
        const name = e.target.value === undefined ? "" : (e.target.value as string);

        const { accessToken, onHandleUpdateBookmark, bookmarks } = this.props;

        let bkmrk: IPbiBookmark = bookmarks.find(
            b => b.id === id) as IPbiBookmark;
        bkmrk.name = e.target.value as string;

        this.setState(() => ({ isEditMode: true }));
        this.setState(() => ({ bookmarks }));

    };


    public nameBlur = async (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { accessToken, onHandleUpdateBookmark, bookmarks } = this.props;
        const { isEditMode } = this.state;

        e.preventDefault();
        const name =
            e.target.value === undefined ? "" : (e.target.value as string).trim();

        let bkmrk: IPbiBookmark = bookmarks.find(
            b => b.id === id) as IPbiBookmark;
        bkmrk.name = name;


        onHandleUpdateBookmark(
            accessToken,
            bkmrk
        );
        this.setState(() => ({ editBookmarkId: 0 }));
        this.setState(() => ({ isEditMode: false }));
        this.setState(() => ({ bookmarks }));
    };

    public nameSave = async () => {
        const { accessToken, onHandleUpdateBookmark, bookmarks } = this.props;
        const { isEditMode, activeBookmark } = this.state;

        const nameInput = document.getElementById(
            "name"
        ) as HTMLElement;

        let bkmrk: IPbiBookmark = bookmarks.find(
            b => b.id === activeBookmark) as IPbiBookmark;

        if (bkmrk != null) {
            bkmrk.name = nameInput.innerText.trim();

            onHandleUpdateBookmark(
                accessToken,
                bkmrk
            );
        }
        this.setState(() => ({ editBookmarkId: 0 }));
        this.setState(() => ({ isEditMode: false }));
        this.setState(() => ({ bookmarks }));


    };

    public showName = (name: string) => {
        if (name.length > 27) {
            return name.substring(0, 27) + '...';
        }
        else {
            return name;
        }
    };


    public render() {

        const { bookmarks } = this.props;
        const { activeBookmarkId, isEditMode, editBookmarkId } = this.state;
        var dropdownToggleName = 'Bookmarks';
        let dropdownToggleNameId: number = 0;

        const bkmrkHeader = bookmarks.find(
            b => b.id === activeBookmarkId);

        var disabled = true;
        if (bkmrkHeader !== undefined) {
            dropdownToggleName = (bkmrkHeader as IPbiBookmark).name;
            dropdownToggleNameId = (bkmrkHeader as IPbiBookmark).id;
            disabled = false;
        }

        return (
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                <Button id="caret"
                    color="info"
                    size="sm"
                    style={{ float: "right", color: "white" }}
                    disabled> {dropdownToggleName}
                </Button>
                <DropdownToggle caret
                    color="info"
                    size="sm"
                    style={{ float: "right", color: "white" }}
                >
                              {activeBookmarkId > 0 ? (<BookmarkActiveIcon />) : (<BookmarkIcon />)}
                </DropdownToggle>
                <DropdownMenu right>
                    {
                        bookmarks !== undefined && bookmarks.length > 0 && (
                            bookmarks.map((bkmrk: IPbiBookmark) => (
                                <DropdownItem key={bkmrk.id} className={this.getActiveStyle(bkmrk.id) }>
                                    <div className="bookmark-row row" id={'r' + bkmrk.id.toString()}>
                                        <div className="col-8 bookmark-control" >
                                            {/* <div className={this.getActiveStyle(bkmrk.id)}>*/}
                                            <div onClick={() => { this.makeDefault(bkmrk.id) }}>
                                                {bkmrk.isDefault ? (
                                                    <FavoritesIcon />
                                                ) : (
                                                    <NotFavoritesIcon />)
                                                }
                                            </div>
                                            {isEditMode && editBookmarkId == bkmrk.id ? (
                                                <div>
                                                    <Input
                                                        id="name"
                                                        type="text"
                                                        placeholder="Name"
                                                        value={bkmrk.name}
                                                        onChange={e => this.nameChange(e, bkmrk.id)}
                                                        // onFocus={e => this.nameFocus(e, bkmrk.id)}
                                                        onBlur={e => this.nameBlur(e, bkmrk.id)}
                                                    />
                                                </div>
                                            )
                                                :
                                                (
                                                    <div title={bkmrk.name} onClick={() => { this.setBookmark(bkmrk.id) }}>
                                                        {this.showName(bkmrk.name)}
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="col-4 bookmark-control">
                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.renameBookmark(bkmrk.id)
                                                }}
                                                style={{ margin: 1, float: "right", fontSize: "1rem" }}
                                            >
                                                <RenameIcon />
                                            </div>
                                            <div
                                                onClick={() => { this.refreshBookmark(bkmrk.id) }}
                                                style={{ margin: 1, float: "right", fontSize: "1rem" }}
                                            ><UpdateIcon /></div>
                                            <div
                                                onClick={() => { this.deleteBookmark(bkmrk.id) }}
                                                style={{ margin: 1, float: "right", fontSize: "1rem" }}
                                            >
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    </div>
                                </DropdownItem>
                            )))
                    }
                    {

                        bookmarks === undefined || bookmarks.length == 0 && (
                            <DropdownItem key="0" className="bookmark-dropdown-item" >
                                <div className="emptyBookmarkListText">Capture this Dashboard's current state</div>
                            </DropdownItem>
                        )

                    }

                    <DropdownItem divider />
                    {
                        !isEditMode && (
                            <DropdownItem>
                                <div className="btn btn-info btn-sm bookmark-menu-button"
                                    onClick={() => { this.addBookmark() }}
                                >
                                    Add Bookmark
                                </div>

                                <div className="btn btn-info btn-sm bookmark-menu-button"
                                    onClick={() => this.clearBookmarks()}
                                >
                                    Default view
                                </div>
                            </DropdownItem>)
                    }
                    {
                        isEditMode && (
                            <DropdownItem>
                                <div className="btn btn-info btn-sm bookmark-menu-button"
                                    onClick={() => { this.nameSave() }}
                                >
                                    Save

                                </div>
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </ButtonDropdown >

        );

    }
}



const mapStateToProps = (state: IStore, ownProps: any) => {
    return {
        accessToken: state.auth0.accessToken,
        activeUserRole: state.userRoles.find(
            role => role.id === state.activeRoleId
        ) as IRole,
        activeUser: state.userProfile,
        dashboardId: state.activeDashboardId,
        bookmarks: state.bookmarks,
        loadingEmailedPbiDashboard: state.loadingEmailedPbiDashboard,
        getBookmarkCallback: ownProps.getBookmarkCallback,
        setBookmarkCallback: ownProps.setBookmarkCallback,
        clearBookmarkCallback: ownProps.clearBookmarkCallback
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadBookmarks: (accaccessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => {
        //dispatch(handleLoadActiveRoleKpiGroupingPredicates(accessToken, roleId));
    },
    onHandleUpdateBookmark: (accessToken: string, bookmark: IPbiBookmark) => {
        dispatch(handleUpdateBookmark(accessToken, bookmark));
    },
    onHandleDeleteBookmark: (accessToken: string, id: number) => {
        dispatch(handleDeleteBookmark(accessToken, id));
    },


    onHandleSaveBookmark: (
        accessToken: string,
        bookmark: IPbiBookmark
    ) => {
        dispatch(handleSaveBookmark(
            accessToken,
            bookmark
        ));
    },


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookmarkDropDownMenu);

import { AnyAction } from "redux";
import { SET_SCHEDULES_LOADED } from "../actions/loadingSchedules";

export const loadingSchedules = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_SCHEDULES_LOADED:
            return action.schedulesLoaded;
        default:
            return state;
    }
};

export default loadingSchedules;

import * as Redux from "redux";
import getUserGroups from "../api/getUserGroups";
import removeUserGroup from "../api/removeUserGroup";
import saveUserGroup from "../api/saveUserGroup";
import { setEditUserGroupsLoaded } from "./loadingEditUserGroups";

export const ADD_EDIT_USER_GROUP_ID = "ADD_EDIT_USER_GROUP_ID";
export const REMOVE_EDIT_USER_GROUP_ID = "REMOVE_EDIT_USER_GROUP_ID";
export const LOAD_EDIT_USER_GROUP_IDS = "LOAD_EDIT_USER_GROUP_IDS";
export const CLEAR_EDIT_USER_GROUP_IDS = "CLEAR_EDIT_USER_GROUP_IDS";

export const addEditUserGroupId = (id: number) => {
  return {
    id,
    type: ADD_EDIT_USER_GROUP_ID
  };
};

export const removeEditUserGroupId = (id: number) => {
  return {
    id,
    type: REMOVE_EDIT_USER_GROUP_ID
  };
};

export const loadEditUserGroupIds = (ids: number[]) => {
  return {
    ids,
    type: LOAD_EDIT_USER_GROUP_IDS
  };
};

export const clearEditUserGroupIds = () => {
  return {
    type: CLEAR_EDIT_USER_GROUP_IDS
  };
};

export const handleLoadEditUserGroupIds = (
  accessToken: string,
  userId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const userGroups = await getUserGroups(accessToken, userId);
    if (userGroups === undefined) {
      dispatch(clearEditUserGroupIds());
      dispatch(setEditUserGroupsLoaded(false));
    } else {
      if (userGroups.length > 0) {
        dispatch(loadEditUserGroupIds(userGroups.map(group => group.id)));
      }
      dispatch(setEditUserGroupsLoaded(true));
    }
  };
};

export const handleSaveEditUserGroupId = (
  accessToken: string,
  userId: number,
    groupId: number,
    addedByUserProfileId:number

) => {
  return async (dispatch: Redux.Dispatch) => {
      await saveUserGroup(accessToken, userId, groupId, addedByUserProfileId);
    dispatch(addEditUserGroupId(groupId));
  };
};

export const handleDeleteEditUserGroupId = (
  accessToken: string,
  userId: number,
  groupId: number,
  addedByUserProfileId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
      await removeUserGroup(accessToken, userId, groupId, addedByUserProfileId);
    dispatch(removeEditUserGroupId(groupId));
  };
};

import { AnyAction } from "redux";
import {    
    LOAD_EDIT_SCHEDULE_REPORT_FORMATS,    
    UPDATE_EDIT_SCHEDULE_REPORT_FORMAT
} from "../actions/editScheduleReportFormats";
import IScheduleReportFormat from "../models/IScheduleReportFormat";

export const editScheduleReportFormats = (
    state = [] as IScheduleReportFormat[],
    action: AnyAction
) => {
    switch (action.type) {
      
        case UPDATE_EDIT_SCHEDULE_REPORT_FORMAT:
            return Object.assign(
                [],
                state.map((rp: IScheduleReportFormat) => {
                    if (                        
                        rp.id ===
                        (action.scheduleReportFormat as IScheduleReportFormat).id
                    ) {
                        return action.scheduleReportFormat as IScheduleReportFormat;
                    }
                    return rp;
                })
            ) as IScheduleReportFormat[];

        case LOAD_EDIT_SCHEDULE_REPORT_FORMATS:
            return action.scheduleReportFormats as IScheduleReportFormat[];
        
        default:
            return state;
    }
};

export default editScheduleReportFormats;

import * as Redux from "redux";
 import IPaginatedReportParameter from "../models/IPaginatedReportParameter";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import getPaginatedReportParameters from "../api/getPaginatedReportParameters";
import { setPaginatedReportParametersLoaded } from "./loadingPaginatedReportParameters";


export const LOAD_PAGINATED_REPORT_PARAMETERS = "LOAD_PAGINATED_REPORT_PARAMETERS";
  


export const loadPaginatedReportParameters = (pbiPaginatedReportParameters: IPaginatedReportParameter[]) => {
    return {
        pbiPaginatedReportParameters,
        type: LOAD_PAGINATED_REPORT_PARAMETERS
    };
};

export const handleLoadPaginatedReportParameters = (accessToken: string, paginatedReportId: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const parameters = await getPaginatedReportParameters(accessToken, paginatedReportId);
        if (parameters !== undefined) {
            dispatch(loadPaginatedReportParameters(parameters));
            }
            dispatch(setPaginatedReportParametersLoaded(true));
        }
    };
 

import { combineReducers } from "redux";
import activeDashboard from "./activeDashboard";
import activeDashboardDefaultClient from "./activeDashboardDefaultClient";
import activeDashboardDefaultPredicateId from "./activeDashboardDefaultPredicate";
import activePaginatedReport from "./activePaginatedReport";
import activeKpi from "./activeKpi";
import activeKpiClient from "./activeKpiClient";
import activeKpiEntries from "./activeKpiEntries";
import activeKpiFinancialYear from "./activeKpiFinancialYear";
import activeKpiPredicate from "./activeKpiPredicate";
import activeReport from "./activeReport";
import activeRole from "./activeRole";
import activeRoleClients from "./activeRoleClients";
import activeRoleKpiGroupingPredicates from "./activeRoleKpiGroupingPredicates";
import activeUserGroup from "./activeUserGroup";
import auth0 from "./auth0";
import currencyCodes from "./currencyCodes";
import dashboards from "./dashboards";
import paginatedReports from "./paginatedReports";
import editRoleDashboardIds from "./editRoleDashboardIds";
import editRolePaginatedReportIds from "./editRolePaginatedReportIds";
import editRoleDetails from "./editRoleDetails";
import editRolePredicates from "./editRolePredicates";
import editRoleReportIds from "./editRoleReportIds";
import editRoleUserIds from "./editRoleUserIds";
import editScheduleUserIds from "./editScheduleUserIds";
import editUserDetails from "./editUserDetails";
import editUserGroupIds from "./editUserGroupIds";
import editUserRoleIds from "./editUserRoleIds";
import financialYears from "./financialYears";
import fyStartMonths from "./fyStartMonths";
import groups from "./groups";
import kpis from "./kpis";
import scheduleFrequencies from "./scheduleFrequencies";
import scheduleEvents from "./scheduleEvents";
import schedulePeriods from "./schedulePeriods";
import userSchedules from "./userSchedules";

import bookmarks from "./bookmarks";


import loading from "./loading";

import loadingCurrencyCodes from "./loadingCurrencyCodes";
import loadingScheduleEvents from "./loadingScheduleEvents";
import loadingSchedulePeriods from "./loadingSchedulePeriods";
import loadingScheduleFrequencies from "./loadingScheduleFrequencies";



import loadingDashboards from "./loadingDashboards";
import loadingBookmarks from "./loadingBookmarks";


import loadingPaginatedReports from "./loadingPaginatedReports";
import loadingEditRoleDashboards from "./loadingEditRoleDashboards";
import loadingEditRolePaginatedReports from "./loadingEditRolePaginatedReports";
import loadingEditRolePredicates from "./loadingEditRolePredicates";
import loadingEditRoleReports from "./loadingEditRoleReports";
import loadingEditRoleUsers from "./loadingEditRoleUsers";
import loadingEditScheduleUsers from "./loadingEditScheduleUsers";


import loadingEditUserDetails from "./loadingEditUserDetails";
import loadingEditUserGroups from "./loadingEditUserGroups";
import loadingEditUserRoles from "./loadingEditUserRoles";
import loadingFinancialYears from "./loadingFinancialYears";
import loadingFyStartMonths from "./loadingFyStartMonths";
import loadingGroups from "./loadingGroups";
import loadingKpiEntries from "./loadingKpiEntries";
import loadingKpis from "./loadingKpis";
import loadingPredicates from "./loadingPredicates";
import loadingReports from "./loadingReports";
import loadingRoleClients from "./loadingRoleClients";
import loadingRoleKpiGroupingPredicates from "./loadingRoleKpiGroupingPredicates";
import loadingRoles from "./loadingRoles";
import loadingSchedules from "./loadingSchedules";

import loadingUserDashboards from "./loadingUserDashboards";
import loadingUserPaginatedReports from "./loadingUserPaginatedReports";


import loadingUserRolesWithUserProfiles from "./loadingUserRolesWithUserProfiles";


import loadingUserGroups from "./loadingUserGroups";
import loadingUserReports from "./loadingUserReports";
import loadingUserRoles from "./loadingUserRoles";
import loadingUsers from "./loadingUsers";
import predicates from "./predicates";
import profiles from "./profiles";
import reports from "./reports";
import rolePredicates from "./rolePredicates";
import roles from "./roles";
import tokenData from "./tokenData";

import userDashboards from "./userDashboards";
import userPaginatedReports from "./userPaginatedReports";

import userGroups from "./userGroups";
import userProfile from "./userProfile";
import userReports from "./userReports";
import userRoles from "./userRoles";
import userFavorites from "./userFavorites";
import loadingUserFavorites from "./loadingUserFavorites";


import paginatedReportParameters from "./paginatedReportParameters";
import editScheduleDetails from "./editScheduleDetails";
import schedules from "./schedules";

import editSchedulePaginatedReportIds from "./editSchedulePaginatedReportIds";
import loadingEditSchedulePaginatedReports from "./loadingEditSchedulePaginatedReports";


import editScheduleReportFormats from "./editScheduleReportFormats";
import loadingEditScheduleReportFormats from "./loadingEditScheduleReportFormats";

import editSchedulePaginatedReportParameters from "./editSchedulePaginatedReportParameters";



import userRolesWithUserProfiles from "./userRolesWithUserProfiles";
import loadingEditSchedulePaginatedReportParameters from "./loadingEditSchedulePaginatedReportParameters";

import loadingUserSchedules from "./loadingUserSchedules";


import loadingEmailedPbiDashboard from "./loadingEmailedDashboard";
import emailedPbiDashboard from "./emailedDashboard";

 


export const allReducers = combineReducers({
    auth0,
    currencyCodes,
    fyStartMonths,
    predicates,
    userProfile,
    // tslint:disable-next-line:object-literal-sort-keys
    userDashboards,
    userPaginatedReports,

    userRoles,
    userFavorites,
    userReports,
    userGroups,
    dashboards,
    paginatedReports,

    groups,
    profiles,
    reports,
    roles,
    rolePredicates,
    editRoleDetails,
    editRoleDashboardIds,
    editRolePaginatedReportIds,
    editRolePredicates,
    editRoleReportIds,
    editRoleUserIds,
    editScheduleUserIds,
    editUserDetails,
    editUserGroupIds,
    editUserRoleIds,
    editSchedulePaginatedReportIds,
    editScheduleReportFormats,

    activeDashboardId: activeDashboard,
    activePaginatedReportId: activePaginatedReport,

    activeReportId: activeReport,
    activeRoleId: activeRole,
    activeUserGroupId: activeUserGroup,
    bookmarks,
    tokenData,
    loading,
    loadingEditUserDetails,
    loadingEditUserGroups,
    loadingEditUserRoles,
    loadingUsers,
    loadingGroups,
    loadingRoles,
    loadingCurrencyCodes,
    loadingScheduleFrequencies,
    loadingSchedulePeriods,
    loadingScheduleEvents,
    loadingFyStartMonths,
    loadingPredicates,
    loadingUserRoles,

    loadingUserDashboards,
    loadingUserPaginatedReports,

    loadingUserReports,
    loadingUserGroups,
    loadingReports,

    loadingDashboards,
    loadingBookmarks,
    loadingPaginatedReports,

    loadingEditRoleUsers,
    loadingEditScheduleUsers,
    loadingEditRoleReports,
    loadingEditRoleDashboards,
    loadingEditRolePaginatedReports,
    loadingEditRolePredicates,
    loadingFinancialYears,
    loadingEditSchedulePaginatedReports,
    loadingEditScheduleReportFormats,
    loadingKpis,

    loadingSchedules,
    loadingUserFavorites,
    financialYears,

    kpis,
    activeKpiId: activeKpi,
    activeKpiPredicateId: activeKpiPredicate,
    activeKpiFinancialYearId: activeKpiFinancialYear,
    activeKpiClientId: activeKpiClient,
    activeRoleClients,
    loadingRoleClients,
    activeRoleKpiGroupingPredicates,
    loadingRoleKpiGroupingPredicates,
    activeKpiEntries,
    loadingKpiEntries,
    activeDashboardDefaultClient,
    activeDashboardDefaultPredicateId,
    paginatedReportParameters,
    editScheduleDetails,
    schedules,
    scheduleEvents,
    schedulePeriods,
    userSchedules,
    scheduleFrequencies,
    editSchedulePaginatedReportParameters,
    loadingEditSchedulePaginatedReportParameters,
    userRolesWithUserProfiles,
    loadingUserRolesWithUserProfiles,
    loadingUserSchedules,
    loadingEmailedPbiDashboard,
    emailedPbiDashboard
});

import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils/index";

export const getPaginatedReports = async (accessToken: string) => {
    const data = await fetchSecureData(
        "GET",
        `/api/PbiPaginatedReport/GetPbiPaginatedReports`,
        accessToken
    );
    const paginatedReports = ((await data.json()) as any).value as IPbiPaginatedReport[];
    if (paginatedReports !== undefined) {
        return paginatedReports;
    } else {
        return [] as IPbiPaginatedReport[];
    }
};

export default getPaginatedReports;

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { handleLoadDashboards } from "../../../actions/dashboards";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IPbiDashboard from "../../../models/IPbiDashboard";
import { IStore } from "../../../models/IStore";

export interface ISearchDashboardsPageProps {
  accessToken: string;
  onHandleLoadDashboards: (accessToken: string) => void;
  dashboards: IPbiDashboard[];
  dashboardsLoaded: boolean;
}

export interface ISearchDashboardsPageState {
  filteredDashboards: IPbiDashboard[];
  matchedNameExact: boolean;
  name: string;
  noMatchingNames: boolean;
}
class SearchDashboardsPage extends React.Component<
  ISearchDashboardsPageProps,
  ISearchDashboardsPageState
> {
  public isLoaded = false;
  public state = {
    filteredDashboards: [] as IPbiDashboard[],
    matchedNameExact: false,
    name: "",
    noMatchingNames: false
  };
  constructor(props: ISearchDashboardsPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { dashboards } = this.props;

    const searchDashboardDisplayName = e.target.value.toLowerCase();
    if (!searchDashboardDisplayName) {
      this.setState({
        filteredDashboards: dashboards
      });
    } else {
      const filteredDashboards: IPbiDashboard[] = dashboards.filter(
        (dashboard: IPbiDashboard) =>
          dashboard.displayName
            .toLowerCase()
            .includes(searchDashboardDisplayName)
      );
      if (filteredDashboards.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredDashboards.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredDashboards
      });
    }
  }

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadDashboards,
      dashboards,
      dashboardsLoaded
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!dashboardsLoaded) {
        onHandleLoadDashboards(accessToken);
      }
      this.setState({ filteredDashboards: dashboards });
    }
  }

  public componentDidUpdate(prevProps: ISearchDashboardsPageProps) {
    const { dashboards } = this.props;
    if (prevProps.dashboards !== dashboards) {
      this.setState({
        filteredDashboards: dashboards
      });
    }
  }

  public render() {
    const { dashboards, dashboardsLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      name,
      filteredDashboards
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>Dashboard Administration</h1>
        {!dashboardsLoaded && <DataLoading dataTitle="Dashboards" />}
        {dashboardsLoaded && (
          <React.Fragment>
            <FormGroup>
           <Card Style="width: 48rem; border-radius: 20% center">
                <CardTitle>
                  <Label for="name">Select Dashboard To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Filter Dashboard"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        Dashboard found! Select to edit
                        {name}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No dashboards match name entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
         <Card Style="width: 48rem; border-radius: 20% center">
              <CardBody>
                {dashboards.length > 0 &&
                  filteredDashboards.map((dashboard: IPbiDashboard) => (
                    <div key={dashboard.id}>
                      <Link
                        to={paths.editDashboard.concat(dashboard.id.toString())}
                      >
                        <span>{dashboard.displayName}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    dashboards: state.dashboards,
    dashboardsLoaded: state.loadingDashboards
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadDashboards: (accessToken: string) => {
    dispatch(handleLoadDashboards(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDashboardsPage);

import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils";

export const getSchedulePaginatedReports = async (
    accessToken: string,
    scheduleId: number
) => {
    try {
        const data = await fetchSecureData(
            "GET",
            `/api/PbiPaginatedReport/GetPbiPaginatedReportsForScheduleId?scheduleId=${scheduleId}`,
            accessToken
        );
        const schedulePaginatedReports = ((await data.json()) as any)
            .value as IPbiPaginatedReport[];
        if (schedulePaginatedReports !== undefined) {
            return schedulePaginatedReports;
        } else {
            return [] as IPbiPaginatedReport[];
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
            `Fetch error for /api/PbiPaginatedReport/GetPbiPaginatedReportsForScheduleId?scheduleId=${scheduleId}`,
            err
        );
    }
    return [] as IPbiPaginatedReport[];
};

export default getSchedulePaginatedReports;

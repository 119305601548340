import * as React from "react";
import { connect } from "react-redux";
import { Input, Table } from "reactstrap";


import IPaginatedReportParameter from "../../models/IPaginatedReportParameter";

import { IStore } from "../../models/IStore";

export interface IRoleDetailsTableProps {

    paginatedReportParameters: IPaginatedReportParameter[];
}


const PaginatedReportParameterDetailsTable: React.SFC<IRoleDetailsTableProps> = (
    props: IRoleDetailsTableProps
) => {
    const { paginatedReportParameters } = props;

    return (
        <Table striped={true} bordered>
            <thead>
                <tr> 
                    <th>Display Name</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th><div style={{ textAlign: "center" }}>Is MultiValue</div></th>
                    <th><div style={{ textAlign: "center" }}>Is Enabled</div></th>
                    <th><div style={{ textAlign: "center" }}>Is Hidden</div></th>
                    <th>Exclusion Reason</th>
                </tr>
            </thead>
            <tbody>
                {
                    paginatedReportParameters !== null &&
                    paginatedReportParameters !== undefined &&
                    paginatedReportParameters.length > 0 &&
                    paginatedReportParameters.map((parameter: IPaginatedReportParameter) => ( 
                        <tr>
                            <td>{parameter.displayName}</td>
                            <td>{parameter.name}</td>
                            <td>{getParameterDefaultLabel(parameter)}</td>
                            <td>
                                <input type="checkbox"
                                    defaultChecked={parameter.isMultiValue} disabled
                                />
                            </td> 
                            <td>
                                <input type="checkbox"
                                    defaultChecked={parameter.isEnabled} disabled
                                /> 
                            </td>
                            <td>{ }
                                <input type="checkbox"
                                    defaultChecked={parameter.isHidden} disabled
                                />
                            </td>
                            <td>{ }
                                <input type="checkbox"
                                    defaultChecked={parameter.isExcluded} disabled
                                />
                            </td>
                        </tr>

                    ))}

            </tbody>
        </Table>
    );
};

//function getParameterDefaultValue(parameter: IPaginatedReportParameter) {

//    if (parameter.defaultValueJson !== null) {
//        var defValue = JSON.parse(parameter.defaultValueJson);
//        if (defValue !== null && defValue !== undefined) {
//            return defValue.Value;
//        }
//    }
//    return null;
//}

function getParameterDefaultLabel(parameter: IPaginatedReportParameter) {
    let result = "";
    //if (parameter.validValuesJson === null || parameter.validValuesJson === undefined) {
    //    return "";
    //}

    if ((parameter.validValuesJson === null || parameter.validValuesJson === undefined)
        && (parameter.defaultValueJson === null || parameter.defaultValueJson === undefined)) {

        return "";
    }
    if ((parameter.validValuesJson === null || parameter.validValuesJson === undefined)
        && (parameter.defaultValueJson !== null || parameter.defaultValueJson !== undefined)) {
        var defValue = JSON.parse(parameter.defaultValueJson);

        return defValue.Value;
    }


    var defValue = JSON.parse(parameter.defaultValueJson);
    if (defValue !== null && defValue !== undefined) {

        if (defValue.Value === 'NULL') {
            return defValue.Value;
        }
        else {
            var allValues = JSON.parse(parameter.validValuesJson);

            if (defValue.Value.constructor.toString().indexOf("Array") > -1) {

                for (var i = 0; i < allValues.length; i++) {

                    for (var j = 0; j < defValue.Value.length; j++) {

                        if (allValues[i].Value === defValue.Value[j]) {

                            if (result !== "") {

                                result = result + ", ";
                            }
                            result = result + allValues[i].Label;
                        }
                    }
                }
            }
            else {

                for (var i = 0; i < allValues.length; i++) {

                    if (allValues[i].Value === defValue.Value) {
                        if (result !== "") {

                            result = result + ", ";
                        }
                        result = result + allValues[i].Label;
                    }
                }
            }

        }
    }
    return result;
    return result;
}



const mapStateToProps = (state: IStore) => {
    return {
        paginatedReportParameters: state.paginatedReportParameters

    };
};

export default connect(mapStateToProps)(PaginatedReportParameterDetailsTable);

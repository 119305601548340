import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card, CardBody, CardHeader } from "reactstrap";
import { settings } from "../../constants";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IStore } from "../../models/IStore";
import DashboardViewPage from "./DashboardViewPage";
import DashboardViewPageV2 from "./DashboardViewPageV2";
import IPbiDashboardShared from "../../models/IPbiDashboardShared";
import { stat } from "fs/promises";
import { setActiveRoleId } from "../../actions/activeRole";
import * as Redux from "redux";

export interface IDashboardSelectionPageProps extends RouteComponentProps {
  dashboard: IPbiDashboard;
    ownProps: RouteComponentProps;
    activeRoleId: number,
    emailedPbiDashboard: IPbiDashboardShared
}

const validDashboard = (dashboardViewerVersion: number) => {
  if (
    dashboardViewerVersion < 1 ||
    dashboardViewerVersion > settings.maxDashboardViewerVersion
  ) {
    return false;
  }
  return true;
};


const validEmailedDashboard = (emailedPbiDashboard: IPbiDashboardShared) => {

};


export const handleSetActiveRoleId = ( 
  roleId: number
     
) => {
    return async (dispatch: Redux.Dispatch) => {
        await dispatch(setActiveRoleId(roleId)); 
    };
};


export class DashboardSelectionPage extends React.Component<
  IDashboardSelectionPageProps
> {


     

  public render() {
      const { dashboard} = this.props;   
    return (
      <React.Fragment>
        {dashboard.dashboardViewerVersion === 1 && (
          <DashboardViewPage {...this.props} />
        )}
        {dashboard.dashboardViewerVersion === 2 && (
          <DashboardViewPageV2 {...this.props} />
        )}
        {!validDashboard(dashboard.dashboardViewerVersion) && (
          <Card>
            <CardHeader>
              <h3>Error</h3>
            </CardHeader>
            <CardBody>
              Invalid viewer version number on Dashboard. Please report error to
              system administrator
            </CardBody>
          </Card>
        )}
      </React.Fragment>
    );
  }
}
const matchStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let dashboard = {} as IPbiDashboard;
  if (match !== null) {
      var id = (match.params as any).id as string;

      //If it is a shared Dashboard     
      if (id.length === 36 && state.loadingEmailedPbiDashboard) {
          id = state.emailedPbiDashboard.pbiDashboardId.toString();
      }

    dashboard = state.userDashboards.find(dash => {
      return dash.id === parseInt(id, 10);
    }) as IPbiDashboard;
  }
  return {
      dashboard,
      activeRoleId: state.activeRoleId,
      emailedPbiDashboard:state.emailedPbiDashboard,
      ownProps
  };
};

export default connect(matchStateToProps)(DashboardSelectionPage);

/* eslint-disable no-useless-escape */
import { IRole } from "../models/IRole";
import ISqlReport from "../models/ISqlReport";

export const validateText = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\|\:\,]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateTextOrWhitespace = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateGuid = (text: string) => {
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateUrl = (text: string) => {
  const re = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateEmail = (text: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validatePathText = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\/\%\?\=\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateParametersText = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\/\%\?\=\:\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateZoomPercent = (text: string) => {
  const value = Number(text);
  if (isNaN(value)) {
    return false;
  }
  if (value < 0 || value > 500) {
    return false;
  }
  return true;
};

export const validateRoleDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\|\:\,\!\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};
export const validateRoleName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\!]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateDashboardDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};
export const validateDashboardName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validatePaginatedReportDisplayName = (text: string) => {
    const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
    return re.test(String(text.trim()).toLowerCase());
};
export const validatePaginatedReportName = (text: string) => {
    const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
    return re.test(String(text.trim()).toLowerCase());
};

export const validateGroupName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};
export const validateReportDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};
export const validateReportName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};
export const validateFinancialYearDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateFinancialYearEndYear = (text: string) => {
  const year = Number(text);
  if (year > 2000 || year < 2050) {
    return true;
  }
  return false;
};

export const validatePredicateName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validatePredicateDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateKpiDisplayName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\%\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};

export const validateKpiName = (text: string) => {
  const re = /^[a-zA-Z0-9\_\.\-\(\)\%\s]*$/;
  return re.test(String(text.trim()).toLowerCase());
};


export const validateScheduleName = (text: string) => {
    const re = /^[a-zA-Z0-9\_\.\-\#\(\)\s]*$/;
    return re.test(String(text.trim()).toLowerCase());
};

export const validateDate = (text: string) => {  
    const re = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
    return re.test(String(text.trim()).toLowerCase());
};

export const validateDateWithFormat = (text: string, dateFormat: string) => {

    var re = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

    if (dateFormat === 'MM/dd/yyyy') {
        var re = /^(1[0-2]|0?[1-9])[/](3[01]|[12][0-9]|0?[1-9])[/](19|20)\d\d$/;
    }

    if (dateFormat === 'dd/MM/yyyy') {
        var re = /^(3[01]|[12][0-9]|0?[1-9])[/](1[0-2]|0?[1-9])[/](19|20)\d\d$/;
    } 

    if (dateFormat === 'yyyy-MM-dd') {
        var re = /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
    }

    return re.test(String(text.trim()).toLowerCase());
};

export const getDayOfWeek = (date: Date) => {
    if (date === undefined || date === null) return '';


    var day = '';    

    switch (date.getDay()) {
        case 0:
            day = "Sunday";
            break;
        case 1:
            day = "Monday";
            break;
        case 2:
            day = "Tuesday";
            break;
        case 3:
            day = "Wednesday";
            break;
        case 4:
            day = "Thursday";
            break;
        case 5:
            day = "Friday";
            break;
        case 6:
            day = "Saturday";
    }

    return day;

}




export const validateNumber = (text: string) => {
  // const re = /^-?\d(\.\d+)?$/;
  // return re.test(String(text.trim()).toLowerCase());
  const value = Number(text);
  if (isNaN(value)) {
    return false;
  }
  return true;
};

export const fetchSecureData = async (
  method: string,
  url: string,
  accessToken: string,
  data?: object
) => {
  if (data !== undefined) {
    return await fetch(url, {
      method,
      // tslint:disable-next-line:object-literal-sort-keys
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": `application/json`,
        ContentType: `application/json`
      }),
      body: JSON.stringify(data)
    });
  } else {
    return await fetch(url, {
      method,
      // tslint:disable-next-line:object-literal-sort-keys
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`
      })
    });
  }
};

export const getDefaultSqlReportServerViewUrl = () => {
  // const url =
  //   "https://projectriverside-sqlreporting-production.azurewebsites.net/SqlReport.aspx";
  // const url =
  // "https://localhost:44325/SqlReport.aspx";
  // const url =
  //   "https://ctmsmartdatapbiserverreportviewerwestus.azurewebsites.net/SqlReport";
  const url = "https://reports.ctmsmartdata.com/SqlReport";
  return url;
};

export const buildIframeUrl = async (report: ISqlReport) => {
  let iframeUrl = "";
  if (report.sqlReportingViewerUrl) {
    iframeUrl = iframeUrl.concat(report.sqlReportingViewerUrl);
  } else {
    iframeUrl = iframeUrl.concat(getDefaultSqlReportServerViewUrl());
  }
  iframeUrl = iframeUrl.concat(
    `?serverReportPath=/${report.path}/${report.name}`
  );
  if (report.parameters) {
    iframeUrl = iframeUrl.concat(`&${report.parameters}`);
  }
  if (report.defaultZoomMode) {
    iframeUrl = iframeUrl.concat(`&defaultZoomMode=${report.defaultZoomMode}`);
  }
  if (report.defaultZoomPercent) {
    iframeUrl = iframeUrl.concat(
      `&defaultZoomPercent=${report.defaultZoomPercent}`
    );
  }
  return iframeUrl;
};

export const buildRolesParams = (userRole: IRole) => {
  const roles = userRole !== undefined ? `&Roles=${userRole.name}` : "";
  return roles;
};

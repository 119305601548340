import * as React from "react";
import Text from "../atoms/Text";
import LogoutIcon from "./LogoutIcon";

export interface ILogoutButtonProps {
  logout: () => void;
}

const LogoutButton = (props: ILogoutButtonProps) => {
  const { logout } = props;
  return (
    <React.Fragment>
      <span onClick={logout} style={{cursor:"pointer"}}>
        <LogoutIcon />
              <Text style={{ paddingRight: "1em", color: "#FFF", paddingLeft: "8px" }}>Logout</Text>
      </span>
    </React.Fragment>
  );
};

export default LogoutButton;

import * as React from "react";
import Manage from "../../assets/icons/bookmark-fill.svg";
import Icon from "./Icon";

const BookmarkActiveIcon = () => {
    var smallStyle = {
        height: 18,
        margin: "0px 8px 0px 0px" 
    }; 

  return (
    <React.Fragment>
          <Icon icon={Manage} text="Active Bookmark Icon" id="activebookmarkIcon" style={smallStyle} />
    </React.Fragment>
  );
};

export default BookmarkActiveIcon;

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { updateAuth0 } from "../../actions/auth0";
import { handleLoadInitialData } from "../../actions/shared";
import Auth from "../../auth/Auth";
import paths from "../../constants";
import { IStore } from "../../models/IStore";

export interface ICallbackEmailReportsProps extends RouteComponentProps {
  auth: Auth;
  onHandleAuthentication: (
    accessToken: string,
    expiresAt: number,
    idToken: string,
    email: string
  ) => void;
}

export class CallbackEmailReports extends React.Component<ICallbackEmailReportsProps> {
  public constructor(props: ICallbackEmailReportsProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth, history, onHandleAuthentication } = this.props;
    auth
      .handleAuthentication()
      // .then((authResult: auth0.Auth0DecodedHash) => {
        .then((authResult: any) => {

            var callbackState = authResult.state;
            if (callbackState !==undefined) {
                //localStorage.remove(callbackState);
                history.push(paths.emailReports + callbackState);
            }
            else {
                history.push(paths.emailReports);
            }
            
        onHandleAuthentication(
          authResult.accessToken as string,
          (authResult.expiresIn as number) * 1000 + new Date().getTime(),
          authResult.idToken as string,
          auth.getProfile().email as string,

        );
      })
      .catch((err: any) => {
        // tslint:disable-next-line:no-console
        console.log("There was an error:", err);
      });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleAuthentication: (
    accessToken: string,
    expiresAt: number,
    idToken: string,
    email: string
  ) => {
    dispatch(updateAuth0(accessToken, expiresAt, idToken));
    dispatch(handleLoadInitialData(accessToken, email));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallbackEmailReports);

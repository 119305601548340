import * as React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { IStore } from "../../models/IStore";
import scheduleFrequencies from "../../reducers/scheduleFrequencies";
import UserDashboardsCard from "../molecules/UserDashboardsCard";
import UserPaginatedReportsCard from "../molecules/UserPaginatedReportsCard";
import UserReportsCard from "../molecules/UserReportsCard";
import UserSchedulesCard from "../molecules/UserSchedulesCard";
import WelcomeCard from "../molecules/WelcomeCard";
import DataLoading from "../organisms/DataLoading";
import {   withRouter,Redirect } from "react-router-dom";
import paths from "../../constants";



export interface IWelcomePageProps {
    loaded: boolean;
    groupsLoaded: boolean;
    currencyCodesLoaded: boolean;
    fyStartMonthsLoaded: boolean;
    predicatesLoaded: boolean;
    userRolesLoaded: boolean;
    userFavoritesLoaded: boolean;
    userDashboardsLoaded: boolean;
    userPaginatedReportsLoaded: boolean;
    userReportsLoaded: boolean;
    userGroupsLoaded: boolean;
    scheduleFrequenciesLoaded: boolean;
    scheduleEventsLoaded: boolean;
    schedulePeriodsLoaded: boolean;
    userSchedulesLoaded: boolean;
}

export const WelcomePage: React.SFC<IWelcomePageProps> = (
    props: IWelcomePageProps
) => {
    const {
        loaded,
        groupsLoaded,
        currencyCodesLoaded,
        fyStartMonthsLoaded,
        predicatesLoaded,
        userRolesLoaded,
        userFavoritesLoaded,
        userDashboardsLoaded,
        userPaginatedReportsLoaded,
        userReportsLoaded,
        userGroupsLoaded,
        scheduleFrequenciesLoaded,
        scheduleEventsLoaded,
        schedulePeriodsLoaded,
        userSchedulesLoaded
    } = props;

    var uid = localStorage.getItem('emailedDashboardUid');
    if (loaded && uid !== undefined && uid !== null) {
        localStorage.removeItem('emailedDashboardUid');
        return (<Redirect push to={paths.pbiDashboards + uid}/>)
    }


    return (
        <div className="loadingContainer">
            <Row>
                <Col>{!userRolesLoaded && <DataLoading dataTitle="User Roles" />}</Col>
            </Row>
            <Row>
                <Col>
                    {!userDashboardsLoaded && <DataLoading dataTitle="User Dashboards" />}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!userPaginatedReportsLoaded && <DataLoading dataTitle="User Reports" />}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!userReportsLoaded && <DataLoading dataTitle="User SSRS Reports" />}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!userGroupsLoaded && <DataLoading dataTitle="User Groups" />}
                </Col>
            </Row>
            <Row>
                <Col>{!predicatesLoaded && <DataLoading dataTitle="Predicates" />}</Col>
            </Row>
            <Row>
                <Col>{!groupsLoaded && <DataLoading dataTitle="Groups" />}</Col>
            </Row>
            <Row>
                <Col>
                    {!currencyCodesLoaded && <DataLoading dataTitle="Currency Codes" />}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!fyStartMonthsLoaded && (
                        <DataLoading dataTitle="Financial Year Start Months" />
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                    {!scheduleFrequenciesLoaded && <DataLoading dataTitle="Subscription Frequencies" />}
                </Col>
            </Row>

            <Row>
                <Col>
                    {!schedulePeriodsLoaded && <DataLoading dataTitle="Subscription Periods" />}
                </Col>
            </Row>

            <Row>
                <Col>
                    {!scheduleEventsLoaded && <DataLoading dataTitle="Subscription Events" />}
                </Col>
            </Row>

            <Row>
                <Col>
                    {loaded && <WelcomeCard />}{" "}
                    {!loaded && <DataLoading dataTitle="Remaining" />}
                </Col>
            </Row>
            <Row>
                <Col>{userDashboardsLoaded && loaded && <UserDashboardsCard />}</Col>
            </Row>
            <Row>
                <Col>{userReportsLoaded && loaded && <UserReportsCard />}</Col>
            </Row>
            <Row>
                <Col>{userPaginatedReportsLoaded && loaded && <UserPaginatedReportsCard />}</Col>
            </Row>
          
            {/*<Row>*/}
            {/*    <Col>{userSchedulesLoaded && loaded && <UserSchedulesCard />}</Col>*/}
            {/*</Row>*/}
            <Row>
                <Col>{!userFavoritesLoaded && <DataLoading dataTitle="User Favorites" />}</Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        loaded: state.loading,
        // tslint:disable-next-line:object-literal-sort-keys
        groupsLoaded: state.loadingGroups,
        currencyCodesLoaded: state.loadingCurrencyCodes,
        fyStartMonthsLoaded: state.loadingFyStartMonths,
        predicatesLoaded: state.loadingPredicates,
        userRolesLoaded: state.loadingUserRoles,
        userFavoritesLoaded: state.loadingUserFavorites,
        userDashboardsLoaded: state.loadingUserDashboards,
        userPaginatedReportsLoaded: state.loadingUserPaginatedReports,
        userReportsLoaded: state.loadingUserReports,
        userGroupsLoaded: state.loadingUserGroups,

        scheduleFrequenciesLoaded: state.loadingScheduleFrequencies,
        schedulePeriodsLoaded: state.loadingSchedulePeriods,
        scheduleEventsLoaded: state.loadingScheduleEvents,
        userSchedulesLoaded: state.loadingUserSchedules
    };
};

export default withRouter (connect(mapStateToProps)(WelcomePage));

import { AnyAction } from "redux";
import {
  ADD_USER_FAVORITE,
  LOAD_USER_FAVORITES,
  REMOVE_USER_FAVORITE
} from "../actions/userFavorites";
import IUserFavorite from "../models/IUserFavorite";

export const userFavorites = (state = [] as IUserFavorite[], action: AnyAction) => {
  switch (action.type) {
    case ADD_USER_FAVORITE:
          const newState: IUserFavorite[] = state.slice();
          newState.push(action.userFavorite as IUserFavorite);
      return newState;
    case REMOVE_USER_FAVORITE:
      return Object.assign(
        [],
          state.filter((r: IUserFavorite) => r.id !== (action.id as number))
          ) as IUserFavorite[];

       

    case LOAD_USER_FAVORITES:
          return action.userFavorites as IUserFavorite[];

    default:
      return state;
  }
};

export default userFavorites;

import IPbiDashboardShared from "../models/IPbiDashboardShared";
import { fetchSecureData } from "../utils";

export const saveDashboardShared = async (
    accessToken: string,
    pbiDashboardId: number,
    roleId: number,
    userProfileId:number, 
    createdBy: number,
    capturedBookmark: string,
    message:string     
) => {
    
    const payload = {
      
        pbiDashboardId : pbiDashboardId,
        roleId: roleId,
        userProfileId: userProfileId,
        createdBy: createdBy,
        capturedBookmark: capturedBookmark,
        message: message
    };
    
    try {
       
    const data = await fetchSecureData(
        "PUT",
        `/api/PbiDashboard/ShareDashboard`,
        accessToken,
        payload 
    );
    const dashboard = ((await data.json()) as any).value as IPbiDashboardShared;
    return dashboard;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/PbiDashboard/ShareDashboard?pbiDashboardId=${pbiDashboardId}&roleId=${roleId}&userProfileId=${userProfileId}&createdBy=${createdBy}&capturedBookmark=${capturedBookmark}&message=${message}`,
      err
    );
  }
  return {} as IPbiDashboardShared;
};

export default saveDashboardShared;

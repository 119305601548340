import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const saveUserGroup = async (
  accessToken: string,
  userId: number,
    groupId: number,
    addedByUserProfileId:number
    
) => {
  try {
    const data = await fetchSecureData(
      "POST",
        `/api/UserProfileGroup/Add?userId=${userId}&groupId=${groupId}&addedByUserProfileId=${addedByUserProfileId}`,
      accessToken
    );
    const userGroup = ((await data.json()) as any).value as IGroup;
    return userGroup;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/UserProfileGroup/Add?userId=${userId}&groupId=${groupId}&addedByUserProfileId=${addedByUserProfileId}`
    );
  }
  return {} as IGroup;
};

export default saveUserGroup;

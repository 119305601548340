import * as React from "react";
import { Badge, Label } from "reactstrap";
import ISqlReport from "../../models/ISqlReport";

export interface IReportsBadgeProps {
  reportIds: number[];
  reports: ISqlReport[];
}

const ReportsBadge = (props: IReportsBadgeProps) => {
  const { reportIds, reports } = props;
  return (
    <React.Fragment>
      <Label for="grpSelectedReports">SSRS Selected Reports:</Label>
      <div id="grpSelectedReports">
        {reportIds.map(id => (
            <Badge key={id} className="badge">
            {(reports.find(report => report.id === id) as ISqlReport).name}
          </Badge>
        ))}
      </div>
      {reportIds.length === 0 && <div>None</div>}
    </React.Fragment>
  );
};

export default ReportsBadge;

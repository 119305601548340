import * as React from "react";
import { connect } from "react-redux";
import { Input, Table } from "reactstrap";
import { handleLoadEditUserGroupIds } from "../../actions/editUserGroupIds";
import { handleLoadEditUserRoleIds } from "../../actions/editUserRoleIds";
import { handleLoadGroups } from "../../actions/groups";
import { handleLoadRoles } from "../../actions/roles";
import ISchedule from "../../models/ISchedule";
import { IStore } from "../../models/IStore";
import IScheduleFrequency from "../../models/IScheduleFrequency";
import ISchedulePeriod from "../../models/ISchedulePeriod";
import IScheduleEvent from "../../models/IScheduleEvent";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { handleLoadEditSchedulePaginatedReportIds } from "../../actions/editSchedulePaginatedReportIds";
import { handleLoadPaginatedReports } from "../../actions/paginatedReports";
import { format, isBefore, addDays } from 'date-fns';
import IRole from "../../models/IRole";
import ICurrencyCode from "../../models/ICurrencyCode";

export interface IScheduleDetailsTableProps {
    accessToken: string;
    editScheduleDetails: ISchedule;

    scheduleFrequencies: IScheduleFrequency[];
    schedulePeriods: ISchedulePeriod[];
    scheduleEvents: IScheduleEvent[];
    paginatedReports: IPbiPaginatedReport[];
    editSchedulePaginatedReportIds: number[];
    scheduleId: number;
    currencyCodes: ICurrencyCode[];
    activeUserRole: IRole;


    //user: IUserProfile;
    //roles: ISchedule[];
    //rolesLoaded: boolean;
    //onHandleLoadRoles: (accessToken: string) => void;
    //roleIds: number[];
    //roleIdsLoaded: boolean;
    //onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => void;
    //groups: IGroup[];
    //groupsLoaded: boolean;
    //onHandleLoadGroups: (accessToken: string) => void;
    //groupIds: number[];
    //groupIdsLoaded: boolean;
    //onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => void;
}

class ScheduleDetailsTable extends React.Component<IScheduleDetailsTableProps> {
    public loaded = false;
    public constructor(props: IScheduleDetailsTableProps) {
        super(props);
        this.getDateInLocalFormat = this.getDateInLocalFormat.bind(this);
        this.getScheduleFrequencyName = this.getScheduleFrequencyName.bind(this);
        this.getSchedulePeriodName = this.getSchedulePeriodName.bind(this);
        this.getScheduleEventName = this.getScheduleEventName.bind(this);
        this.getStatusName = this.getStatusName.bind(this);
        this.getDateAsString = this.getDateAsString.bind(this);
        
    }


    public async componentDidMount() {
        this.loaded = true;
    }


    public getDateInLocalFormat(date: Date) {
        var formatter = new Intl.DateTimeFormat("en-US");
        formatter.format(date);
    }

    public getScheduleFrequencyName(scheduleFrequencyId: number, scheduleFrequencyDays: number) {

        if (scheduleFrequencyId === 0) return "N/A";
        const { scheduleFrequencies } = this.props;

        if (scheduleFrequencyId === 1) {
            if (scheduleFrequencyDays === 1) {
                return "Daily"
            } else {
                return "Every " + scheduleFrequencyDays + " days"
            }
        }

        if (scheduleFrequencyId === 2) {
            if (scheduleFrequencyDays === 1) {
                return "Weekly"
            } else {
                return "Every " + scheduleFrequencyDays + " weeks"
            }
        }

        if (scheduleFrequencyId === 3) {
            if (scheduleFrequencyDays === 1) {
                return "Monthly"
            } else {
                return "Every " + scheduleFrequencyDays + " months"
            }
        }
        if (scheduleFrequencyId === 4) {
            return "Ad hoc"
        }
        //  return (scheduleFrequencies.find(item => item.id === scheduleFrequencyId) as IScheduleFrequency).name;
    }

    public getSchedulePeriodName(schedulePeriodId: number, schedulePeriodDays: number) {
        if (schedulePeriodId === 0) return "N/A";
        const { schedulePeriods } = this.props;

        if (schedulePeriodId === 4) {
            if (schedulePeriodDays === 1) {
                return "Last " + schedulePeriodDays + " day";
            }
            else {
                return "Last " + schedulePeriodDays + " days";
            }
        }
        else {
            return (schedulePeriods.find(item => item.id === schedulePeriodId) as ISchedulePeriod).name;
        }
    }

    public getScheduleEventName(scheduleEventId: number) {
        if (scheduleEventId === 0) return "N/A";
        const { scheduleEvents } = this.props;
        return (scheduleEvents.find(item => item.id === scheduleEventId) as IScheduleEvent).name;
    }

    public getStatusName = (schedule: ISchedule) => {
        var status: string;

        if (isBefore(addDays(new Date(schedule.lastRunDate), 1), new Date())) {
            status = "Expired";
        }
        else {
            if (schedule.isActive) {
                status = "Active";
            }
            else {
                status = "Paused";
            }
        }

        return status;

    };




    public getDateAsString(date: Date) {

        const {
            activeUserRole,
            currencyCodes } = this.props;
        let currency = {} as ICurrencyCode;

        if (activeUserRole === undefined) {
            currency= (currencyCodes.find(
                cc => cc.id === 1
            ) as ICurrencyCode);

            throw new Error(
                "At least one role has not been defined, so default CurrencyCode to AUD"
            );
        } else {
            currency = (currencyCodes.find(
                cc => cc.id === activeUserRole.currencyCodeId
            ) as ICurrencyCode);
            
        }
         
        let result = "";
        if (date !== undefined && date !== null) {
            result = format(new Date(date), currency.dateFormat);
        }
        else {
            result = format(new Date(), currency.dateFormat);
        }

        return result;
    }


    public render() {

        const { editScheduleDetails, scheduleFrequencies, schedulePeriods, scheduleEvents } = this.props;

        return (
            <Table striped={true}>
                <tbody>
                    <tr>
                        <th scope="row">Subscription Name:</th>
                        <td> {editScheduleDetails.name}</td>
                    </tr>
                    <tr>
                        <th scope="row">Is Active:</th>
                        {
                            this.getStatusName(editScheduleDetails) === 'Active' ? <td><p className='text-success'>Active</p></td> : ''
                        }
                        {
                            this.getStatusName(editScheduleDetails) === 'Paused' ? <td><p className='text-danger'>Paused</p></td> : ''
                        }
                        {
                            this.getStatusName(editScheduleDetails) === 'Expired' ? <td><p className='text-secondary'>Expired</p></td> : ''
                        }
                    </tr>
                    <tr>
                        <th scope="row">Start Date:</th>
                        <td>{this.getDateAsString(new Date(editScheduleDetails.firstRunDate))}</td>
                    </tr>
                    <tr>
                        <th scope="row">End Date:</th>
                        <td>{this.getDateAsString(new Date(editScheduleDetails.lastRunDate))}</td>
                    </tr>
                    <tr>
                        <th scope="row">Schedule Frequency:</th>
                        <td>
                            {this.getScheduleFrequencyName(editScheduleDetails.scheduleFrequencyId, editScheduleDetails.scheduleFrequencyDays)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Schedule Period:</th>
                        <td>
                            {this.getSchedulePeriodName(editScheduleDetails.schedulePeriodId, editScheduleDetails.schedulePeriodDays)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Schedule Event:</th>
                        <td>{this.getScheduleEventName(editScheduleDetails.scheduleEventId)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    return {
        accessToken: state.auth0.accessToken,
        editScheduleDetails: state.editScheduleDetails,
        scheduleEvents: state.scheduleEvents,
        scheduleFrequencies: state.scheduleFrequencies,
        schedulePeriods: state.schedulePeriods,

        paginatedReports: state.paginatedReports,
        editSchedulePaginatedReportIds: state.editSchedulePaginatedReportIds,
        scheduleId: state.editScheduleDetails.id,
        activeUserRole: state.userRoles.find(
            role => role.id === state.activeRoleId
        ) as IRole,
        currencyCodes: state.currencyCodes


        //user: ownProps.user,
        //// tslint:disable-next-line:object-literal-sort-keys
        //roles: state.roles,
        //rolesLoaded: state.loadingRoles,
        //roleIds: state.editUserRoleIds,
        //roleIdsLoaded: state.loadingEditUserRoles,
        //groups: state.groups,
        //groupsLoaded: state.loadingGroups,
        //groupIds: state.editUserGroupIds,
        //groupIdsLoaded: state.loadingEditUserGroups
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadRoles: (accessToken: string) => {
        dispatch(handleLoadRoles(accessToken));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleLoadGroups: (accessToken: string) => {
        dispatch(handleLoadGroups(accessToken));
    },
    onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => {
        dispatch(handleLoadEditUserRoleIds(accessToken, userId));
    },
    onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => {
        dispatch(handleLoadEditUserGroupIds(accessToken, userId));
    },


    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },
    onHandleLoadEditSchedulePaginatedReportIds: (accessToken: string, scheduleId: number) => {
        dispatch(handleLoadEditSchedulePaginatedReportIds(accessToken, scheduleId));
    },


});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleDetailsTable);

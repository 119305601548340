import * as React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Input, Table, Label } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import { IStore } from "../../../models/IStore";

import IPaginatedReportParameter from "../../../models/IPaginatedReportParameter";

import {
    handleUpdateEditPaginatedReportParameter
} from "../../../actions/editPaginatedReportParameters"


export interface IEditPaginatedParametersDetailsProps {
    accessToken: string;
    editPaginatedReportParameters: IPaginatedReportParameter[];
    onHandleUpdatePaginatedReportParameter: (
        accessToken: string,
        parameter: IPaginatedReportParameter
    ) => void;

}


export interface IEditPaginatedParametersDetailsState {
    editPaginatedReportParameters: IPaginatedReportParameter[];
    sortParametersBy: string;
}

class EditPaginatedParametersDetails extends React.Component<
    IEditPaginatedParametersDetailsProps,
    IEditPaginatedParametersDetailsState
> {
    public state = {

        editPaginatedReportParameters: [] as IPaginatedReportParameter[],
        sortParametersBy: ''
    };



    constructor(props: IEditPaginatedParametersDetailsProps) {
        super(props);

        this.readyToSave = this.readyToSave.bind(this);
        this.getParameterDefaultValue = this.getParameterDefaultValue.bind(this);
        this.getParameterDefaultLabel = this.getParameterDefaultLabel.bind(this);
        this.handleValidValuesChange = this.handleValidValuesChange.bind(this);
        this.handleValidValuesInputTextChange = this.handleValidValuesInputTextChange.bind(this);
        this.handleValidValuesInputTextOnBlur = this.handleValidValuesInputTextOnBlur.bind(this);
        this.handleIsEnabledChange = this.handleIsEnabledChange.bind(this);
        this.handleIsHiddenChange = this.handleIsHiddenChange.bind(this);
        this.getParameterValidValuesJsonAsList = this.getParameterValidValuesJsonAsList.bind(this);
        this.handleSortParametersBy = this.handleSortParametersBy.bind(this);
  
    }
    public readyToSave = () => {

        return true;
    };



    public handleIsEnabledChange = (event: any, parameterId: number) => {
        const { accessToken, onHandleUpdatePaginatedReportParameter, editPaginatedReportParameters } = this.props;

        var param = editPaginatedReportParameters.find(par => par.id === parameterId);

        if (param !== null && param !== undefined) {

            param.isEnabled = !param.isEnabled;
            onHandleUpdatePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editPaginatedReportParameters }));

        }
    };

    public handleIsHiddenChange = (event: any, parameterId: number) => {
        const { accessToken, onHandleUpdatePaginatedReportParameter, editPaginatedReportParameters } = this.props;

        var param = editPaginatedReportParameters.find(par => par.id === parameterId);

        if (param !== null && param !== undefined) {

            param.isHidden = !param.isHidden;
            onHandleUpdatePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editPaginatedReportParameters }));

        }
    };


    public getParameterDefaultValue = (parameter: IPaginatedReportParameter) => {

        let result: string[] = [];
        let defValueJson: string;


        defValueJson = parameter.defaultValueJson;


        if (defValueJson !== '') {
            let defValue = JSON.parse(defValueJson);
            if (defValue !== null && defValue !== undefined) {

                if (defValue.Value.constructor.toString().indexOf("Array") > -1) {
                    result = defValue.Value;
                }
                else {
                    result = Object.assign([], defValue.Value);
                    result = defValue.Value.split(",");
                }
            }
        }

        return result;
    };


    public getParameterDefaultLabel = (parameter: IPaginatedReportParameter) => {

        let result = "";
        //if (parameter.validValuesJson === null || parameter.validValuesJson === undefined) {
        //    return "";
        //}

        if ((parameter.validValuesJson === null || parameter.validValuesJson === undefined)
            && (parameter.defaultValueJson === null || parameter.defaultValueJson === undefined)) { 
            return "";
        }

        if ((parameter.validValuesJson === null || parameter.validValuesJson === undefined)
            && (parameter.defaultValueJson !== null || parameter.defaultValueJson !== undefined)) {
            var defValue = JSON.parse(parameter.defaultValueJson);
            return defValue.Value;
        }


        var defValue = JSON.parse(parameter.defaultValueJson);
        if (defValue !== null && defValue !== undefined) {

            if (defValue.Value === 'NULL') {
                return defValue.Value;
            }
            else {
                var allValues = JSON.parse(parameter.validValuesJson);

                if (defValue.Value.constructor.toString().indexOf("Array") > -1) {

                    for (var i = 0; i < allValues.length; i++) {

                        for (var j = 0; j < defValue.Value.length; j++) {

                            if (allValues[i].Value === defValue.Value[j]) {

                                if (result !== "") {

                                    result = result + ", ";
                                }
                                result = result + allValues[i].Label;
                            }
                        }
                    }
                }
                else {

                    for (var i = 0; i < allValues.length; i++) {

                        if (allValues[i].Value === defValue.Value) {
                            if (result !== "") {

                                result = result + ", ";
                            }
                            result = result + allValues[i].Label;
                        }
                    }
                }

            }
        }
        return result;
    };


    public getParameterValidValuesJsonAsList(parameter: IPaginatedReportParameter) {
        if (parameter.validValuesJson === null) {
            return '';
        }

        JSON.parse(parameter.validValuesJson).map((obj: { Value: string; Label: string; }) => {
            return <option value={obj.Value} >{obj.Label}</option>
        })
    }


    //public handleValidValuesChangse = (event: React.ChangeEvent<HTMLSelectElement>) => {

    //    const defaultValues = event.target.value;
    //    const parameterId = event.target.getAttribute('id');

    //    alert(defaultValues);
    //    alert(parameterId);


    //};
    
    public handleValidValuesInputTextOnBlur = (event: React.ChangeEvent<HTMLInputElement>, parameterId: number) => {
       
        const { accessToken, onHandleUpdatePaginatedReportParameter, editPaginatedReportParameters } = this.props;

        event.preventDefault();

        var param = editPaginatedReportParameters.find(par => par.id === parameterId);
        let output = "";


        if (param !== null && param !== undefined) {

            if (event.target.value != null) {

                let collection = event.target.value.split(",");
                for (let i = 0; i < collection.length; i++) {
                    if (output === "") {
                        output = "{\"Value\":[";
                    }
                    output += "\"";
                    output += collection[i].trim();
                    output += "\"";

                    if (i < (collection.length - 1)) {
                        output += ", ";
                    } else if (i === (collection.length - 1)) {
                        output += "]}";
                    }
                }
            }

            param.defaultValueJson = output;

            onHandleUpdatePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editPaginatedReportParameters }));
        }
    };



    public handleValidValuesInputTextChange = (event: React.ChangeEvent<HTMLInputElement>, parameterId: number) => {
        //const { editPaginatedReportParameters } = this.state;
        const { accessToken, onHandleUpdatePaginatedReportParameter, editPaginatedReportParameters } = this.props;

        event.preventDefault();
        var param = editPaginatedReportParameters.find(par => par.id === parameterId);
        let output = "";


        if (param !== null && param !== undefined) {

            if (event.target.value != null) {

                let collection = event.target.value.split(",");
                for (let i = 0; i < collection.length; i++) {
                    if (output === "") {
                        output = "{\"Value\":[";
                    }
                    output += "\"";
                    output += collection[i];
                    output += "\"";

                    if (i < (collection.length - 1)) {
                        output += ", ";
                    } else if (i === (collection.length - 1)) {
                        output += "]}";
                    }
                }
            }

            param.defaultValueJson = output;

       //     onHandleUpdatePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editPaginatedReportParameters }));
        }
    };


    public handleValidValuesChange = (event: React.ChangeEvent<HTMLSelectElement>, parameterId: number) => {
        //const { editPaginatedReportParameters } = this.state;
        const { accessToken, onHandleUpdatePaginatedReportParameter, editPaginatedReportParameters } = this.props;

        var param = editPaginatedReportParameters.find(par => par.id === parameterId);

        if (param !== null && param !== undefined) {

            let collection = event.target.selectedOptions;
            let output = "";

            for (let i = 0; i < collection.length; i++) {
                if (output === "") {
                    output = "{\"Value\":[";
                }

                output += "\"";
                output += collection[i].value;
                output += "\"";

                if (i < (collection.length - 1)) {
                    output += ", ";
                } else if (i === (collection.length - 1)) {
                    output += "]}";
                }
            }

            param.defaultValueJson = output;

            onHandleUpdatePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editPaginatedReportParameters }));
        }
    };


    public componentDidUpdate(prevProps: IEditPaginatedParametersDetailsProps, prevState: IEditPaginatedParametersDetailsState) {
        const { editPaginatedReportParameters } = this.props;

        if (prevProps.editPaginatedReportParameters !== editPaginatedReportParameters) {
            this.setState({
                editPaginatedReportParameters: editPaginatedReportParameters
            });
        }

        if (prevState.sortParametersBy !== this.state.sortParametersBy) {
            this.setState({
                editPaginatedReportParameters: editPaginatedReportParameters
            });
        }
    }

    public handleSortParametersBy(event: React.ChangeEvent<HTMLSelectElement>) {
        const { editPaginatedReportParameters } = this.props;

        if (event.target.value === null && event.target.value === undefined) {
            return;
        }
        this.state.sortParametersBy = event.target.value;
        switch (this.state.sortParametersBy) {
            case "rows":
                editPaginatedReportParameters.sort((a, b) => {
                    if (a.rowIndex === b.rowIndex) {
                        return a.columnIndex < b.columnIndex ? -1 : 1
                    } else {
                        return a.rowIndex < b.rowIndex ? -1 : 1
                    }
                })
                break;

            case "columns":
                editPaginatedReportParameters.sort((a, b) => {
                    if (a.columnIndex === b.columnIndex) {
                        return a.rowIndex < b.rowIndex ? -1 : 1
                    } else {
                        return a.columnIndex < b.columnIndex ? -1 : 1
                    }
                }) 
                break;

            case "displayname":
                editPaginatedReportParameters.sort((a, b) =>
                    a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
                );
                break;

            case "name":
                editPaginatedReportParameters.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                );
                break;
        }

        this.setState(() => ({ editPaginatedReportParameters }));

    }


    public async componentDidMount() {
        const { editPaginatedReportParameters } = this.state;
        await this.setState(() => ({ editPaginatedReportParameters }));
    }



    public render() {
        const { editPaginatedReportParameters } = this.props;

        const getClassName = (parameter: IPaginatedReportParameter) => {

            if (parameter.isExcluded) {
                return 'reportParameterTrExcluded';
            } else {
                return 'reportParameterTr';
            }
        };

        return (
            <div>
                <div className='reportParameterSortingDiv'>
                    <Label>Sorting by:</Label> &nbsp;
                    <select
                        onChange={e => this.handleSortParametersBy(e)}
                    >
                        <option value='rows'>---</option>
                        <option value='rows'>Rows then Columns</option>
                        <option value='columns'>Columns then Rows</option>
                        <option value='displayname'>Display Name</option>
                        <option value='name'>Name</option>
                    </select>
                </div>
                <Table striped={false} bordered>
                    <thead>
                        <tr>
                            <th>Display Name</th>
                            <th>Name</th>
                            <th>Value</th>
                            <th><div style={{ textAlign: "center" }}>Is MultiValue</div></th>
                            <th><div style={{ textAlign: "center" }}>Is Enabled</div></th>
                            <th><div style={{ textAlign: "center" }}>Is Hidden</div></th>
                            <th>Exclusion Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {editPaginatedReportParameters !== null &&
                            editPaginatedReportParameters !== undefined &&
                            editPaginatedReportParameters.length > 0 &&
                            editPaginatedReportParameters.map((parameter: IPaginatedReportParameter) => (
                                <tr className={getClassName(parameter)}>
                                    <td>{parameter.displayName}</td>
                                    <td>{parameter.name}</td>
                                    <td>
                                        {parameter.isMultiValue && parameter.type !== 'InputText' && !parameter.isExcluded && (<div><select
                                            id='{parameter.id}'
                                            multiple={true}
                                            defaultValue={this.getParameterDefaultValue(parameter)}
                                            onChange={e => this.handleValidValuesChange(e, parameter.id)}
                                        >
                                            <option value=''>---</option>
                                            {

                                                JSON.parse(parameter.validValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                    return <option value={obj.Value} >{obj.Label}</option>
                                                })
                                            }</select>
                                            <p className='reportParameterTooltip'>   {this.getParameterDefaultLabel(parameter)} </p></div>

                                        )}
                                        {parameter.isMultiValue && parameter.type !== 'InputText' && parameter.isExcluded && (<div><select
                                            id='{parameter.id}'
                                            multiple={true}
                                            defaultValue={this.getParameterDefaultValue(parameter)}
                                            disabled
                                            onChange={e => this.handleValidValuesChange(e, parameter.id)}
                                        >
                                            <option value=''>---</option>
                                            {
                                                JSON.parse(parameter.validValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                    return <option value={obj.Value} >{obj.Label}</option>
                                                })
                                            }</select>
                                            <p className='reportParameterTooltip'>   {this.getParameterDefaultLabel(parameter)} </p></div>

                                        )}


                                        {!parameter.isMultiValue && parameter.type !== 'InputText' && !parameter.isExcluded && (<div><select
                                            defaultValue={this.getParameterDefaultValue(parameter)}
                                            onChange={e => this.handleValidValuesChange(e, parameter.id)}
                                        >
                                            <option value=''>---</option>
                                            {
                                                // this.getParameterValidValuesJsonAsList(parameter)

                                                JSON.parse(parameter.validValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                    return <option value={obj.Value} >{obj.Label}</option>
                                                })
                                            }</select>
                                            {/* <p style={{ fontSize: "0.9em", color: "#0075ff" }}>   {this.getParameterDefaultLabel(parameter)} </p>*/}
                                        </div>
                                        )}


                                        {!parameter.isMultiValue && parameter.type !== 'InputText' && parameter.isExcluded && (<div><select
                                            defaultValue={this.getParameterDefaultValue(parameter)}
                                            disabled
                                            onChange={e => this.handleValidValuesChange(e, parameter.id)}
                                        >
                                            <option value=''>---</option>
                                            {
                                                JSON.parse(parameter.validValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                    return <option value={obj.Value} >{obj.Label}</option>
                                                })
                                            }</select>
                                        </div>
                                        )}

                                        {parameter.type === 'InputText' && !parameter.isExcluded && (
                                            <div><input
                                                key={parameter.id}
                                                type="text"
                                                value={parameter.defaultValueJson !== null ? JSON.parse(parameter.defaultValueJson).Value : ''}
                                                onChange={e => this.handleValidValuesInputTextChange(e, parameter.id)}
                                                onBlur={e => this.handleValidValuesInputTextOnBlur(e, parameter.id)}
                                            />
                                                <p className='reportParameterTooltip'>   {this.getParameterDefaultLabel(parameter)} </p></div>
                                        )}


                                        {parameter.type === 'InputText' && parameter.isExcluded && (
                                            <div><input
                                                key={parameter.id}
                                                type="text"
                                                value={parameter.defaultValueJson !== null ? JSON.parse(parameter.defaultValueJson).Value : ''}
                                                disabled
                                                onChange={e => this.handleValidValuesInputTextChange(e, parameter.id)}
                                                onBlur={e => this.handleValidValuesInputTextOnBlur(e, parameter.id)}
                                            />
                                                <p className='reportParameterTooltip'>   {this.getParameterDefaultLabel(parameter)} </p></div>
                                        )}
                                    </td>
                                    <td>
                                        <div style={{ textAlign: "center" }}>
                                            <Input type="checkbox"
                                                checked={parameter.isMultiValue}
                                                disabled
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {!parameter.isExcluded && (<div style={{ textAlign: "center" }}>
                                            <Input type="checkbox"
                                                defaultChecked={parameter.isEnabled}
                                                onChange={e => this.handleIsEnabledChange(e, parameter.id)}
                                            />
                                        </div>)}

                                        {parameter.isExcluded && (<div style={{ textAlign: "center" }}>
                                            <Input type="checkbox"
                                                defaultChecked={parameter.isEnabled}
                                                disabled
                                                onChange={e => this.handleIsEnabledChange(e, parameter.id)}
                                            />
                                        </div>)}
                                    </td>

                                    <td>
                                        {!parameter.isExcluded && (<div style={{ textAlign: "center" }}>
                                            <Input type="checkbox"
                                                defaultChecked={parameter.isHidden}
                                                onChange={e => this.handleIsHiddenChange(e, parameter.id)}
                                            />
                                        </div>)}

                                        {parameter.isExcluded && (<div style={{ textAlign: "center" }}>
                                            <Input type="checkbox"
                                                defaultChecked={parameter.isHidden}
                                                disabled
                                                onChange={e => this.handleIsHiddenChange(e, parameter.id)}
                                            />
                                        </div>)}
                                    </td>


                                    <td>
                                        {parameter.exclusionReason}
                                    </td>

                                </tr>

                            ))}

                    </tbody>
                </Table>

            </div>

        );
    }
}



const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        editPaginatedReportParameters: state.paginatedReportParameters
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleUpdatePaginatedReportParameter: (
        accessToken: string,
        paginatedReportParameter: IPaginatedReportParameter
    ) => {
        dispatch(handleUpdateEditPaginatedReportParameter(accessToken, paginatedReportParameter));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPaginatedParametersDetails);

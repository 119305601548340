import { AnyAction } from "redux";
import {
  ADD_BOOKMARK,
  LOAD_BOOKMARKS,
  REMOVE_BOOKMARK,
  UPDATE_BOOKMARK
} from "../actions/bookmarks";
import IPbiBookmark from "../models/IPbiBookmark";

export const bookmarks = (
  state = [] as IPbiBookmark[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_BOOKMARK:
      const newState: IPbiBookmark[] = state.slice();
      newState.push(action.bookmark as IPbiBookmark);
      return newState;
    case REMOVE_BOOKMARK:
      return Object.assign(
        [],
        state.filter(
          (dshbd: IPbiBookmark) => dshbd.id !== (action.id as number)
        )
      ) as IPbiBookmark[];
    case UPDATE_BOOKMARK:
      return Object.assign(
        [],
        state.map((dshbd: IPbiBookmark) => {
          if (dshbd.id === (action.bookmark as IPbiBookmark).id) {
            return action.bookmark as IPbiBookmark;
          }
          return dshbd;
        })
      ) as IPbiBookmark[];
    case LOAD_BOOKMARKS:
      return action.bookmarks as IPbiBookmark[];
    default:
      return state;
  }
};

export default bookmarks;

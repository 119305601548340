import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import DashboardsBadge from "../../../components/molecules/DashboardsBadge";
import PaginatedReportsBadge from "../../../components/molecules/PaginatedReportsBadge";
import ReportsBadge from "../../../components/molecules/ReportsBadge";
import RoleDetailsTable from "../../../components/molecules/RoleDetailsTable";
import RolePredicatesTable from "../../../components/molecules/RolePredicatesTable";
import UsersBadge from "../../../components/molecules/UsersBadge";
import paths from "../../../constants";
import IPbiDashboard from "../../../models/IPbiDashboard";
import IPbiPaginatedReport from "../../../models/IPbiPaginatedReport";
import IPredicate from "../../../models/IPredicate";
import IRole from "../../../models/IRole";
import IRolePredicate from "../../../models/IRolePredicate";
import ISqlReport from "../../../models/ISqlReport";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";

export interface IRoleSummaryState {
  activeEditRoleUsers: IUserProfile[];
}

export interface IRoleSummaryProps extends RouteComponentProps {
  role: IRole;
  dashboards: IPbiDashboard[];
  dashboardIds: number[];
  paginatedReports: IPbiPaginatedReport[];
  paginatedReportIds: number[];

  reports: ISqlReport[];
  reportIds: number[];
  users: IUserProfile[];
  userIds: number[];
  predicates: IPredicate[];
  rolePredicates: IRolePredicate[];
}

class RoleSummary extends React.Component<IRoleSummaryProps> {
  public render() {
    const {
      history,
      role,
      predicates,
      rolePredicates,
      dashboards,
      dashboardIds,
      paginatedReports, 
      paginatedReportIds,
      reports,
      reportIds,
      users,
      userIds
    } = this.props;
    return (
      <Card>
        <CardTitle tag="h3">Review and Complete</CardTitle>
        <CardBody>
          <h4>Role Details:</h4>
          <RoleDetailsTable role={role} />
          <h4>Role Predicates:</h4>
          <RolePredicatesTable
            predicates={predicates}
            rolePredicates={rolePredicates}
          />{" "}
          <br />
          <h4>Role Dashboards</h4>
          <DashboardsBadge
            dashboards={dashboards}
            dashboardIds={dashboardIds}
          />
           <h4>Role Reports</h4>
                <PaginatedReportsBadge
                   paginatedReportIds={paginatedReportIds}
                    paginatedReports={paginatedReports}           
          />
          <h4>Role Reports</h4>
          <ReportsBadge reports={reports} reportIds={reportIds} />
          <h4>Role Users</h4>
          <UsersBadge users={users} userIds={userIds} />
        </CardBody>
        <CardFooter>
          <Button
            // tslint:disable-next-line:jsx-no-lambda
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              history.push(paths.home)
            }
          >
            Complete
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    users: state.profiles,
    // tslint:disable-next-line:object-literal-sort-keys
    userIds: state.editRoleUserIds,
    role: state.editRoleDetails,
    reports: state.reports,
    reportIds: state.editRoleReportIds,
    dashboards: state.dashboards,
    dashboardIds: state.editRoleDashboardIds,
    paginatedReports: state.paginatedReports,
    paginatedReportIds: state.editRolePaginatedReportIds,
    predicates: state.predicates,
    rolePredicates: state.editRolePredicates
  };
};

export default connect(mapStateToProps)(RoleSummary);

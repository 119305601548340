import { AnyAction } from "redux";
import { SET_SCHEDULE_FREQUENCYS_LOADED } from "../actions/loadingScheduleFrequencies";

export const loadingScheduleFrequencies = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_SCHEDULE_FREQUENCYS_LOADED:
            return action.scheduleFrequenciesLoaded;
        default:
            return state;
    }
};

export default loadingScheduleFrequencies;

import * as React from "react";
import { connect } from "react-redux";
import {
    Table,
    Card,
    CardBody
} from "reactstrap";
import {

    handleLoadEditSchedulePaginatedReportParameters,
    handleUpdateEditSchedulePaginatedReportParameter
} from "../../actions/editSchedulePaginatedReportParameters"

import DataLoading from "../../components/organisms/DataLoading";

import ISchedulePaginatedReportParameter from "../../models/ISchedulePaginatedReportParameter";
import { IStore } from "../../models/IStore";



export interface IAssignSchedulePaginatedReportParametersState {
    editSchedulePaginatedReportParameters: ISchedulePaginatedReportParameter[];
}

export interface IAssignSchedulePaginatedReportParametersProps {
    accessToken: string;

    editSchedulePaginatedReportParameters: ISchedulePaginatedReportParameter[];
    editSchedulePaginatedReportParametersLoaded: boolean,

    scheduleId: number;

    onHandleUpdateSchedulePaginatedReportParameter: (
        accessToken: string,
        parameter: ISchedulePaginatedReportParameter
    ) => void;

    onHandleLoadSchedulePaginatedReportParameters: (accessToken: string, scheduleId: number) => void;


}

export class AssignSchedulePaginatedReportParameters extends React.Component<
    IAssignSchedulePaginatedReportParametersProps,
    IAssignSchedulePaginatedReportParametersState
> {
    public state = {

        editSchedulePaginatedReportParameters: [] as ISchedulePaginatedReportParameter[]
    };


    constructor(props: IAssignSchedulePaginatedReportParametersProps) {
        super(props);

        this.readyToAdd = this.readyToAdd.bind(this);
        this.getReportName = this.getReportName.bind(this);
        this.getReportParameters = this.getReportParameters.bind(this);
        this.getParameterDefaultLabel = this.getParameterDefaultLabel.bind(this);
        this.getParameterDefaultValue = this.getParameterDefaultValue.bind(this);
        this.handleValidValuesChange = this.handleValidValuesChange.bind(this);
        this.getParameterValueAsString = this.getParameterValueAsString.bind(this);
        this.handleValidValuesInputTextChange = this.handleValidValuesInputTextChange.bind(this);
        this.handleValidValuesInputTextOnBlur = this.handleValidValuesInputTextOnBlur.bind(this);
    }


    public readyToAdd = () => {

        return true;
    };



    public async componentDidMount() {
        const {
            accessToken,
            scheduleId,
            onHandleLoadSchedulePaginatedReportParameters,

        } = this.props;

        const { editSchedulePaginatedReportParameters } = this.state;

        await onHandleLoadSchedulePaginatedReportParameters(accessToken, scheduleId);
        await this.setState(() => ({ editSchedulePaginatedReportParameters }));
    }

    //public async componentDidUpdate(prevProps: IAssignSchedulePaginatedReportParametersProps) {
    //    const { schedulePaginatedReportParameters } = this.props;
    //    if (prevProps.schedulePaginatedReportParameters !== schedulePaginatedReportParameters) {
    //        await this.setState(() => ({ schedulePaginatedReportParameters }));
    //    }
    //}



    public getParameterDefaultValue = (parameter: ISchedulePaginatedReportParameter
    ) => {
        let result: string[] = [];
        let defValueJson: string;

        if (parameter.scheduleOverridedParameterDefaultValueJson !== '') {
            defValueJson = parameter.scheduleOverridedParameterDefaultValueJson;
        }
        else {
            defValueJson = parameter.parameterDefaultValueJson;
        }

        if (defValueJson !== '') {
            let defValue = JSON.parse(defValueJson);
            if (defValue !== null && defValue !== undefined) {

                if (defValue.Value.constructor.toString().indexOf("Array") > -1) {
                    result = defValue.Value;
                }
                else {
                    result = Object.assign([], defValue.Value);
                    result = defValue.Value.split(",");
                }
            }
        }

        return result;
    };





    public getParameterValueAsString = (parameter: ISchedulePaginatedReportParameter, forShowingDefaultValue: boolean) => {

        let result = '';

        var defValue;

        if ((parameter.parameterDefaultValueJson === null || parameter.parameterDefaultValueJson === '' || parameter.parameterDefaultValueJson === undefined) &&
            (parameter.scheduleOverridedParameterDefaultValueJson === null || parameter.scheduleOverridedParameterDefaultValueJson === '' || parameter.scheduleOverridedParameterDefaultValueJson === undefined)) {
            return result;
        }

        if (forShowingDefaultValue) // show only  Schedule Value
        {
            if (parameter.parameterDefaultValueJson === null || parameter.parameterDefaultValueJson === '' || parameter.parameterDefaultValueJson === undefined) {
                return '';
            }
            else {
                defValue = JSON.parse(parameter.parameterDefaultValueJson).Value;
            }

        }
        else // show SheduleValue, if null, then Report Default Value        
        {
            if (parameter.scheduleOverridedParameterDefaultValueJson === null || parameter.scheduleOverridedParameterDefaultValueJson === '' || parameter.scheduleOverridedParameterDefaultValueJson === undefined) {
                defValue = JSON.parse(parameter.parameterDefaultValueJson).Value;
            }
            else {
                defValue = JSON.parse(parameter.scheduleOverridedParameterDefaultValueJson).Value;
            } 
        }



      

          



        if (defValue === null || defValue === undefined) {

            return '';
        } 
        if (Array.isArray(defValue)) // this is array
        {

            for (var i = 0; i < defValue.length; i++) {


                if (result !== "") {

                    result = result + ", ";
                }
                result = result + defValue[i];
            }
        }
        else // this is not array
        {
            return defValue.Value;
        }
        return result;

    }




    public getParameterDefaultLabel = (parameter: ISchedulePaginatedReportParameter, forSchedule: boolean) => {
        let result = '';

        if (!forSchedule && parameter.parameterDefaultValueJson === '') {
            return '';
        }


        if (!forSchedule && parameter.parameterValidValuesJson === '') {
            return this.getParameterValueAsString(parameter,false);
        }


        if (forSchedule && (parameter.scheduleOverridedParameterDefaultValueJson === ''
            || parameter.scheduleOverridedParameterDefaultValueJson === undefined
            || parameter.scheduleOverridedParameterDefaultValueJson === '{"Value":[""]}')) {

            if (parameter.parameterDefaultValueJson === '' || parameter.parameterDefaultValueJson === undefined) {
                var errorMessage = (
                    <span className='text-danger'>Warning! The parameter's value cannot be Null</span>
                );

                
                return errorMessage;
            }
            else {

                return '';

            }
        } 

   
        var defValue;



        if (forSchedule) {
            defValue = JSON.parse(parameter.scheduleOverridedParameterDefaultValueJson);
        }
        else {
            defValue = JSON.parse(parameter.parameterDefaultValueJson);
        }


        if (defValue !== '' && defValue !== undefined) {


            var allValues = JSON.parse(parameter.parameterValidValuesJson);

            if (defValue.Value.constructor.toString().indexOf("Array") > -1) {

                for (var i = 0; i < allValues.length; i++) {

                    for (var j = 0; j < defValue.Value.length; j++) {

                        if (allValues[i].Value === defValue.Value[j]) {

                            if (result !== "") {

                                result = result + ", ";
                            }
                            result = result + allValues[i].Label;
                        }
                    }
                }
            }
            else {

                for (var i = 0; i < allValues.length; i++) {

                    if (allValues[i].Value === defValue.Value) {
                        if (result !== "") {

                            result = result + ", ";
                        }
                        result = result + allValues[i].Label;
                    }
                }
            }

        }


        return result;
    };


    public componentDidUpdate(prevProps: IAssignSchedulePaginatedReportParametersProps) {
        const { editSchedulePaginatedReportParameters } = this.props;

        if (prevProps.editSchedulePaginatedReportParameters !== editSchedulePaginatedReportParameters) {
            this.setState({
                editSchedulePaginatedReportParameters: editSchedulePaginatedReportParameters
            });
        }

    }


    public getReportName = (reportId: number) => {
        const { editSchedulePaginatedReportParameters } = this.state;

        return (editSchedulePaginatedReportParameters.find(element => element.reportId === reportId) as ISchedulePaginatedReportParameter).reportDisplayName;

    }

    public getReportParameters = (reportId: number) => {
        const { editSchedulePaginatedReportParameters } = this.state;

        let filteredParams = editSchedulePaginatedReportParameters.filter(param => param.reportId === reportId);

        return filteredParams;
    }


    public handleValidValuesChange = (event: React.ChangeEvent<HTMLSelectElement>, parameterId: number) => {
        event.preventDefault();

        const { editSchedulePaginatedReportParameters } = this.state;
        const { accessToken, onHandleUpdateSchedulePaginatedReportParameter } = this.props;

        var param = editSchedulePaginatedReportParameters.find(par => par.parameterId === parameterId);

        if (param !== null && param !== undefined) {

            let collection = event.target.selectedOptions;
            let output = "";

            for (let i = 0; i < collection.length; i++) {
                if (output === "") {
                    output = "{\"Value\":[";
                }

                output += "\"";
                output += collection[i].value;
                output += "\"";

                if (i < (collection.length - 1)) {
                    output += ", ";
                } else if (i === (collection.length - 1)) {
                    output += "]}";
                }

            }


            param.scheduleOverridedParameterDefaultValueJson = output;

            onHandleUpdateSchedulePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editSchedulePaginatedReportParameters }));
        }
    }

    public handleValidValuesInputTextOnBlur = (event: React.ChangeEvent<HTMLInputElement>, parameterId: number) => {      

        const { editSchedulePaginatedReportParameters } = this.state;
        const { accessToken, onHandleUpdateSchedulePaginatedReportParameter } = this.props;

        event.preventDefault();


        var param = editSchedulePaginatedReportParameters.find(par => par.parameterId === parameterId);
        let output = "";


        if (param !== null && param !== undefined) {

            if (event.target.value != null) {

                let collection = event.target.value.split(",");
                for (let i = 0; i < collection.length; i++) {
                    if (output === "") {
                        output = "{\"Value\":[";
                    }
                    output += "\"";
                    output += collection[i];
                    output += "\"";

                    if (i < (collection.length - 1)) {
                        output += ", ";
                    } else if (i === (collection.length - 1)) {
                        output += "]}";
                    }
                }
            }

            param.scheduleOverridedParameterDefaultValueJson = output;

              onHandleUpdateSchedulePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editSchedulePaginatedReportParameters }));
        }

    }


    public handleValidValuesInputTextChange = (event: React.ChangeEvent<HTMLInputElement>, parameterId: number) => {

        const { editSchedulePaginatedReportParameters } = this.state;
        const { accessToken, onHandleUpdateSchedulePaginatedReportParameter } = this.props; 
        event.preventDefault();

        var param = editSchedulePaginatedReportParameters.find(par => par.parameterId === parameterId);
        let output = "";


        if (param !== null && param !== undefined) {

            if (event.target.value != null) {

                let collection = event.target.value.split(",");
                for (let i = 0; i < collection.length; i++) {
                    if (output === "") {
                        output = "{\"Value\":[";
                    }
                    output += "\"";
                    output += collection[i];
                    output += "\"";

                    if (i < (collection.length - 1)) {
                        output += ", ";
                    } else if (i === (collection.length - 1)) {
                        output += "]}";
                    }
                }
            }

            param.scheduleOverridedParameterDefaultValueJson = output;

         //   onHandleUpdateSchedulePaginatedReportParameter(accessToken, param);

            this.setState(() => ({ editSchedulePaginatedReportParameters }));
        }
    };


    public render() {
        const {

            editSchedulePaginatedReportParametersLoaded
        } = this.props;
        const {
            editSchedulePaginatedReportParameters

        } = this.state;

        const reports = Array.from(new Set(editSchedulePaginatedReportParameters.map((item: ISchedulePaginatedReportParameter) => item.reportId)));
        var i = 0;
        return (
            <Card>
                <CardBody>
                    <h1>Schedule Report Parameters</h1>
                    {!editSchedulePaginatedReportParametersLoaded && (
                        <DataLoading dataTitle="Schedule Report Parameters:" />
                    )}
                    {editSchedulePaginatedReportParameters && (

                        reports.map((reportId: number) => (
                            <div key={reportId}>
                                <h2>{this.getReportName(reportId)}</h2>
                                <Table striped={true} bordered>
                                    <thead>
                                        <tr>
                                            <th>Parameter Name</th>
                                            <th>Parameter Value</th>
                                            <th>Parameter Default Value</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.getReportParameters(reportId)).map((item) =>
                                            <tr key={item.parameterId}>
                                                <td>{item.parameterDisplayName}</td>
                                                <td>
                                                    {item.parameterIsMultiValue && item.parameterType !== 'InputText' && (<div><select
                                                        id="{item.parameterId}"
                                                        multiple={true}
                                                        defaultValue={this.getParameterDefaultValue(item)}
                                                        onChange={e => this.handleValidValuesChange(e, item.parameterId)}
                                                    >
                                                        <option key={i++} value=''>---</option>
                                                        {
                                                            JSON.parse(item.parameterValidValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                                return <option key={i++}  value={obj.Value} >{obj.Label}</option>
                                                            })
                                                        }</select>
                                                        <div className='reportParameterTooltip'>   {this.getParameterDefaultLabel(item, true)} </div>
                                                    </div>
                                                    )}

                                                    {!item.parameterIsMultiValue && item.parameterType !== 'InputText' && (<div><select
                                                        id="{item.parameterId}"
                                                        defaultValue={this.getParameterDefaultValue(item).length > 0 ? this.getParameterDefaultValue(item)[0] : ""}
                                                        onChange={e => this.handleValidValuesChange(e, item.parameterId)}
                                                    >
                                                        <option key={i++}  value=''>---</option>
                                                        { 
                                                            JSON.parse(item.parameterValidValuesJson).map((obj: { Value: string; Label: string; }) => {
                                                                return <option key={i++}  value={obj.Value} >{obj.Label}</option>
                                                            })
                                                        }</select>
                                                        <div className='reportParameterTooltip'>   {this.getParameterDefaultLabel(item, true)} </div></div>
                                                    )}
                                                     
                                                    {item.parameterType === 'InputText' && (
                                                        <input
                                                            key={item.parameterId}
                                                            type="text"
                                                            value={this.getParameterValueAsString(item, false)}
                                                            onChange={e => this.handleValidValuesInputTextChange(e, item.parameterId)}                                                            
                                                            onBlur={e => this.handleValidValuesInputTextOnBlur(e, item.parameterId)}
                                                        /> 
                                                    )}
                                                </td>
                                                <td>
                                                    {item.parameterType !== 'InputText' && (
                                                    <div className="text-secondary">
                                                        {this.getParameterDefaultLabel(item, false)}
                                                        </div>)}

                                                    { item.parameterType === 'InputText' && (
                                                        <div className="text-secondary">
                                                        {this.getParameterValueAsString(item,true)}
                                                        </div>)}

                                                </td>

                                                {/*<td>*/}
                                                {/*    {!item.parameterIsMultiValue && item.parameterType !== 'InputText' && (*/}
                                                {/*        <div className="text-secondary">*/}
                                                {/*            {parse(this.getParameterDefaultLabel(item, true))}*/}
                                                {/*        </div>)}*/}
                                                {/*</td>*/}


                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        ))
                    )}

                </CardBody>
            </Card >


        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,

        editSchedulePaginatedReportParameters: state.editSchedulePaginatedReportParameters,
        editSchedulePaginatedReportParametersLoaded: state.loadingEditSchedulePaginatedReportParameters,

        scheduleId: state.editScheduleDetails.id,
        // tslint:disable-next-line:object-literal-sort-keys
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadSchedulePaginatedReportParameters: (accessToken: string, scheduleId: number) => {
        dispatch(handleLoadEditSchedulePaginatedReportParameters(accessToken, scheduleId));
    },

    onHandleUpdateSchedulePaginatedReportParameter: (
        accessToken: string,
        schedulePaginatedReportParameter: ISchedulePaginatedReportParameter
    ) => {
        dispatch(handleUpdateEditSchedulePaginatedReportParameter(accessToken, schedulePaginatedReportParameter));
    },


});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignSchedulePaginatedReportParameters);

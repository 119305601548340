import * as Redux from "redux";
import getEditSchedulePaginatedReportParameters from "../api/getSchedulePaginatedReportParameters";
import saveUpdatedEditSchedulePaginatedReportParameter from "../api/saveUpdatedEditSchedulePaginatedReportParameter";
import ISchedulePaginatedReportParameter from "../models/ISchedulePaginatedReportParameter";
import { setEditSchedulePaginatedReportParametersLoaded } from "./loadingEditSchedulePaginatedReportParameters";


export const UPDATE_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETER = "UPDATE_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETER";
export const LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS = "LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS";
 
 

export const updateEditSchedulePaginatedReportParameter = (editSchedulePaginatedReportParameter: ISchedulePaginatedReportParameter) => {
    return {
        editSchedulePaginatedReportParameter,
        type: UPDATE_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETER
    };
};

export const loadEditSchedulePaginatedReportParameters = (editSchedulePaginatedReportParameters: ISchedulePaginatedReportParameter[]) => {
    return {
        editSchedulePaginatedReportParameters,
        type: LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS
    };
};

export const handleUpdateEditSchedulePaginatedReportParameter = (
    accessToken: string,
    editSchedulePaginatedReportParameter: ISchedulePaginatedReportParameter
) => {
    return async (dispatch: Redux.Dispatch) => {
        const dsh = await saveUpdatedEditSchedulePaginatedReportParameter(accessToken, editSchedulePaginatedReportParameter);
        if (dsh !== null && dsh !== undefined) {
            dispatch(updateEditSchedulePaginatedReportParameter(editSchedulePaginatedReportParameter));
        }
    };
};
 

export const handleLoadEditSchedulePaginatedReportParameters = (accessToken: string, scheduleId:number) => {
    return async (dispatch: Redux.Dispatch) => {
        const editSchedulePaginatedReportParameters = await getEditSchedulePaginatedReportParameters(accessToken, scheduleId);
        if (editSchedulePaginatedReportParameters !== undefined) {
            if (editSchedulePaginatedReportParameters.length > 0) {
              
                dispatch(loadEditSchedulePaginatedReportParameters(editSchedulePaginatedReportParameters));
            }
            dispatch(setEditSchedulePaginatedReportParametersLoaded(true));
        }
    };
};

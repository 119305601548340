import { AnyAction } from "redux";
import {
  CLEAR_EDIT_SCHEDULE_DETAILS,
  SET_EDIT_SCHEDULE_DETAILS
} from "../actions/editScheduleDetails";
import ISchedule from "../models/ISchedule";

export const editScheduleDetails = (
  state = {} as ISchedule,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_EDIT_SCHEDULE_DETAILS:
      return action.schedule as ISchedule;
    case CLEAR_EDIT_SCHEDULE_DETAILS:
      return {} as ISchedule;
    default:
      return state;
  }
};

export default editScheduleDetails;

import * as Redux from "redux";
import getPaginatedReports from "../api/getPaginatedReports";
import savePaginatedReport from "../api/savePaginatedReport";
import saveUpdatedPaginatedReport from "../api/saveUpdatedPaginatedReport";
import { removePaginatedReport as deletePaginatedReport } from "../api/removePaginatedReport";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { setPaginatedReportsLoaded } from "./loadingPaginatedReports";
import createPaginatedReportParameters from "../api/createPaginatedReportParameters";


export const ADD_PAGINATED_REPORT = "ADD_PAGINATED_REPORT";
export const REMOVE_PAGINATED_REPORT = "REMOVE_PAGINATED_REPORT";
export const UPDATE_PAGINATED_REPORT = "UPDATE_PAGINATED_REPORT";
export const LOAD_PAGINATED_REPORTS = "LOAD_PAGINATED_REPORTS";
export const ADD_PAGINATED_REPORT_PARAMETERS = "ADD_PAGINATED_REPORT_PARAMETERS";



export const addPaginatedReportParameters = (paginatedReport: IPbiPaginatedReport) => {
    return {
        paginatedReport,
        // tslint:disable-next-line:object-literal-sort-keys
        type: ADD_PAGINATED_REPORT_PARAMETERS
    };
};



export const addPaginatedReport = (paginatedReport: IPbiPaginatedReport) => {
    return {
        paginatedReport,
        type: ADD_PAGINATED_REPORT
    };
};

export const removePaginatedReport = (id: number) => {
    return {
        id,
        type: REMOVE_PAGINATED_REPORT
    };
};

export const updatePaginatedReport = (paginatedReport: IPbiPaginatedReport) => {
    return {
        paginatedReport,
        type: UPDATE_PAGINATED_REPORT
    };
};

export const loadPaginatedReports = (paginatedReports: IPbiPaginatedReport[]) => {
    return {
        paginatedReports,
        type: LOAD_PAGINATED_REPORTS
    };
};



export const handleCreatePaginatedReportParameters = (
    accessToken: string,
    pbiPaginatedReport: IPbiPaginatedReport
) => {
    return async (dispatch: Redux.Dispatch) => {
        const updatedPaginatedReport = await createPaginatedReportParameters(
            accessToken,
            pbiPaginatedReport.groupId,
            pbiPaginatedReport.reportId,
            pbiPaginatedReport.id.toString()
        );
        if (updatedPaginatedReport !== null && updatedPaginatedReport !== undefined) {          
            dispatch(addPaginatedReportParameters(updatedPaginatedReport)); 
        }
    };
};




export const handleSavePaginatedReport = (
    accessToken: string,
    name: string,
    displayName: string,
    groupId: string,
    reportId: string,    
    isEnabled: boolean
) => {
    return async (dispatch: Redux.Dispatch) => {
        const paginatedReport = await savePaginatedReport(
            accessToken,
            name,
            displayName,
            groupId,
            reportId,            
            isEnabled
        );
        if (paginatedReport !== null && paginatedReport !== undefined) {
            dispatch(addPaginatedReport(paginatedReport));
        }
    };
};

export const handleUpdatePaginatedReport = (
    accessToken: string,
    paginatedReport: IPbiPaginatedReport
) => {
    return async (dispatch: Redux.Dispatch) => {
        const rpt = await saveUpdatedPaginatedReport(accessToken, paginatedReport);
        if (rpt !== null && rpt !== undefined) {
            dispatch(updatePaginatedReport(rpt));
        }
    };
};

export const handleDeletePaginatedReport = (accessToken: string, id: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const result = await deletePaginatedReport(accessToken, id);
        if (result === 200) {
            dispatch(removePaginatedReport(id));
        }
    };
};

export const handleLoadPaginatedReports = (accessToken: string) => {
    return async (dispatch: Redux.Dispatch) => {
        const paginatedReports = await getPaginatedReports(accessToken);
        if (paginatedReports !== undefined) {
            if (paginatedReports.length > 0) {
                paginatedReports.sort((a, b) =>
                    a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
                );
                dispatch(loadPaginatedReports(paginatedReports));
            }
            dispatch(setPaginatedReportsLoaded(true));
        }
    };
};

import ISchedule from "../models/ISchedule";

export const SET_EDIT_SCHEDULE_DETAILS = "SET_EDIT_SCHEDULE_DETAILS";
export const CLEAR_EDIT_SCHEDULE_DETAILS = "CLEAR_EDIT_SCHEDULE_DETAILS";

export const setEditScheduleDetails = (schedule: ISchedule) => {
  return {
    schedule,
    type: SET_EDIT_SCHEDULE_DETAILS
  };
};

export const clearEditScheduleDetails = () => {
  return {
    type: CLEAR_EDIT_SCHEDULE_DETAILS
  };
};

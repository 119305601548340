import IPbiDashboardShared from "../models/IPbiDashboardShared";
import { fetchSecureData } from "../utils";

export const getEmailedDashboard = async (
    accessToken: string,
    uid:string) => {
  const data = await fetchSecureData(
    "GET",
      `/api/PbiDashboard/GetShareDashboardByUid?uid=${uid}`,
    accessToken
  );
    const emailedPbiDashboard = ((await data.json()) as any).value as IPbiDashboardShared;
  if (emailedPbiDashboard !== undefined) {
        return emailedPbiDashboard;
  } else {
        return {} as IPbiDashboardShared;
  }
};

export default getEmailedDashboard;

import * as React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

export interface IAddTextFieldForDateProps {
  allowEmptyValues?:boolean;
  noDuplicates: boolean;
  value: string;
  existingValues: string[];
  callback: (value: string) => void;
  isValid: (value: string, format:string) => boolean;
  title: string;
  dateFormat: string;
}

export interface IAddTextFieldForDateState {
  value: string;  
  valueHasInvalidChars: boolean;
  duplicateValue: boolean;
}

class AddTextFieldForDate extends React.Component<
  IAddTextFieldForDateProps,
  IAddTextFieldForDateState
> {
  public state = {
    duplicateValue: false,
    value: "",   
    valueHasInvalidChars: false 
    };

  constructor(props: IAddTextFieldForDateProps) {
    super(props);
    this.valueChange = this.valueChange.bind(this);
    this.valueFocus = this.valueFocus.bind(this);
    this.valueBlur = this.valueBlur.bind(this);
    this.valueIsValid = this.valueIsValid.bind(this);
  }
  public valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value =
      e.target.value === undefined ? "" : (e.target.value as string);
      this.setState(() => ({ value }));    
  };
  public valueFocus = () => {
    this.setState({
      duplicateValue: false,
      valueHasInvalidChars: false
    });
  };
  public valueBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { callback } = this.props;
    e.preventDefault();
    const value =
      e.target.value === undefined ? "" : (e.target.value as string).trim();
    await this.setState(() => ({
      value
    }));
    if (this.valueIsValid()) {
      callback(value);
    } else {
      this.setState(() => ({ value: this.props.value }));
    }
  };
  public valueIsValid = () => {
    const { isValid, noDuplicates, allowEmptyValues,dateFormat} = this.props;
    const { value} = this.state;
      if ((!allowEmptyValues && value === "") || value === undefined || !isValid(value, dateFormat)) {
      this.setState(() => ({ valueHasInvalidChars: true }));
      return false;
    }
    if (noDuplicates) {
      const { existingValues } = this.props;
      if (
        existingValues.find(
          dn => dn.trim().toLowerCase() === value.trim().toLowerCase()
        ) !== undefined
      ) {
        this.setState(() => ({ duplicateValue: true }));
        return false;
      }
    }
    return true;
  };
  public async componentDidUpdate(prevProps: IAddTextFieldForDateProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      await this.setState(() => ({ value }));
    }
  }
  public async componentDidMount() {
    const { value } = this.props;
    if (value !== undefined) {
      await this.setState(() => ({ value }));
    }
  }
  public render() {
    const { title, noDuplicates } = this.props;
    const { value, valueHasInvalidChars, duplicateValue } = this.state;
    return (
      <FormGroup>
        {title!=="" && (<Label for="value">{title}</Label>)}
        <Input
          id="value"
          type="text"
          placeholder={title}
          value={value}
          onChange={this.valueChange}
          onFocus={this.valueFocus}
          onBlur={this.valueBlur}
          invalid={
            noDuplicates
              ? duplicateValue || valueHasInvalidChars
              : valueHasInvalidChars
          }
        />
        <FormFeedback tooltip={true}>
          {this.state.valueHasInvalidChars && (
            <span>Invalid characters were present in {title}. The value of {title} was reset.</span>
          )}
          {this.state.duplicateValue && (
            <span>
              {title} value entered is already used. Duplicates not allowed. The value of {title} was reset.
            </span>
          )}
        </FormFeedback>
      </FormGroup>
    );
  }
}

export default AddTextFieldForDate;

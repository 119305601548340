import * as Redux from "redux";
import getScheduleEvents from "../api/getScheduleEvents";
import IScheduleEvent from "../models/IScheduleEvent";
import { setScheduleEventsLoaded } from "./loadingScheduleEvents";

//export const ADD_SCHEDULE_EVENT = "ADD_SCHEDULE_EVENT";
//export const REMOVE_SCHEDULE_EVENT = "REMOVE_SCHEDULE_EVENT";
//export const UPDATE_SCHEDULE_EVENT = "UPDATE_SCHEDULE_EVENT";
export const LOAD_SCHEDULE_EVENTS = "LOAD_SCHEDULE_EVENTS";

//export const addScheduleEvent = (scheduleEvent: IScheduleEvent) => {
//  return {
//    scheduleEvent,
//    type: ADD_SCHEDULE_EVENT
//  };
//};

//export const removeScheduleEvent = (id: number) => {
//  return {
//    id,
//    type: REMOVE_SCHEDULE_EVENT
//  };
//};

//export const updateScheduleEvent = (scheduleEvent: IScheduleEvent) => {
//  return {
//    scheduleEvent,
//    type: UPDATE_SCHEDULE_EVENT
//  };
//};

export const loadScheduleEvents = (scheduleEvents: IScheduleEvent[]) => {
  return {
    scheduleEvents,
    type: LOAD_SCHEDULE_EVENTS
  };
};

export const handleLoadScheduleEvents = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const scheduleEvents = await getScheduleEvents(accessToken);
    if (scheduleEvents !== undefined) {
      if (scheduleEvents.length > 0) {
        scheduleEvents.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        dispatch(loadScheduleEvents(scheduleEvents));
      }
      dispatch(setScheduleEventsLoaded(true));
    }
  };
};

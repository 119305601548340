import IPbiBookmark from "../models/IPbiBookmark";
import { fetchSecureData } from "../utils";

export const saveUpdatedBookmark = async (
  accessToken: string,
  bookmark: IPbiBookmark
) => {
  const payload = {
    id: bookmark.id,
    name: bookmark.name,
    // tslint:disable-next-line:object-literal-sort-keys
    capturedBookmark: bookmark.capturedBookmark,
    userProfileId: bookmark.userProfileId,
    roleId: bookmark.roleId,
    pbiDashboardId: bookmark.pbiDashboardId,
    isDefault: bookmark.isDefault 
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/PbiBookmark/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IPbiBookmark;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/PbiBookmark/Edit`, err);
  }
  return {} as IPbiBookmark;
};

export default saveUpdatedBookmark;

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, ListGroup, ListGroupItem } from "reactstrap";
import ListGroupItemHeading from "reactstrap/lib/ListGroupItemHeading";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import paths from "../../constants";
import IUserFavorite from "../../models/IUserFavorite";
import FavoritesIcon from "./FavoritesIcon";
import Icon from "./Icon";
 
import Graph from "../../assets/icons/GraphBlack.svg";

export interface IUserReportsCardProps {
    reports: ISqlReport[];
    userFavoritesReports: IUserFavorite[];
}

const UserReportsCard: React.SFC<IUserReportsCardProps> = (
  props: IUserReportsCardProps
) => {
    

    const { reports, userFavoritesReports } = props;


    const filteresReports = reports.filter(el => {
        return userFavoritesReports.some(f => {
            return f.reportId === el.id;
        });
    });


  return (
    <React.Fragment>
        <ListGroup>
          <ListGroupItemHeading style={{ backgroundColor: "white" }}>
                  <div className="textH2">  <Icon icon={Graph} text="Graph" id="report" /> Your Favorite Reports</div>
          </ListGroupItemHeading>
              {filteresReports.map(report => (
            <ListGroupItem action={true} variant="Light" key={report.id}>
              <Link to={paths.sqlReports.concat(report.id.toString())}>
                      <FavoritesIcon />{report.displayName}
              </Link>
            </ListGroupItem>
          ))}
          {/*{(reports === undefined ||*/}
          {/*  (reports !== undefined && reports.length === 0)) && (*/}
          {/*  <span>You have no available reports</span>*/}
          {/*)}*/}
        </ListGroup>    
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
      reports: state.userReports,
      userFavoritesReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "REPORT") : state.userFavorites,
  };
};

export default connect(mapStateToProps)(UserReportsCard);

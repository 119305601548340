import ISchedulePaginatedReportParameter from "../models/ISchedulePaginatedReportParameter";
import { fetchSecureData } from "../utils";

export const saveUpdatedEditSchedulePaginatedReportParameter = async (
    accessToken: string,
    editSchedulePaginatedReportParameter: ISchedulePaginatedReportParameter
) => {
    const payload = {

        ScheduleId: editSchedulePaginatedReportParameter.scheduleId,

        PbiPaginatedReportParameterId : editSchedulePaginatedReportParameter.parameterId,
        
        DefaultValueJson: editSchedulePaginatedReportParameter.scheduleOverridedParameterDefaultValueJson,
         
        IsEnabled: true


    };
    try {
        const data = await fetchSecureData(
            "PUT",
            `/api/Schedule/UpdateScheduleParameter`,
            accessToken,
            payload
        );
        const result = ((await data.json()) as any).value as ISchedulePaginatedReportParameter;
        // tslint:disable-next-line:no-console
        return result;
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for /api/Schedule/UpdateScheduleParameter` + payload, err);
    }
    return {} as ISchedulePaginatedReportParameter;
};

export default saveUpdatedEditSchedulePaginatedReportParameter;

import * as React from "react";
import Logo from "../../assets/images/logo_l.svg";
import Icon from "./Icon";

const LogoIcon = () => {
    var iconStyle = {
        height: 50  
    };

    return (
        <React.Fragment>
            <Icon icon={Logo} text="Logo" id="logo" style={iconStyle } />
        </React.Fragment>
    );
};

export default LogoIcon;

import { fetchSecureData } from "../utils/index";

export const copySchedule = async (accessToken: string, id: number, userId:number) => {
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/Schedule/Copy?id=${id}&userId=${userId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Schedule/Copy?id=${id}`, err);
  }
  return 404;
};

export default copySchedule;

import IScheduleReportFormat from "../models/IScheduleReportFormat";
import { fetchSecureData } from "../utils/index";

export const saveUpdatedScheduleReportFormat = async (
    accessToken: string,
    id: string,
    formatName: string,
    formatValue: boolean
) => {
   
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/SchedulePaginatedReport/EditFormat?id=${id}&formatName=${formatName}&formatValue${formatValue}`, 
      accessToken,
       
    );
      const result = ((await data.json()) as any).value as IScheduleReportFormat;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
      console.log(`Fetch error for /api/SchedulePaginatedReport/EditFormat`, err);
  }
    return {} as IScheduleReportFormat;
};

export default saveUpdatedScheduleReportFormat;

import * as React from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import {
  clearEditRoleReportIds,
  handleDeleteEditRoleReportId,
  handleLoadEditRoleReportIds,
  handleSaveEditRoleReportId
} from "../../../actions/editRoleReportIds";
import { handleLoadReports } from "../../../actions/reports";
import ReportsBadge from "../../../components/molecules/ReportsBadge";
import DataLoading from "../../../components/organisms/DataLoading";
import ISqlReport from "../../../models/ISqlReport";
import { IStore } from "../../../models/IStore";

export interface IAssignRoleReportsState {
  editRoleReportIds: number[];
  filteredReports: ISqlReport[];
}

export interface IAssignRoleReportsProps {
  accessToken: string;
  reports: ISqlReport[];
  reportsLoaded: boolean;
  editRoleReportIds: number[];
  editRoleReportIdsLoaded: boolean;
  roleId: number;
  onHandleLoadReports: (accessToken: string) => void;
  onHandleLoadEditRoleReportIds: (accessToken: string, roleId: number) => void;
  onClearEditRoleReportIds: () => void;
  onHandleSaveEditRoleReportId: (
    accessToken: string,
    roleId: number,
    reportId: number
  ) => void;
  onHandleDeleteEditRoleReportId: (
    accessToken: string,
    roleId: number,
    reportId: number
  ) => void;
}

class AssignRoleReports extends React.Component<
  IAssignRoleReportsProps,
  IAssignRoleReportsState
> {
  public state = {
    editRoleReportIds: [] as number[],
    filteredReports: [] as ISqlReport[]
  };
  constructor(props: IAssignRoleReportsProps) {
    super(props);
    this.reportCheckboxChange = this.reportCheckboxChange.bind(this);
    this.txtFilterChange = this.txtFilterChange.bind(this);
  }
  public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { reports } = this.props;
    const filteredReports = reports.filter(report =>
      report.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    await this.setState(() => ({ filteredReports }));
  };
  public reportCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      accessToken,
      roleId,
      onHandleSaveEditRoleReportId,
      onHandleDeleteEditRoleReportId
    } = this.props;
    e.preventDefault();
    if (e.target.checked) {
      await onHandleSaveEditRoleReportId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    } else {
      await onHandleDeleteEditRoleReportId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    }
  };
  public async componentDidMount() {
    const {
      accessToken,
      reports,
      reportsLoaded,
      editRoleReportIds,
      onHandleLoadReports,
      onHandleLoadEditRoleReportIds,
      roleId
    } = this.props;
    if (!reportsLoaded) {
      await onHandleLoadReports(accessToken);
    }
    await onHandleLoadEditRoleReportIds(accessToken, roleId);
    this.setState({ editRoleReportIds });
    this.setState({ filteredReports: reports });
  }

  public async componentDidUpdate(prevProps: IAssignRoleReportsProps) {
    const { editRoleReportIds, reports } = this.props;
    if (prevProps.reports !== reports) {
      await this.setState(() => ({ filteredReports: reports }));
    }
    if (prevProps.editRoleReportIds !== editRoleReportIds) {
      await this.setState(() => ({ editRoleReportIds }));
    }
  }

  public render() {
    const { filteredReports, editRoleReportIds } = this.state;
    const { reports, reportsLoaded, editRoleReportIdsLoaded } = this.props;
    return (
      <React.Fragment>
        {!reportsLoaded && <DataLoading dataTitle="Reports " />}
        {!editRoleReportIdsLoaded && <DataLoading dataTitle="Role Reports " />}
        {reportsLoaded && editRoleReportIdsLoaded && (
          <Container>
            <Row>
              <Col>
                <h2>Assign SSRS Reports</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                {reportsLoaded && (
                  <ReportsBadge
                    reports={reports}
                    reportIds={editRoleReportIds}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="txtFilter">Search: </Label>
                <Input
                  type="text"
                  id="txtFilter"
                  onChange={this.txtFilterChange}
                  placeholder="Search for..."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup>
                  {filteredReports.map(report => (
                    <ListGroupItem key={report.id}>
                      <FormGroup check={true}>
                        <Label check={true}>
                          <Input
                            type="checkbox"
                            value={report.id}
                            onChange={this.reportCheckboxChange}
                            checked={
                              this.state.editRoleReportIds.find(
                                id => id === report.id
                              ) !== undefined
                            }
                          />
                          {report.displayName}
                        </Label>
                      </FormGroup>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  const { onSave } = ownProps;
  return {
    accessToken: state.auth0.accessToken,
    reports: state.reports,
    reportsLoaded: state.loadingReports,
    roleId: state.editRoleDetails.id,
    // tslint:disable-next-line:object-literal-sort-keys
    editRoleReportIds: state.editRoleReportIds,
    editRoleReportIdsLoaded: state.loadingEditRoleReports,
    onSave
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadReports: (accessToken: string) => {
    dispatch(handleLoadReports(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadEditRoleReportIds: (accessToken: string, roleId: number) => {
    dispatch(handleLoadEditRoleReportIds(accessToken, roleId));
  },
  onClearEditRoleReportIds: () => {
    dispatch(clearEditRoleReportIds());
  },
  onHandleSaveEditRoleReportId: (
    accessToken: string,
    roleId: number,
    reportId: number
  ) => {
    dispatch(handleSaveEditRoleReportId(accessToken, roleId, reportId));
  },
  onHandleDeleteEditRoleReportId: (
    accessToken: string,
    roleId: number,
    reportId: number
  ) => {
    dispatch(handleDeleteEditRoleReportId(accessToken, roleId, reportId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignRoleReports);

import * as Redux from "redux";
import saveActiveRole from "../api/saveActiveRole";

export const SET_ACTIVE_ROLE_ID = "SET_ACTIVE_ROLE_ID";
export const CLEAR_ACTIVE_ROLE_ID = "CLEAR_ACTIVE_ROLE_ID";
export const SAVE_ACTIVE_ROLE_ID = "SAVE_ACTIVE_ROLE_ID";



export const setActiveRoleId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_ROLE_ID
  };
};

export const clearActiveRoleId = () => {
  return {
    type: CLEAR_ACTIVE_ROLE_ID
  };
};


export const saveActiveRoleId = (id: number,userId: number) => {
    return {
        id,
        userId,
        type: SAVE_ACTIVE_ROLE_ID
    };
};


export const handleSaveRole = (accessToken: string,
    roleId: number,
    userId: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const activeRoleId = await saveActiveRole(
            accessToken,
            roleId,
            userId
        );
        if (activeRoleId !== null && activeRoleId !== undefined) {
            dispatch(saveActiveRoleId(activeRoleId, userId));
            dispatch(setActiveRoleId(activeRoleId));
        }
    };
};
 
export const SET_EDIT_SCHEDULE_REPORT_FORMATS_LOADED =
  "SET_EDIT_SCHEDULE_REPORT_FORMATS_LOADED";

export const setEditScheduleReportFormatsLoaded = (
  editScheduleReportFormatsLoaded: boolean
) => {
  return {
    editScheduleReportFormatsLoaded,
    type: SET_EDIT_SCHEDULE_REPORT_FORMATS_LOADED
  };
};

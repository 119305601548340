import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { handleLoadRoles } from "../../../actions/roles";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IRole from "../../../models/IRole";
import { IStore } from "../../../models/IStore";

export interface ISearchRolesPageProps {
  accessToken: string;
  onHandleLoadRoles: (accessToken: string) => void;
  roles: IRole[];
  rolesLoaded: boolean;
}

export interface ISearchRolesPageState {
  filteredRoles: IRole[];
  matchedNameExact: boolean;
  name: string;
  noMatchingNames: boolean;
}
class SearchRolesPage extends React.Component<
  ISearchRolesPageProps,
  ISearchRolesPageState
> {
  public isLoaded = false;
  public state = {
    filteredRoles: [] as IRole[],
    matchedNameExact: false,
    name: "",
    noMatchingNames: false
  };
  constructor(props: ISearchRolesPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { roles } = this.props;

    const searchRoleDisplayName = e.target.value.toLowerCase();
    if (!searchRoleDisplayName) {
      this.setState({
        filteredRoles: roles
      });
    } else {
      const filteredRoles: IRole[] = roles.filter((role: IRole) =>
        role.displayName.toLowerCase().includes(searchRoleDisplayName)
      );
      if (filteredRoles.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredRoles.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredRoles
      });
    }
  }

  public componentDidMount() {
    const { accessToken, onHandleLoadRoles, roles, rolesLoaded } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!rolesLoaded) {
        onHandleLoadRoles(accessToken);
      }
      this.setState({ filteredRoles: roles });
    }
  }
  public componentDidUpdate(prevProps: ISearchRolesPageProps) {
    // const { roles } = this.props;
    // if (prevProps.roles !== roles) {
    //   this.setState({ filteredRoles: roles });
    // }
  }
  public render() {
    const { roles, rolesLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      name,
      filteredRoles
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>Role Administration</h1>
        {!rolesLoaded && <DataLoading dataTitle="Roles" />}
        {rolesLoaded && (
          <React.Fragment>
            <FormGroup>
                        <Card Style="width: 48rem; border-radius: 20% center">
                <CardTitle>
                  <Label for="name">Select Role To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Filter Role"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        Role found! Select to edit
                        {name}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No roles match name entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
                    <Card Style="width: 48rem; border-radius: 20% center">
              <CardBody>
                {roles.length > 0 &&
                  filteredRoles.map((role: IRole) => (
                    <div key={role.id}>
                      <Link to={paths.editRole.concat(role.id.toString())}>
                        <span>{role.displayName}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    roles: state.roles,
    rolesLoaded: state.loadingRoles
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadRoles: (accessToken: string) => {
    dispatch(handleLoadRoles(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchRolesPage);

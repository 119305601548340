import { fetchSecureData } from "../utils";

export const removeScheduleUser = async (
    accessToken: string,
    scheduleId: number,
    roleId: number,
    userId: number
) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/ScheduleUser/Remove?scheduleId=${scheduleId}&roleId=${roleId}&userId=${userId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
      console.log(`Fetch error for /api/ScheduleUser/Remove?scheduleId=${scheduleId}&roleId${roleId}&userId=${userId}`, err);
  }
  return 404;
};

export default removeScheduleUser;

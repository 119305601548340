import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import paths from "../../constants";
import { IStore } from "../../models/IStore";
import Jumbotron from "../atoms/Jumbotron";
import Text from "../atoms/Text";
import LoginButton from "../molecules/LoginButton";
import LogoIcon from "../molecules/LogoIcon";
import PageFooter from "../molecules/PageFooter";





export interface ISplashPageProps extends RouteComponentProps {
    auth: Auth;
    isAuthenticated: boolean;
}

const SplashPage = (props: ISplashPageProps) => {
    const { auth, isAuthenticated, history } = props;
    if (isAuthenticated) {
        history.push(paths.home);
    }
    var textStyle = { paddingRight: "1em", color: "#000", fontSize: "1rem" }

    return (
        <div>
            {!isAuthenticated && (
                <React.Fragment>
                    <Jumbotron style={{paddingLeft:'350px'}}>
                        <LogoIcon />
                        <div style={{ paddingTop: "150px" }}>
                            <div style={{ fontSize: "52px" }}><strong>ctm</strong> data hub</div>


                            <div style={{ width: "48rem", paddingBottom: "30px"   }}>
                                <Text style={textStyle}>
                                    The <strong>CTM Data Hub</strong> is your one-stop shop for travel analytics and reporting &#8211; providing dashboards, ad-hoc reporting and scheduled reports, giving you a clear view of your travel spend, policy compliance and supplier usage.
                                </Text>
                            </div>


                            <LoginButton
                                outline={false}
                                login={
                                    // tslint:disable-next-line:jsx-no-lambda
                                    () => {
                                        auth.login();
                                    }
                                }
                            />
                        </div>
                    </Jumbotron>
                    <PageFooter />
                </React.Fragment>
            )}
        </div>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        auth: state.auth0,
        isAuthenticated: state.auth0.isAuthenticated()
    };
};

export default connect(mapStateToProps)(SplashPage);

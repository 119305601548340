import * as React from "react";
import { connect } from "react-redux";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import {
    clearEditUserGroupIds,
    handleDeleteEditUserGroupId,
    handleLoadEditUserGroupIds,
    handleSaveEditUserGroupId
} from "../../../actions/editUserGroupIds";
import { handleLoadGroups } from "../../../actions/groups";
import GroupsBadge from "../../../components/molecules/GroupsBadge";
import IGroup from "../../../models/IGroup";
import { IStore } from "../../../models/IStore";

export interface IAssignUserGroupsState {
    editUserGroupIds: number[];
    filteredGroups: IGroup[];
}

export interface IAssignUserGroupsProps {
    accessToken: string;
    groups: IGroup[];
    groupsLoaded: boolean;
    editUserGroupIds: number[];
    userId: number;
    activeUser: number;
    onHandleLoadGroups: (accessToken: string) => void;
    onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => void;
    onClearEditUserGroupIds: () => void;
    onHandleSaveEditUserGroupId: (
        accessToken: string,
        userId: number,
        groupId: number,
        addedByUserProfileId: number
    ) => void;
    onHandleDeleteEditUserGroupId: (
        accessToken: string,
        userId: number,
        groupId: number,
        addedByUserProfileId:number
    ) => void;
}

class AssignUserGroups extends React.Component<
    IAssignUserGroupsProps,
    IAssignUserGroupsState
> {
    public state = {
        editUserGroupIds: [] as number[],
        filteredGroups: [] as IGroup[]
    };
    constructor(props: IAssignUserGroupsProps) {
        super(props);
        this.groupCheckboxChange = this.groupCheckboxChange.bind(this);
        this.txtFilterChange = this.txtFilterChange.bind(this);
    }
    public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { groups } = this.props;
        const filteredGroups = groups.filter(group =>
            group.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        await this.setState(() => ({ filteredGroups }));
    };
    public groupCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const {
            accessToken,
            userId,
            onHandleSaveEditUserGroupId,
            onHandleDeleteEditUserGroupId,
            activeUser
        } = this.props;
        e.preventDefault();

        if (e.target.checked) {
            await onHandleSaveEditUserGroupId(
                accessToken,
                userId,
                Number(e.target.value),
                activeUser
            );
        } else {
            await onHandleDeleteEditUserGroupId(
                accessToken,
                userId,
                Number(e.target.value),
                activeUser
            );
        }
    };
    public async componentDidMount() {
        const {
            accessToken,
            groups,
            groupsLoaded,
            editUserGroupIds,
            onHandleLoadGroups,
            onHandleLoadEditUserGroupIds,
            userId
        } = this.props;
        if (!groupsLoaded) {
            await onHandleLoadGroups(accessToken);
        }
        await onHandleLoadEditUserGroupIds(accessToken, userId);
        this.setState({ editUserGroupIds });
        this.setState({ filteredGroups: groups });
    }

    public async componentDidUpdate(prevProps: IAssignUserGroupsProps) {
        const { editUserGroupIds, groups } = this.props;
        if (prevProps.groups !== groups) {
            await this.setState(() => ({ filteredGroups: groups }));
        }
        if (prevProps.editUserGroupIds !== editUserGroupIds) {
            await this.setState(() => ({ editUserGroupIds }));
        }
    }

    public render() {
        const { filteredGroups, editUserGroupIds } = this.state;
        const { groups } = this.props;
        return (
            <Container>
                <Row>
                    <Col>
                        <h2>Assign Groups</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GroupsBadge groups={groups} groupIds={editUserGroupIds} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="txtFilter">Search: </Label>
                        <Input
                            type="text"
                            id="txtFilter"
                            onChange={this.txtFilterChange}
                            placeholder="Search for..."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {filteredGroups.map(group => (
                                <ListGroupItem key={group.id}>
                                    <FormGroup check={true}>
                                        <Label check={true}>
                                            <Input
                                                type="checkbox"
                                                value={group.id}
                                                onChange={this.groupCheckboxChange}
                                                checked={
                                                    this.state.editUserGroupIds.find(
                                                        id => id === group.id
                                                    ) !== undefined
                                                }
                                            />
                                            {group.name}
                                        </Label>
                                    </FormGroup>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    const { onSave } = ownProps;
    return {
        accessToken: state.auth0.accessToken,
        groups: state.groups,
        groupsLoaded: state.loadingGroups,
        userId: state.editUserDetails.id,
        activeUser: state.userProfile.id,
        // tslint:disable-next-line:object-literal-sort-keys
        editUserGroupIds: state.editUserGroupIds,
        onSave
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadGroups: (accessToken: string) => {
        dispatch(handleLoadGroups(accessToken));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => {
        dispatch(handleLoadEditUserGroupIds(accessToken, userId));
    },
    onClearEditUserGroupIds: () => {
        dispatch(clearEditUserGroupIds());
    },
    onHandleSaveEditUserGroupId: (
        accessToken: string,
        userId: number,
        groupId: number,
        addedByUserProfileId: number
    ) => {
        dispatch(handleSaveEditUserGroupId(accessToken, userId, groupId, addedByUserProfileId));
    },
    onHandleDeleteEditUserGroupId: (
        accessToken: string,
        userId: number,
        groupId: number,
        addedByUserProfileId:number
    ) => {
        dispatch(handleDeleteEditUserGroupId(accessToken, userId, groupId, addedByUserProfileId));
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignUserGroups);

import * as React from "react";
import { connect } from "react-redux";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import { handleLoadPaginatedReports } from "../../actions/paginatedReports";
import {
    clearEditSchedulePaginatedReportIds,
    handleDeleteEditSchedulePaginatedReportId,
    handleLoadEditSchedulePaginatedReportIds,
    handleSaveEditSchedulePaginatedReportId
} from "../../actions/editSchedulePaginatedReportIds";
 
import DataLoading from "../../components/organisms/DataLoading";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { IStore } from "../../models/IStore";
import PaginatedReportsBadge from "../../components/molecules/PaginatedReportsBadge";



export interface IAssignSchedulePaginatedReportsState {
    editSchedulePaginatedReportIds: number[];
    filteredPaginatedReports: IPbiPaginatedReport[];
}

export interface IAssignSchedulePaginatedReportsProps {
    accessToken: string;
    paginatedReports: IPbiPaginatedReport[];
    paginatedReportsLoaded: boolean;
    editSchedulePaginatedReportIds: number[];
    editSchedulePaginatedReportIdsLoaded: boolean;
    scheduleId: number;
    onHandleLoadPaginatedReports: (accessToken: string) => void;

    onHandleLoadEditSchedulePaginatedReportIds: (
        accessToken: string,
        scheduleId: number
    ) => void;

    onClearEditSchedulePaginatedReportIds: () => void;
    onHandleSaveEditSchedulePaginatedReportId: (
        accessToken: string,
        scheduleId: number,
        paginatedReportId: number
    ) => void;
    onHandleDeleteEditSchedulePaginatedReportId: (
        accessToken: string,
        scheduleId: number,
        paginatedReportId: number
    ) => void;
}

class AssignSchedulePaginatedReports extends React.Component<
    IAssignSchedulePaginatedReportsProps,
    IAssignSchedulePaginatedReportsState
> {
    public state = {
        editSchedulePaginatedReportIds: [] as number[],
        filteredPaginatedReports: [] as IPbiPaginatedReport[]
    };
    constructor(props: IAssignSchedulePaginatedReportsProps) {
        super(props);
        this.paginatedReportCheckboxChange = this.paginatedReportCheckboxChange.bind(this);
        this.txtFilterChange = this.txtFilterChange.bind(this);
    }
    public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { paginatedReports } = this.props;
        const filteredPaginatedReports = paginatedReports.filter(paginatedReport =>
            paginatedReport.displayName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        await this.setState(() => ({ filteredPaginatedReports }));
    };
    public paginatedReportCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const {
            accessToken,
            scheduleId,
            onHandleSaveEditSchedulePaginatedReportId,
            onHandleDeleteEditSchedulePaginatedReportId
        } = this.props;
        e.preventDefault();
        if (e.target.checked) {
            await onHandleSaveEditSchedulePaginatedReportId(
                accessToken,
                scheduleId,
                Number(e.target.value)
            );
        } else {
            await onHandleDeleteEditSchedulePaginatedReportId(
                accessToken,
                scheduleId,
                Number(e.target.value)
            );
        }
    };

    public async componentWillMount() {
        const {
            onClearEditSchedulePaginatedReportIds
        } = this.props;

        await onClearEditSchedulePaginatedReportIds();

    }

    public async componentWillUnmount() {
        const {
            onClearEditSchedulePaginatedReportIds
        } = this.props;

        await onClearEditSchedulePaginatedReportIds();

    }

    public async componentDidMount() {
        const {
            accessToken,
            paginatedReports,
            paginatedReportsLoaded,
            editSchedulePaginatedReportIds,
            onHandleLoadPaginatedReports,
            onHandleLoadEditSchedulePaginatedReportIds,
            scheduleId
        } = this.props;
        if (!paginatedReportsLoaded) {
            await onHandleLoadPaginatedReports(accessToken);
        }
         
        await onHandleLoadEditSchedulePaginatedReportIds(accessToken, scheduleId);
        this.setState({ editSchedulePaginatedReportIds });
        this.setState({ filteredPaginatedReports: paginatedReports });
    }

    public async componentDidUpdate(prevProps: IAssignSchedulePaginatedReportsProps) {
        const { editSchedulePaginatedReportIds, paginatedReports } = this.props;
        if (prevProps.paginatedReports !== paginatedReports) {
            await this.setState(() => ({ filteredPaginatedReports: paginatedReports }));
        }
        if (prevProps.editSchedulePaginatedReportIds !== editSchedulePaginatedReportIds) {
            await this.setState(() => ({ editSchedulePaginatedReportIds }));
        }
    }

    public render() {
        const { filteredPaginatedReports, editSchedulePaginatedReportIds } = this.state;
        const {
            paginatedReports,
            paginatedReportsLoaded,
            editSchedulePaginatedReportIdsLoaded
        } = this.props;
        return (
            <React.Fragment>
                {!paginatedReportsLoaded && <DataLoading dataTitle="Reports " />}
                {!editSchedulePaginatedReportIdsLoaded && (
                    <DataLoading dataTitle="Schedule's Reports " />
                )}
                {paginatedReportsLoaded && editSchedulePaginatedReportIdsLoaded && (
                    <Container>                                             
                        <Row>
                            <Col>
                                {paginatedReportsLoaded && (
                                    <PaginatedReportsBadge
                                        paginatedReports={paginatedReports}
                                        paginatedReportIds={editSchedulePaginatedReportIds}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>                     
                            <Col>                              
                                <Input
                                    type="text"
                                    id="txtFilter"
                                    onChange={this.txtFilterChange}
                                    placeholder="Search for..."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <ListGroup>
                                    {filteredPaginatedReports.map(paginatedReport => (
                                        <ListGroupItem key={paginatedReport.id}>
                                            <FormGroup check={true}>
                                                <Label check={true}>
                                                    <Input
                                                        type="checkbox"
                                                        value={paginatedReport.id}
                                                        onChange={this.paginatedReportCheckboxChange}
                                                        checked={
                                                           editSchedulePaginatedReportIds.find(
                                                                id => id === paginatedReport.id
                                                            ) !== undefined
                                                        }
                                                    />
                                                    {paginatedReport.displayName}
                                                </Label>
                                            </FormGroup>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    const { onSave } = ownProps;
    return {
        accessToken: state.auth0.accessToken,
        paginatedReports: state.userPaginatedReports,
        paginatedReportsLoaded: state.loadingPaginatedReports,
        scheduleId: state.editScheduleDetails.id,
        // tslint:disable-next-line:object-literal-sort-keys
        editSchedulePaginatedReportIds: state.editSchedulePaginatedReportIds,
        editSchedulePaginatedReportIdsLoaded: state.loadingEditSchedulePaginatedReports,
        onSave
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },
    onHandleLoadEditSchedulePaginatedReportIds: (accessToken: string, scheduleId: number) => {
        dispatch(handleLoadEditSchedulePaginatedReportIds(accessToken, scheduleId));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onClearEditSchedulePaginatedReportIds: () => {
        dispatch(clearEditSchedulePaginatedReportIds());
    },
    onHandleSaveEditSchedulePaginatedReportId: (
        accessToken: string,
        scheduleId: number,
        paginatedReportId: number
    ) => {
        dispatch(handleSaveEditSchedulePaginatedReportId(accessToken, scheduleId, paginatedReportId));
    },
    onHandleDeleteEditSchedulePaginatedReportId: (
        accessToken: string,
        scheduleId: number,
        paginatedReportId: number
    ) => {
        dispatch(handleDeleteEditSchedulePaginatedReportId(accessToken, scheduleId, paginatedReportId));
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignSchedulePaginatedReports);

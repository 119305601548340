import * as Redux from "redux";
import getRolePaginatedReports from "../api/getRolePaginatedReports";
import removeRolePaginatedReport from "../api/removeRolePaginatedReport";
import saveRolePaginatedReport from "../api/saveRolePaginatedReport";

import { setEditRolePaginatedReportsLoaded } from "./loadingEditRolePaginatedReports";

export const ADD_EDIT_ROLE_PAGINATED_REPORT_ID = "ADD_EDIT_ROLE_PAGINATED_REPORT_ID";
export const REMOVE_EDIT_ROLE_PAGINATED_REPORT_ID = "REMOVE_EDIT_ROLE_PAGINATED_REPORT_ID";
export const LOAD_EDIT_ROLE_PAGINATED_REPORT_IDS = "LOAD_EDIT_ROLE_PAGINATED_REPORT_IDS";
export const CLEAR_EDIT_ROLE_PAGINATED_REPORT_IDS = "CLEAR_EDIT_ROLE_PAGINATED_REPORT_IDS";

export const addEditRolePaginatedReportId = (id: number) => {
    return {
        id,
        type: ADD_EDIT_ROLE_PAGINATED_REPORT_ID
    };
};

export const removeEditRolePaginatedReportId = (id: number) => {
    return {
        id,
        type: REMOVE_EDIT_ROLE_PAGINATED_REPORT_ID
    };
};

export const loadEditRolePaginatedReportIds = (ids: number[]) => {
    return {
        ids,
        type: LOAD_EDIT_ROLE_PAGINATED_REPORT_IDS
    };
};

export const clearEditRolePaginatedReportIds = () => {
    return {
        type: CLEAR_EDIT_ROLE_PAGINATED_REPORT_IDS
    };
};

export const handleLoadEditRolePaginatedReportIds = (
    accessToken: string,
    roleId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const rolePaginatedReports = await getRolePaginatedReports(accessToken, roleId);
        if (rolePaginatedReports === undefined) {
            dispatch(clearEditRolePaginatedReportIds());
            dispatch(setEditRolePaginatedReportsLoaded(false));
        } else {
            if (rolePaginatedReports.length > 0) {
                dispatch(
                    loadEditRolePaginatedReportIds(
                        rolePaginatedReports.map(paginatedReport => paginatedReport.id)
                    )
                );
            }
            dispatch(setEditRolePaginatedReportsLoaded(true));
        }
    };
};

export const handleSaveEditRolePaginatedReportId = (
    accessToken: string,
    roleId: number,
    paginatedReportId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        await saveRolePaginatedReport(accessToken, roleId, paginatedReportId);
        dispatch(addEditRolePaginatedReportId(paginatedReportId));
    };
};

export const handleDeleteEditRolePaginatedReportId = (
    accessToken: string,
    roleId: number,
    paginatedReportId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        await removeRolePaginatedReport(accessToken, roleId, paginatedReportId);
        dispatch(removeEditRolePaginatedReportId(paginatedReportId));
    };
};

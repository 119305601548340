import { AnyAction } from "redux";
import {
 /*   ADD_SCHEDULE_FREQUENCY,*/
    LOAD_SCHEDULE_FREQUENCYS,
    //REMOVE_SCHEDULE_FREQUENCY,
    //UPDATE_SCHEDULE_FREQUENCY
} from "../actions/scheduleFrequencies";
import IScheduleFrequency from "../models/IScheduleFrequency";

export const scheduleFrequencies = (
    state = [] as IScheduleFrequency[],
    action: AnyAction
) => {
    switch (action.type) {
        //case ADD_SCHEDULE_FREQUENCY:
        //    const newState: IScheduleFrequency[] = state.slice();
        //    newState.push(action.scheduleFrequency as IScheduleFrequency);
        //    return newState;
        //case REMOVE_SCHEDULE_FREQUENCY:
        //    return Object.assign(
        //        {},
        //        state.filter((cc: IScheduleFrequency) => cc.id !== (action.id as number))
        //    );
        //case UPDATE_SCHEDULE_FREQUENCY:
        //    return state.map((cc: IScheduleFrequency) => {
        //        if (cc.id === (action.scheduleFrequency as IScheduleFrequency).id) {
        //            return action.scheduleFrequency as IScheduleFrequency;
        //        }
        //        return cc;
        //    });

        case LOAD_SCHEDULE_FREQUENCYS:
            return action.scheduleFrequencies as IScheduleFrequency[];
        default:
            return state;
    }
};

export default scheduleFrequencies;

import { AnyAction } from "redux";
import { SET_USER_FAVORITES_LOADED } from "../actions/loadingUserFavorites";

export const loadingUserFavorites = (state = false, action: AnyAction) => {
  switch (action.type) {
      case SET_USER_FAVORITES_LOADED:
      return action.userFavoritesLoaded;
    default:
      return state;
  }
};

export default loadingUserFavorites;

import * as React from "react";
import { Progress } from "reactstrap";
import { roleWizardStages } from "../../constants";

export interface IRoleWizardProgressProps {
  currentStage: number;
}
const RoleWizardProgress: React.SFC<IRoleWizardProgressProps> = (
  props: IRoleWizardProgressProps
) => {
  const { currentStage } = props;
  return (
    <Progress multi={true}>
      {currentStage >= roleWizardStages.addInitialRoleDetails && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.addInitialRoleDetails}
          value="15"
        >
          {currentStage === roleWizardStages.addInitialRoleDetails && (
            <span>Initial Role Details</span>
          )}
        </Progress>
      )}
      {currentStage >= roleWizardStages.addPredicateParameters && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.addPredicateParameters}
          value="15"
        >
          {currentStage === roleWizardStages.addPredicateParameters && (
            <span>Add Predicate Values</span>
          )}
        </Progress>
      )}
      {currentStage >= roleWizardStages.assignDashboards && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.assignDashboards}
          value="15"
        >
          {currentStage === roleWizardStages.assignDashboards && (
            <span>Assign Dashboards</span>
          )}
        </Progress>
      )}
      {currentStage >= roleWizardStages.assignPaginatedReports && (
        <Progress
                  color="success"
                  bar={true}
                  animated={currentStage === roleWizardStages.assignPaginatedReports}
                  value="15"
              >
                  {currentStage === roleWizardStages.assignPaginatedReports && (
                      <span>Assign Reports</span>
                  )}
              </Progress>
          )}
      {currentStage >= roleWizardStages.assignReports && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.assignReports}
          value="15"
        >
          {currentStage === roleWizardStages.assignReports && <span>Assign Reports</span>}
        </Progress>
      )}
      {currentStage >= roleWizardStages.assignUsers && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.assignUsers}
          value="15"
        >
          {currentStage === roleWizardStages.assignUsers && <span>Assign Users</span>}
        </Progress>
      )}
      {currentStage >= roleWizardStages.reviewAndComplete && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === roleWizardStages.reviewAndComplete}
          value="25"
        >
          {currentStage === roleWizardStages.reviewAndComplete && (
            <span>Review and Complete</span>
          )}
        </Progress>
      )}
    </Progress>
  );
};

export default RoleWizardProgress;

import IPbiBookmark from "../models/IPbiBookmark";
import { fetchSecureData } from "../utils";

export const saveBookmark = async (
  accessToken: string,
  bookmark:IPbiBookmark
) => {
  try {
    const data = await fetchSecureData(
      "PUT",
        `/api/PbiBookmark/Add`,
        accessToken,
        bookmark
      );

    const bkmrk = ((await data.json()) as any).value as IPbiBookmark;
      return bkmrk;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/PbiBookmark/Add?name=${bookmark.name}&capturedBookmark=${bookmark.capturedBookmark}&userProfileId=${bookmark.userProfileId}&roleId=${bookmark.roleId}&pbiDashboardId=${bookmark.pbiDashboardId}&isDefault=${bookmark.isDefault}`,
      err
    );
  }
  return {} as IPbiBookmark;
};

export default saveBookmark;

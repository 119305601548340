import { AnyAction } from "redux";
import {
  //ADD_SCHEDULE_EVENT,
  LOAD_SCHEDULE_EVENTS,
  //REMOVE_SCHEDULE_EVENT,
  //UPDATE_SCHEDULE_EVENT
} from "../actions/scheduleEvents";
import IScheduleEvent from "../models/IScheduleEvent";

export const scheduleEvents = (
  state = [] as IScheduleEvent[],
  action: AnyAction
) => {
  switch (action.type) {
    //case ADD_SCHEDULE_EVENT:
    //  const newState: IScheduleEvent[] = state.slice();
    //  newState.push(action.scheduleEvent as IScheduleEvent);
    //  return newState;
    //case REMOVE_SCHEDULE_EVENT:
    //  return Object.assign(
    //    {},
    //    state.filter((cc: IScheduleEvent) => cc.id !== (action.id as number))
    //  );
    //case UPDATE_SCHEDULE_EVENT:
    //  return state.map((cc: IScheduleEvent) => {
    //    if (cc.id === (action.scheduleEvent as IScheduleEvent).id) {
    //      return action.scheduleEvent as IScheduleEvent;
    //    }
    //    return cc;
    //  });
    case LOAD_SCHEDULE_EVENTS:
      return action.scheduleEvents as IScheduleEvent[];
    default:
      return state;
  }
};

export default scheduleEvents;

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlProdEuProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlProdEu extends React.Component<ICallbackSamlProdEuProps> {
  public constructor(props: ICallbackSamlProdEuProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

      auth.loginSAMLProdEu().then(() => {
          console.log("Login via SAML Prod Europe");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlProdEu);

import { fetchSecureData } from "../utils";

export const removeRolePaginatedReport = async (accessToken: string, roleId: number, paginatedReportId: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
        `/api/RolePaginatedReport/Remove?roleId=${roleId}&paginatedReportId=${paginatedReportId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
      console.log(`Fetch error for /api/RolePaginatedReport/Remove?roleId=${roleId}&reportId=${paginatedReportId}`, err);
  }
  return 404;
};

export default removeRolePaginatedReport;

import * as React from "react";
import { Badge, Label } from "reactstrap";
import IUserProfile from "../../models/IUserProfile";

export interface IUsersBadgeProps {
  userIds: number[];
  users: IUserProfile[];
}

const UsersBadge = (props: IUsersBadgeProps) => {
  const { userIds, users } = props;
  return (
    <React.Fragment>
          <Label for="grpSelectedUsers" style={{ fontSize: '1.5em', color: '#028cca', margin: 'margin: 15px 0px 10px 0px', width: '100%'} }>Selected Users:</Label>
      <div id="grpSelectedUsers">
        {users.length > 0 &&
          userIds.map(id => (
              <Badge key={id} className="badge">
              {
                (users.find(user => user.id === id) as IUserProfile)
                  .emailAddress
              }
            </Badge>
          ))}
      </div>
      {userIds.length === 0 && <div>None</div>}
    </React.Fragment>
  );
};

export default UsersBadge;

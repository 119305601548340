import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  ListGroup,
  ListGroupItem,
    ListGroupItemHeading,
} from "reactstrap";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IStore } from "../../models/IStore";
import paths from "../../constants";
import Dashboard from "../../assets/icons/dashboard.svg";
import Icon from "./Icon";
import IUserFavorite from "../../models/IUserFavorite";
import FavoritesIcon from "../molecules/FavoritesIcon";


export interface IUserDashboardsCardProps {
    dashboards: IPbiDashboard[];
    userFavoritesDashboards: IUserFavorite[];
}



const UserDashboardsCard: React.SFC<IUserDashboardsCardProps> = (
  props: IUserDashboardsCardProps
) => {
    const { dashboards, userFavoritesDashboards } = props;
 

    const filteresDashboards = dashboards.filter(el => {
        return userFavoritesDashboards.some(f => {
            return f.reportId === el.id ;
        });
    }); 

  return (
    <React.Fragment>      
        <ListGroup>
          <ListGroupItemHeading style={{ backgroundColor: "white" }}>
                  <div className="textH2"> <Icon icon={Dashboard} text="Dashboard" id="dashboard" /> Your Favorite Dashboards</div>
          </ListGroupItemHeading>
              {filteresDashboards.map(dashboard => (

            <ListGroupItem action={true} variant="Light" key={dashboard.id}>
              <Link to={paths.pbiDashboards.concat(dashboard.id.toString())}>
                          <FavoritesIcon/> {dashboard.displayName}
              </Link>
            </ListGroupItem>
          ))}
          {(dashboards===undefined || (dashboards!==undefined && dashboards.length===0) )&&(<span>You have no available dashboards</span>)}
        </ListGroup>   
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
      dashboards: state.userDashboards,
      userFavoritesDashboards: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "DASHBOARD") : state.userFavorites
  };
};

export default connect(mapStateToProps)(UserDashboardsCard);

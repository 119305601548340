import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
    Label
} from "reactstrap";
import {
    handleDeletePaginatedReport,
    handleLoadPaginatedReports,
    handleUpdatePaginatedReport,
    handleCreatePaginatedReportParameters
} from "../../../actions/paginatedReports";
import { handleLoadPaginatedReportParameters } from "../../../actions/paginatedReportParameters";
import AddTextField from "../../../components/molecules/AddTextField";
import PaginatedReportDetailsTable from "../../../components/molecules/PaginatedReportDetailsTable";
import PaginatedReportParameterDetailsTable from "../../../components/molecules/PaginatedReportParameterDetailsTable";
import EditPaginatedReportParametersDetails from "../../../components/templates/admin/EditPaginatedReportParametersDetails";
import { paths, settings } from "../../../constants";
import IPbiPaginatedReport from "../../../models/IPbiPaginatedReport";
import IPaginatedReportParameter from "../../../models/IPaginatedReportParameter";
import { IStore } from "../../../models/IStore";
import {
    validatePaginatedReportDisplayName,
    validatePaginatedReportName,
    validateGuid
} from "../../../utils";
import loadingPaginatedReportParameters from "../../../reducers/loadingPaginatedReportParameters";
import DataLoading from "../../organisms/DataLoading";
import ta from "date-fns/esm/locale/ta/index";




export interface IPaginatedReportPageProps extends RouteComponentProps {
    accessToken: string;
    paginatedReport: IPbiPaginatedReport;
    existingPaginatedReports: IPbiPaginatedReport[];
    //paginatedReportParameters: IPaginatedReportParameter[];

    onHandleLoadPaginatedReports: (accessToken: string) => void;

    onHandleUpdatePaginatedReport: (
        accessToken: string,
        paginatedReport: IPbiPaginatedReport
    ) => void;

    onHandleDeletePaginatedReport: (accessToken: string, id: number) => void;

    onHandleCreatePaginatedReportParameters: (
        accessToken: string,
        paginatedReport: IPbiPaginatedReport
    ) => void;


    onHandleLoadPaginatedReportParameters: (
        accessToken: string,
        reportId: number
    ) => void;

}
export interface IPaginatedReportPageState {
    editModeOn: boolean;
    displayName: string;
    name: string;
    groupId: string;
    reportId: string;
    isEnabled: boolean;
    isForScheduling: boolean;
    showDeleteModal: boolean;
    isReadyToSave: boolean;
    loadingPaginatedReportParameters: boolean;
}

class EditPaginatedReportPage extends React.Component<
    IPaginatedReportPageProps,
    IPaginatedReportPageState
> {
    public isLoaded = false;

    public state = {
        editModeOn: false,
        // tslint:disable-next-line:object-literal-sort-keys
        displayName: "",
        name: "",
        groupId: "",
        reportId: "",
        isEnabled: true,
        isForScheduling: true,
        showDeleteModal: false,
        isReadyToSave: false,
        paginatedReportParameters: [] as IPaginatedReportParameter[],
        loadingPaginatedReportParameters: false

    };


    constructor(props: IPaginatedReportPageProps) {
        super(props);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.delete = this.delete.bind(this);
        this.readyToSave = this.readyToSave.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isEnabledChange = this.isEnabledChange.bind(this);
        this.isForSchedulingChange = this.isForSchedulingChange.bind(this);
        this.createParameters = this.createParameters.bind(this);
        this.getButtonText = this.getButtonText.bind(this);
    }


    public createParameters = (e: React.MouseEvent) => {
        e.preventDefault();
        const {
            accessToken,
            onHandleCreatePaginatedReportParameters,
            paginatedReport,
        } = this.props;


        this.setState(() => ({ loadingPaginatedReportParameters: true }));


        onHandleCreatePaginatedReportParameters(accessToken, paginatedReport);
        // this.props.history.push(paths.editPaginatedReport.concat(paginatedReport.id.toString()));
        //this.props.history.push(paths.searchPaginatedReports);
    };


    public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isEnabled = e.target.checked;
        this.setState(() => ({ isEnabled }));
    };

    public isForSchedulingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isForScheduling = e.target.checked;
        this.setState(() => ({ isForScheduling }));
        this.readyToSave();
    };

    public save = async (e: React.MouseEvent) => {
        e.preventDefault();
        const {
            accessToken,
            onHandleUpdatePaginatedReport,
            history,
            paginatedReport
        } = this.props;
        const {
            name,
            displayName,
            groupId,
            reportId,
            isEnabled,
            isForScheduling,
            isReadyToSave
        } = this.state;
        const updatedPaginatedReport = {
            id: paginatedReport.id,
            name,
            // tslint:disable-next-line:object-literal-sort-keys
            displayName,
            groupId,
            reportId,
            isEnabled,
            isForScheduling
        } as IPbiPaginatedReport;
        if (this.readyToSave() && isReadyToSave) {
           await  onHandleUpdatePaginatedReport(accessToken, updatedPaginatedReport);
            history.push(paths.searchPaginatedReports);
        }
    };

    public delete = (e: React.MouseEvent) => {
        const {
            accessToken,
            paginatedReport,
            history,
            onHandleDeletePaginatedReport
        } = this.props;
        onHandleDeletePaginatedReport(accessToken, paginatedReport.id);
        history.push(paths.searchPaginatedReports);
    };

    public cancel = (e: React.MouseEvent) => {
        const { history } = this.props;
        e.preventDefault();
        history.push(paths.searchPaginatedReports);
    };


    public toggleEdit() {
        this.setState({ editModeOn: !this.state.editModeOn });
    }

    public toggleDeleteModal() {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
    }


    public getButtonText() {
        const { paginatedReport } = this.props;
        if (paginatedReport.createParametersMessage === null || paginatedReport.createParametersMessage === undefined) {
            return "Load Parameters";
        }
        else {
            return "Reload Parameters"; 
        }

    }


    public readyToSave = () => {
        const {
            displayName,
            name,
            groupId,
            reportId
        } = this.state;
        if (
            displayName === undefined ||
            displayName === "" ||
            !validatePaginatedReportDisplayName(displayName)
        ) {
            this.setState(() => ({ isReadyToSave: false }));
            return false;
        }
        if (name === undefined || name === "" || !validatePaginatedReportName(name)) {
            this.setState(() => ({ isReadyToSave: false }));
            return false;
        }
        if (groupId === undefined || groupId === "" || !validateGuid(groupId)) {
            this.setState(() => ({ isReadyToSave: false }));
            return false;
        }
        if (reportId === undefined || reportId === "" || !validateGuid(reportId)) {
            this.setState(() => ({ isReadyToSave: false }));
            return false;
        }
        this.setState(() => ({ isReadyToSave: true }));
        return true;
    };  

    public async componentDidUpdate(prevProps: IPaginatedReportPageProps) {
        const { paginatedReport, accessToken, onHandleLoadPaginatedReportParameters, onHandleLoadPaginatedReports} = this.props;
        if (paginatedReport.lastUpdatedDate !== prevProps. paginatedReport.lastUpdatedDate) {
          
            await onHandleLoadPaginatedReportParameters(accessToken, paginatedReport.id);
            await this.setState(() => ({ loadingPaginatedReportParameters: false }));

            onHandleLoadPaginatedReports(accessToken);
        }
    }

    public async componentDidMount() {
        const {
            accessToken,
            onHandleLoadPaginatedReports,
            onHandleLoadPaginatedReportParameters,
            existingPaginatedReports,
            paginatedReport,
            //paginatedReportParameters
        } = this.props;
        const {
            id,
            displayName,
            name,
            groupId,
            reportId,
            isEnabled,
            isForScheduling
        } = paginatedReport;
        this.setState(() => ({
            displayName,
            name,
            groupId,
            reportId,
            isEnabled,
            isForScheduling
        }));
        this.isLoaded = true;
        if (this.isLoaded) {
            if (existingPaginatedReports.length === 0) {
                onHandleLoadPaginatedReports(accessToken);
            }

            await onHandleLoadPaginatedReportParameters(accessToken, id)

        }


    }

    public render() {
        const { paginatedReport, existingPaginatedReports } = this.props;
        const {
            editModeOn,
            displayName,
            name,
            groupId,
            reportId,
            isEnabled,
            isForScheduling,
            loadingPaginatedReportParameters
            //isReadyToSave
        } = this.state;
        const { allowDisablePaginatedReports } = settings;
        return (
            <Form>
                <Modal
                    isOpen={this.state.showDeleteModal}
                    toggle={this.toggleDeleteModal}
                >
                    <ModalHeader toggle={this.toggleDeleteModal}>
                        Delete PaginatedReport {displayName}
                    </ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete PaginatedReport: {displayName}{" "}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.delete}>
                            Confirm Delete
                        </Button>{" "}
                        <Button color="secondary" onClick={this.toggleDeleteModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Card>
                    <CardTitle><h1>Report Details for {displayName}</h1></CardTitle>
                    <CardBody>
                        {!editModeOn && <PaginatedReportDetailsTable paginatedReport={paginatedReport} />}
                        {editModeOn && (
                            <React.Fragment>
                                <AddTextField
                                    noDuplicates={true}
                                    isValid={validatePaginatedReportDisplayName}
                                    title="Display Name"
                                    value={displayName}
                                    existingValues={existingPaginatedReports.map(d => d.displayName)}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={async (dn: string) => {
                                        await this.setState(() => ({ displayName: dn }));
                                        this.readyToSave();
                                    }}
                                />

                                <AddTextField
                                    noDuplicates={true}
                                    isValid={validatePaginatedReportName}
                                    title="Name"
                                    value={name}
                                    existingValues={existingPaginatedReports.map(d => d.name)}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={async (n: string) => {
                                        await this.setState(() => ({ name: n }));
                                        this.readyToSave();
                                    }}
                                />
                                <AddTextField
                                    noDuplicates={false}
                                    isValid={validateGuid}
                                    title="Group ID "
                                    value={groupId}
                                    existingValues={existingPaginatedReports.map(d => d.groupId)}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={async (gId: string) => {
                                        await this.setState(() => ({ groupId: gId }));
                                        this.readyToSave();
                                    }}
                                />
                                <AddTextField
                                    noDuplicates={true}
                                    isValid={validateGuid}
                                    title="Report ID"
                                    value={reportId}
                                    existingValues={existingPaginatedReports.map(d => d.reportId)}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={async (rId: string) => {
                                        await this.setState(() => ({ reportId: rId }));
                                        this.readyToSave();
                                    }}
                                />

                                <div>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Label check>
                                                        <Input type="checkbox"
                                                            defaultChecked={isForScheduling}
                                                            onChange={this.isForSchedulingChange}
                                                        />{''}
                                                        Available for Scheduling
                                                    </Label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                               
                                    {isForScheduling && !loadingPaginatedReportParameters &&(
                                        <Table striped={true}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            color="danger"
                                                            onClick={this.createParameters}
                                                            style={{
                                                                margin: 4,
                                                                padding: 4
                                                            }}
                                                        >
                                                            <strong>{this.getButtonText()}</strong>
                                                        </Button>
                                                    </td>
                                               
                                                    <td>
                                                        <Label>Parameters Count:</Label><span>{paginatedReport.schedulingParametersCount}</span>

                                                    </td>
                                               
                                                    <td>  <Label>Create Report Parameters Message: </Label>
                                                        {paginatedReport.createParametersMessage !== null && paginatedReport.createParametersMessage !== '' && paginatedReport.createParametersMessage.includes('Error') ? <p className='text-danger' >{paginatedReport.createParametersMessage} </p> : <p className='text-success'>{paginatedReport.createParametersMessage} </p>}

                                                    </td>
                                                </tr>
                                            
                                            </tbody>
                                        </Table>)}
                                </div>

                             

                                {loadingPaginatedReportParameters && <DataLoading dataTitle="Creating Parameters" />}


                                {allowDisablePaginatedReports && (
                                    <Table>
                                        <tr>
                                            <th scope="row">Enabled</th>
                                            <td>
                                                <Input
                                                    type="checkbox"
                                                    defaultChecked={isEnabled}
                                                    onChange={this.isEnabledChange}
                                                />
                                                {isEnabled}
                                            </td>
                                        </tr>
                                    </Table>
                                )}


                            </React.Fragment>
                        )}
                        {!editModeOn && isForScheduling && <PaginatedReportParameterDetailsTable />}
                        {editModeOn && !loadingPaginatedReportParameters&&isForScheduling && <EditPaginatedReportParametersDetails />}
                    </CardBody>

                    <CardFooter>
                        {!editModeOn && (
                            <React.Fragment>
                                <Button
                                    onClick={this.toggleEdit}
                                    style={{ margin: 4, padding: 4 }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={this.toggleDeleteModal}
                                    style={{
                                        margin: 4,
                                        padding: 4
                                    }}
                                >
                                    <strong>Delete PaginatedReport</strong>
                                </Button>
                            </React.Fragment>
                        )}
                        {editModeOn && (
                            <React.Fragment>
                                {
                                    //isReadyToSave &&
                                    (
                                        <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                                            Save Changes
                                        </Button>
                                    )}
                                <Button
                                    onClick={this.toggleEdit}
                                    style={{ margin: 4, padding: 4 }}
                                >
                                    Cancel
                                </Button>
                            </React.Fragment>
                        )}
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
    const { match } = ownProps;
    let paginatedReport: IPbiPaginatedReport = {} as IPbiPaginatedReport;
    const existingPaginatedReports = state.paginatedReports.filter(
        dsh => dsh.id !== parseInt((match.params as any).id as string, 10)
    ) as IPbiPaginatedReport[];
    if (match !== null) {
        paginatedReport = state.paginatedReports.find(dsh => {
            return dsh.id === parseInt((match.params as any).id as string, 10);
        }) as IPbiPaginatedReport;
    }
    return {
        accessToken: state.auth0.accessToken,
        paginatedReport,
        existingPaginatedReports
    };
};

const mapDispatchToProps = (dispatch: any) => ({

    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },
    onHandleUpdatePaginatedReport: (accessToken: string, paginatedReport: IPbiPaginatedReport) => {
        dispatch(handleUpdatePaginatedReport(accessToken, paginatedReport));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleDeletePaginatedReport: (accessToken: string, id: number) => {
        dispatch(handleDeletePaginatedReport(accessToken, id));
    },
    onHandleCreatePaginatedReportParameters: (accessToken: string, paginatedReport: IPbiPaginatedReport) => {
        dispatch(handleCreatePaginatedReportParameters(accessToken, paginatedReport));
    },
    onHandleLoadPaginatedReportParameters: (accessToken: string, id: number) => {
        dispatch(handleLoadPaginatedReportParameters(accessToken, id));
    },

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPaginatedReportPage);

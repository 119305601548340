import * as React from "react";
import { connect } from "react-redux";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import { handleLoadPaginatedReports } from "../../../actions/paginatedReports";
import {
    clearEditRolePaginatedReportIds,
    handleDeleteEditRolePaginatedReportId,
    handleLoadEditRolePaginatedReportIds,
    handleSaveEditRolePaginatedReportId
} from "../../../actions/editRolePaginatedReportIds";


import PaginatedReportsBadge from "../../../components/molecules/PaginatedReportsBadge";
import DataLoading from "../../../components/organisms/DataLoading";
import IPbiPaginatedReport from "../../../models/IPbiPaginatedReport";
import { IStore } from "../../../models/IStore";

export interface IAssignRolePaginatedReportsState {
    editRolePaginatedReportIds: number[];
    filteredPaginatedReports: IPbiPaginatedReport[];
}

export interface IAssignRolePaginatedReportsProps {
    accessToken: string;
    paginatedReports: IPbiPaginatedReport[];
    paginatedReportsLoaded: boolean;
    editRolePaginatedReportIds: number[];
    editRolePaginatedReportIdsLoaded: boolean;
    roleId: number;
    onHandleLoadPaginatedReports: (accessToken: string) => void;

    onHandleLoadEditRolePaginatedReportIds: (
        accessToken: string,
        roleId: number
    ) => void;

    onClearEditRolePaginatedReportIds: () => void;
    onHandleSaveEditRolePaginatedReportId: (
        accessToken: string,
        roleId: number,
        paginatedReportId: number
    ) => void;
    onHandleDeleteEditRolePaginatedReportId: (
        accessToken: string,
        roleId: number,
        paginatedReportId: number
    ) => void;
}

class AssignRolePaginatedReports extends React.Component<
    IAssignRolePaginatedReportsProps,
    IAssignRolePaginatedReportsState
    > {
    public state = {
        editRolePaginatedReportIds: [] as number[],
        filteredPaginatedReports: [] as IPbiPaginatedReport[]
    };
    constructor(props: IAssignRolePaginatedReportsProps) {
        super(props);
        this.paginatedReportCheckboxChange = this.paginatedReportCheckboxChange.bind(this);
        this.txtFilterChange = this.txtFilterChange.bind(this);
    }
    public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { paginatedReports } = this.props;
        const filteredPaginatedReports = paginatedReports.filter(paginatedReport =>
            paginatedReport.displayName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        await this.setState(() => ({ filteredPaginatedReports }));
    };
    public paginatedReportCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const {
            accessToken,
            roleId,
            onHandleSaveEditRolePaginatedReportId,
            onHandleDeleteEditRolePaginatedReportId
        } = this.props;
        e.preventDefault();
        if (e.target.checked) {
            await onHandleSaveEditRolePaginatedReportId(
                accessToken,
                roleId,
                Number(e.target.value)
            );
        } else {
            await onHandleDeleteEditRolePaginatedReportId(
                accessToken,
                roleId,
                Number(e.target.value)
            );
        }
    };
    public async componentDidMount() {
        const {
            accessToken,
            paginatedReports,
            paginatedReportsLoaded,
            editRolePaginatedReportIds,
            onHandleLoadPaginatedReports,
            onHandleLoadEditRolePaginatedReportIds,
            roleId
        } = this.props;
        if (!paginatedReportsLoaded) {
            await onHandleLoadPaginatedReports(accessToken);
        }
        await onHandleLoadEditRolePaginatedReportIds(accessToken, roleId);
        this.setState({ editRolePaginatedReportIds });
        this.setState({ filteredPaginatedReports: paginatedReports });
    }

    public async componentDidUpdate(prevProps: IAssignRolePaginatedReportsProps) {
        const { editRolePaginatedReportIds, paginatedReports } = this.props;
        if (prevProps.paginatedReports !== paginatedReports) {
            await this.setState(() => ({ filteredPaginatedReports: paginatedReports }));
        }
        if (prevProps.editRolePaginatedReportIds !== editRolePaginatedReportIds) {
            await this.setState(() => ({ editRolePaginatedReportIds }));
        }
    }

    public render() {
        const { filteredPaginatedReports, editRolePaginatedReportIds } = this.state;
        const {
            paginatedReports,
            paginatedReportsLoaded,
            editRolePaginatedReportIdsLoaded
        } = this.props;
        return (
            <React.Fragment>
                {!paginatedReportsLoaded && <DataLoading dataTitle="Reports " />}
                {!editRolePaginatedReportIdsLoaded && (
                    <DataLoading dataTitle="Role Reports " />
                )}
                {paginatedReportsLoaded && editRolePaginatedReportIdsLoaded && (
                    <Container>
                        <Row>
                            <Col>
                                <h2>Assign Reports</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {paginatedReportsLoaded && (
                                    <PaginatedReportsBadge
                                        paginatedReports={paginatedReports}
                                        paginatedReportIds={editRolePaginatedReportIds}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label for="txtFilter">Search: </Label>
                                <Input
                                    type="text"
                                    id="txtFilter"
                                    onChange={this.txtFilterChange}
                                    placeholder="Search for..."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup>
                                    {filteredPaginatedReports.map(paginatedReport => (
                                        <ListGroupItem key={paginatedReport.id}>
                                            <FormGroup check={true}>
                                                <Label check={true}>
                                                    <Input
                                                        type="checkbox"
                                                        value={paginatedReport.id}
                                                        onChange={this.paginatedReportCheckboxChange}
                                                        checked={
                                                            this.state.editRolePaginatedReportIds.find(
                                                                id => id === paginatedReport.id
                                                            ) !== undefined
                                                        }
                                                    />
                                                    {paginatedReport.displayName}
                                                </Label>
                                            </FormGroup>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    const { onSave } = ownProps;
    return {
        accessToken: state.auth0.accessToken,
        paginatedReports: state.paginatedReports,
        paginatedReportsLoaded: state.loadingPaginatedReports,
        roleId: state.editRoleDetails.id,
        // tslint:disable-next-line:object-literal-sort-keys
        editRolePaginatedReportIds: state.editRolePaginatedReportIds,
        editRolePaginatedReportIdsLoaded: state.loadingEditRolePaginatedReports,
        onSave
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },
    onHandleLoadEditRolePaginatedReportIds: (accessToken: string, roleId: number) => {
        dispatch(handleLoadEditRolePaginatedReportIds(accessToken, roleId));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onClearEditRolePaginatedReportIds: () => {
        dispatch(clearEditRolePaginatedReportIds());
    },
    onHandleSaveEditRolePaginatedReportId: (
        accessToken: string,
        roleId: number,
        paginatedReportId: number
    ) => {
        dispatch(handleSaveEditRolePaginatedReportId(accessToken, roleId, paginatedReportId));
    },
    onHandleDeleteEditRolePaginatedReportId: (
        accessToken: string,
        roleId: number,
        paginatedReportId: number
    ) => {
        dispatch(handleDeleteEditRolePaginatedReportId(accessToken, roleId, paginatedReportId));
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignRolePaginatedReports);

import * as Redux from "redux";
import getScheduleReportFormats from "../api/getScheduleReportFormats";
import saveUpdatedScheduleReportFormat from "../api/saveUpdatedScheduleReportFormat";
import IScheduleReportFormat from "../models/IScheduleReportFormat";
import { setEditScheduleReportFormatsLoaded } from "./loadingEditScheduleReportFormats";


export const UPDATE_EDIT_SCHEDULE_REPORT_FORMAT = "UPDATE_EDIT_SCHEDULE_REPORT_FORMAT";
export const LOAD_EDIT_SCHEDULE_REPORT_FORMATS = "LOAD_EDIT_SCHEDULE_REPORT_FORMATS";

 

export const updateEditScheduleReportFormat = (scheduleReportFormat: IScheduleReportFormat) => {
    return {
        scheduleReportFormat,
        type: UPDATE_EDIT_SCHEDULE_REPORT_FORMAT
    };
};
 

export const loadEditScheduleReportFormats = (scheduleReportFormats: IScheduleReportFormat[]) => {
    return {
        scheduleReportFormats,
        type: LOAD_EDIT_SCHEDULE_REPORT_FORMATS
    };
};
 
export const handleLoadEditScheduleReportFormats = (
    accessToken: string,
    scheduleId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const scheduleReportFormats = await getScheduleReportFormats(accessToken, scheduleId);
        if (scheduleReportFormats === undefined) {
            // tslint:disable-next-line:no-console
            // console.log(
            //   "No Role Predicates found, or user does not have access to edit these role predicates"
            // );
          
            dispatch(setEditScheduleReportFormatsLoaded(false));
        } else {
            if (scheduleReportFormats.length > 0) {
                dispatch(loadEditScheduleReportFormats(scheduleReportFormats));
            }
            dispatch(setEditScheduleReportFormatsLoaded(true));
        }
    };
};
 

export const handleUpdateScheduleReportFormat = (
    accessToken: string,
    id: string,
    formatName: string,
    formatValue: boolean 
) => {
    return async (dispatch: Redux.Dispatch) => {
        dispatch(setEditScheduleReportFormatsLoaded(false));
        const scheduleReportFormat  =   await saveUpdatedScheduleReportFormat(accessToken, id, formatName, formatValue );
        dispatch(updateEditScheduleReportFormat(scheduleReportFormat));
        dispatch(setEditScheduleReportFormatsLoaded(true));
    };
};
 
import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";
import IScheduleRoleUserIds from "../models/IScheduleRoleUserIds";



export const getScheduleUsers = async (accessToken: string, scheduleId: number) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/UserProfile/GetUserProfilesForScheduleId?scheduleId=${scheduleId}`,
      accessToken
    );
      const scheduleUsers = ((await data.json()) as any).value as IScheduleRoleUserIds[];
    if (scheduleUsers !== undefined) {
      return scheduleUsers;
    } else {
        return [] as IScheduleRoleUserIds[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/UserProfile/GetUserProfilesForScheduleId?scheduleId=${scheduleId}`,
      err
    );
  }
    return [] as IScheduleRoleUserIds[];
};

export default getScheduleUsers;

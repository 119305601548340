import * as Redux from "redux";
import getUserSchedules from "../api/getUserSchedules";
import IUserSchedule from "../models/IUserSchedule";
import { setUserSchedulesLoaded } from "./loadingUserSchedules";

export const ADD_USER_SCHEDULE = "ADD_USER_SCHEDULE";
export const REMOVE_USER_SCHEDULE = "REMOVE_USER_SCHEDULE";
export const LOAD_USER_SCHEDULES = "LOAD_USER_SCHEDULES";

 

export const loadUserSchedules = (userSchedules: IUserSchedule[]) => {
  return {
    userSchedules,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_USER_SCHEDULES
  };
};   

export const handleLoadUserSchedules = (
  accessToken: string,
    userId: number,
    jobId: number,
    scheduleId:number
) => {
  return async (dispatch: Redux.Dispatch) => {
      const schedules = await getUserSchedules(accessToken, userId, jobId, scheduleId);
    if (schedules !== undefined) {
      if (schedules.length > 0) {
        schedules.sort((a, b) =>
            a.scheduleName.toLowerCase() > b.scheduleName.toLowerCase() ? 1 : -1
        );
        dispatch(loadUserSchedules(schedules));         
      }
      dispatch(setUserSchedulesLoaded(true));
    }
  };
};

import { AnyAction } from "redux";
import { SET_USER_ROLES_WITH_USER_PROFILES_LOADED } from "../actions/loadingUserRolesWithUserProfiles";

export const loadingUserRolesWithUserProfiles = (state = false, action: AnyAction) => {
  switch (action.type) {
      case SET_USER_ROLES_WITH_USER_PROFILES_LOADED:
      return action.userRolesWithUserProfilesLoaded;
    default:
      return state;
  }
};

export default loadingUserRolesWithUserProfiles;

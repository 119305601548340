export const paths = {
    addDashboard: "/admin/dashboards/add/",
    addPaginatedReport: "/admin/paginatedReports/add/",
    addGroup: "/admin/groups/add/",
    addKpi: "/admin/kpis/add/",
    addPredicate: "/admin/predicates/add/",
    addReport: "/admin/reports/add/",
    addRole: "/admin/roles/add/",
    addRoleWizard: "/admin/roles/addWizard/",
    addUser: "/admin/users/add/",
    admin: "/admin/",
    callback: "/callback/",
    editDashboard: "/admin/dashboards/edit/",
    editPaginatedReport: "/admin/paginatedReports/edit/",
    editGroup: "/admin/groups/edit/",
    editKpi: "/admin/kpis/edit/",
    editPredicate: "/admin/predicates/edit/",
    editReport: "/admin/reports/edit/",
    editRole: "/admin/roles/edit/",
    editUser: "/admin/users/edit/",
    home: "/home/",
    pbiDashboards: "/dashboards/",
    pbiPaginatedReports: "/paginatedReports/",
    profile: "/profile/",
    quickAddUser: "/admin/users/quick-add",
    root: "/",
    searchDashboards: "/admin/dashboards/search/",
    searchPaginatedReports: "/admin/paginatedReports/search/",
    searchGroups: "/admin/groups/search/",
    searchKpis: "/admin/kpis/search/",
    searchPredicates: "/admin/predicates/search",
    searchReports: "/admin/reports/search",
    searchRoles: "/admin/roles/search/",
    searchUsers: "/admin/users/search/",
    searchSchedules: "/schedules/search/",

    /*
    ssosaml: "/ssosaml/",
    ssosamldevau: "/ssosamldevau",
    ssosamlqaasia: "/ssosamlqaasia",
    ssosamlqaau: "/ssosamlqaau",
    ssosamlqa: "/ssosamlqa",
    ssosamlqana: "/ssosamlqana",
    ssosamlprodasia: "/ssosamlprodasia",
    ssosamlprodau: "/ssosamlprodau",
    ssosamlprodna: "/ssosamlprodna",
    ssosamlstagingau: "/ssosamlstagingau",
    */

    ssosamlprodna: "/ssosamlprodna",
    ssosamlqana: "/ssosamlqana",

    ssosamlprodeu: "/ssosamlprodeu",
    ssosamlqaeu: "/ssosamlqaeu",

    ssosamlprodau: "/ssosamlprodau",
    ssosamlqaau: "/ssosamlqaau",

    ssosamlprodas: "/ssosamlprodas",
    ssosamlqaas: "/ssosamlqaas",


    ssosamlanzprodau: "/ssosamlanzprodau",

     
    sqlReports: "/reports/",
    // tslint:disable-next-line:object-literal-sort-keys
    kpitargets: "/kpi/",
    addKpiTargets: "/admin/kpis/add/",
    addFinancialYear: "/admin/kpis/financialYears/add/",

    listUserDashboards: "/user/dashboards/list/",
    listUserPaginatedReports: "/user/paginatedReports/list/",
    listUserReports: "/user/reports/list/",
    listUserUnitedReports: "/user/unitedReports/list/",
    listSchedules: "/user/schedules/list/",
    addSchedule: "/schedule/add/",
    editSchedule: "/schedule/edit/",
    addScheduleWizard: "/schedules/addScheduleWizard/",
    emailReports: "/emailreports/",
    callbackEmailReports: "/email/",

    sharedDashboard: "/sharedDashboard/",
};

export const settings = {
    allowAlternateSqlReportingServerUrl: false,
    allowAlternateSqlReportingViewerUrl: false,
    allowDisableDashboards: false,
    allowDisablePaginatedReports: false,
    allowDisableGroups: false,
    allowDisableReports: false,
    allowDisableRoles: false,
    allowDisableUsers: true,
    // tslint:disable-next-line:object-literal-sort-keys
    allowDisableFinancialYears: false,
    allowDisablePredicates: false,
    allowDisableKpis: false,
    maxDashboardViewerVersion: 2,
    kpiDashboardPageName: "KPIs",
    simulationDashboardPageName: "Simulation",
    allowDisableSchedules: true
};

export const zoomModes = [
    "Whole Page",
    "Page Width",
    "Percent",
    "500%",
    "200%",
    "150%",
    "100%",
    "75%",
    "50%",
    "25%",
    "10%"
];

export const editModes = {
    add: 0,
    view: 1,
    // tslint:disable-next-line:object-literal-sort-keys
    edit: 2
};
export const roleWizardStages = {
    addInitialRoleDetails: 0,
    addPredicateParameters: 1,
    assignDashboards: 2,
    assignPaginatedReports: 3,
    assignReports: 4,
    assignUsers: 5,
    reviewAndComplete: 6

};

export const userWizardStages = {
    addInitialUserDetails: 0,
    assignRoles: 1,
    // tslint:disable-next-line:object-literal-sort-keys
    assignGroups: 2,
    reviewAndComplete: 3
};



export const scheduleWizardStages = {
    addInitialScheduleDetails: 0,
    addFrequency: 1,
    assignRecipients: 2,
    assignReports: 3,
    editParameters: 4,        
    addReportFormats: 5,    
    reviewAndComplete: 6
};

export const adminGroupName = "Company Administrator";
export const kpiAdminGroupName = "Client KPI Administrator";
export const superAdminGroupName = "Super Administrator";
export const scheduleAdminGroupName = "Subscription Manager";

export default paths;


import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Spinner } from "reactstrap";

import '../../assets/styles/Report.css';

import {
    clearActivePaginatedReport,
    setActivePaginatedReport
} from "../../actions/activePaginatedReport";
import { handleRefreshToken } from "../../actions/tokenData";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { IPbiPaginatedReportData } from "../../models/IPbiPaginatedReportData";
import { IPbiTokenData } from "../../models/IPbiTokenData";
import IRole from "../../models/IRole";
import IUserProfile from "../../models/IUserProfile";

import { IStore } from "../../models/IStore";
import { ITokenData } from "../../models/ITokenData";
import Jumbotron from "../atoms/Jumbotron";
import ActiveRoleBadge from "../molecules/ActiveRoleBadge";
import ResetSizeToWindowButton from "../molecules/ResetSizeToWindowButton";

export interface IPaginatedReportViewProps {
    apiAccessToken: string;
    paginatedReport: IPbiPaginatedReport;
    token: ITokenData;
    activeUserRole: IRole;
    onSetActivePaginatedReport: (id: number) => void;
    onClearActivePaginatedReport: () => void;
    onHandleRefreshToken: (accessToken: string, refreshToken: string) => void;
    roleCount: number;
    userProfile: IUserProfile;

}

export interface IPaginatedReportViewState {
    embedUrl: string;
    fetched: boolean;
    fetching: boolean;
    fitToWindowVisible: boolean;
    height: string;
    intervalId: number;
    pbiToken: IPbiTokenData;
    width: string;

}

export class PaginatedReportView extends React.Component<
    IPaginatedReportViewProps,
    IPaginatedReportViewState
> {
    public state = {
        embedUrl: "",
        fetched: false,
        fetching: true,
        fitToWindowVisible: false,
        height: "",
        intervalId: 0,
        pbiToken: {
            expiration: "",
            token: "",
            tokenId: ""
        },
        width: ""
    };

    public report: any;
    constructor(props: IPaginatedReportViewProps) {
        super(props);
        this.getPbiReportAccessToken = this.getPbiReportAccessToken.bind(this);
        this.tokenRefresh = this.tokenRefresh.bind(this);
        this.handleDataSelected = this.handleDataSelected.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.setFullscreen = this.setFullscreen.bind(this);

        this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc...
    }

    public handleDataSelected = (data: any) => {
        // will be called when some chart or data element in your report clicked
    };


    public handlePageChange = (data: any) => {
        // will be called when pages in your report changes
    };

    public setFullscreen = () => {
        if (this.report) {
            this.setState({
                fitToWindowVisible: true,
                width: "96.5vw",
                // tslint:disable-next-line:object-literal-sort-keys
                height: "100vh"
            });
            this.report.fullscreen();
        }
    };


    public async getPbiReportAccessToken(accessToken: string) {
        const { paginatedReport, apiAccessToken, userProfile } = this.props;
        const { groupId, reportId } = paginatedReport;

        const paginatedReportInfo = (await this.getReportInGroup(
            accessToken
        )) as IPbiPaginatedReportData;
        console.log('*******');
        console.log(paginatedReportInfo);
        await this.setState({ embedUrl: paginatedReportInfo.embedUrl });
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${apiAccessToken}`);
        headers.append("AccessToken", accessToken);
        const init: RequestInit = {
            cache: "default",
            headers,
            method: "GET",
            mode: "cors"
        };
        // if the user has no roles, it will return all data, so we set an 'unknown' role if no roles found so that no data will be returned
        const url = `/api/PowerBiService/GetEmbedTokenForRDLReport?reportId=${reportId}&groupId=${groupId}&userEmail=${userProfile.emailAddress}`;
        const request = new Request(url, init);
        return fetch(request)
            .then(result => result.json())
            .then(data => {
                return data as IPbiTokenData;
            })
            .catch(err => {
                // tslint:disable-next-line:no-console
                console.log(`Fetch error for ${url}`, err);
                return {} as IPbiTokenData;
            });
    }

    public getReportInGroup(accessToken: string) {
        const { paginatedReport, apiAccessToken, activeUserRole } = this.props;
        const { groupId, reportId } = paginatedReport;
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${apiAccessToken}`);
        headers.append("AccessToken", accessToken);
        const init: RequestInit = {
            cache: "default",
            headers,
            method: "GET",
            mode: "cors"
        };
        const url = `/api/PowerBiService/GetPaginatedReportInGroup?groupId=${groupId}&reportId=${reportId}&role=${activeUserRole.name}`;
        const request = new Request(url, init);
        return fetch(request)
            .then(result => result.json())
            .then(data => {
                return data as IPbiPaginatedReport;
            })
            .catch(err =>
                // tslint:disable-next-line:no-console
                console.log(`Fetch error for ${url}`, err)
            );
    }
    public tokenRefresh = async () => {
        const { apiAccessToken, token, onHandleRefreshToken } = this.props;
        await onHandleRefreshToken(apiAccessToken, token.refresh_token);
    };
    public async componentDidUpdate(prevProps: IPaginatedReportViewProps) {
        const { onSetActivePaginatedReport, paginatedReport } = this.props;
        if (prevProps.paginatedReport.id !== paginatedReport.id) {
            // tslint:disable-next-line:no-console
            console.log("props paginatedReport change detected, refreshing");
            onSetActivePaginatedReport(paginatedReport.id);
            this.tokenRefresh();
        }
    }
    public async componentDidMount() {
        const {
            paginatedReport,
            onSetActivePaginatedReport
        } = this.props;
        onSetActivePaginatedReport(paginatedReport.id);
        await this.setState(() => ({ fetching: true, fetched: false }));
        await this.tokenRefresh();
        await this.setState(() => ({ fetching: false, fetched: true }));
        const intervalId = window.setInterval(this.tokenRefresh, 55 * 60 * 1000); // 55 minutes between refreshes of PBI token
        await this.setState(() => ({ intervalId }));
        this.setState({ width: "calc(96.5vw)", height: "calc(100vh)" });
    }

    public shouldComponentUpdate(
        nextProps: IPaginatedReportViewProps,
        nextState: IPaginatedReportViewState
    ) {
        if (this.props.token.access_token !== nextProps.token.access_token) {
            if (nextProps.token.access_token !== "") {
                this.getPbiReportAccessToken(nextProps.token.access_token).then(
                    (pbiToken: IPbiTokenData) => {
                        this.setState(() => ({ pbiToken }));
                        if (this.report !== null) {
                            this.report.setAccessToken(pbiToken.token);
                        }
                    }
                );
            }
            if (this.props.token.access_token === "") {
                return true;
            } else {
                return false;
            }
        }
        if (this.state.pbiToken.token !== nextState.pbiToken.token) {
            if (this.state.pbiToken.token === "") {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.state.fitToWindowVisible !== nextState.fitToWindowVisible) {
                return false;
            }

            if (this.state.height !== nextState.height) {
                if (this.state.height === "") {
                    return true;
                } else {
                    return false;
                }
            }
            if (this.state.width !== nextState.width) {
                if (this.state.width === "") {
                    return true;
                } else {
                    return false;
                }
            }
        }
        if (this.props.paginatedReport.id !== nextProps.paginatedReport.id) {
            return true;
        }
        return false;
    }

    public componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    public render() {
        const { paginatedReport, roleCount } = this.props;
        const { reportId } = paginatedReport;
        const { fetching, fetched, pbiToken, embedUrl } = this.state;


        const extraSettings = {
            filterPanelEnabled: false, // true
            navContentPaneEnabled: true, // false
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToPage
            }
            // any other custom settings
        };
        return (
            <React.Fragment>
                <span style={{ backgroundColor: "white" }}>
                    Report: <strong>{paginatedReport.displayName}</strong>
                    {roleCount > 1 && (
                        <span>
                            {" "}
                            using Role: <strong><ActiveRoleBadge /></strong>
                        </span>
                    )}
                </span>

                <Button
                    onClick={this.setFullscreen}
                    color="info"
                    size="sm"
                    style={{ margin: 4, float: "right" }}
                >
                    Go Full Screen
                </Button>
                <ResetSizeToWindowButton
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => {
                        this.setState({
                            fitToWindowVisible: false,
                            height: "calc(85vh)",
                            width: "calc(99vw)"
                        });
                    }}
                    isVisible={this.state.fitToWindowVisible}
                />
                {fetching && !fetched && (
                    <Jumbotron
                        style={{ height: this.state.height, width: this.state.width }}
                    >
                        <h1>Loading...</h1>
                        <Spinner color="info" type="grow" />
                        <Spinner color="info" type="grow" />
                        <Spinner color="info" type="grow" />
                        <Spinner color="info" type="grow" />
                        <Spinner color="info" type="grow" />
                    </Jumbotron>
                )}
                {!fetching && fetched && (
                    <div id="embedContainer">
                        {!pbiToken && <div>Loading...</div>}
                        {pbiToken && pbiToken.token && (
                            <PowerBIEmbed
                                embedConfig={{
                                    type: 'report',   // Supported types: report, paginatedReport, tile, visual and qna
                                    id: reportId,
                                    embedUrl: embedUrl,
                                    accessToken: pbiToken.token,
                                    tokenType: models.TokenType.Embed,
                                    pageView: "fitToPage",
                                    settings: {
                                        extraSettings
                                    },

                                    background: models.BackgroundType.Transparent,
                                }
                                }
                                cssClassName={
                                    "report-style-class"
                                }
                                eventHandlers={
                                    new Map([
                                        ['loaded', function () { console.log('Report loaded'); }],
                                        ['rendered', function () { console.log('Report rendered'); }],
                                        ['error', function (event: any) { console.log(event.detail); }]
                                    ])
                                }
                                getEmbeddedComponent={(embeddedReport: any) => {
                                    this.report = embeddedReport as Report;
                                }}
                            />
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}


const matchStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
    const { match } = ownProps;
    let paginatedReport = {} as IPbiPaginatedReport;
    if (match !== null) {
        const id = (match.params as any).id as string;
        paginatedReport = state.userPaginatedReports.find(dash => {
            return dash.id === parseInt(id, 10);
        }) as IPbiPaginatedReport;
        // if (paginatedReport === undefined) {
        //   // tslint:disable-next-line:no-console
        //   console.log("didn't find the paginatedReport");
        //   return { paginatedReport: {} as IPbiPaginatedReport };
        // }
    }
    return {
        activeUserRole: state.userRoles.find(
            role => role.id === state.activeRoleId
        ) as IRole,

        userProfile: state.userProfile,
        roleCount: state.userRoles.length,
        // tslint:disable-next-line:object-literal-sort-keys
        apiAccessToken: state.auth0.accessToken,
        paginatedReport,
        fyStartMonths: state.fyStartMonths,
        token: state.tokenData
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onClearActivePaginatedReport: () => {
        dispatch(clearActivePaginatedReport());
    },
    onSetActivePaginatedReport: (id: number) => {
        dispatch(setActivePaginatedReport(id));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleRefreshToken: async (accessToken: string, refreshToken: string) => {
        await dispatch(handleRefreshToken(accessToken, refreshToken));
    }
});


const PaginatedReportViewPage = connect(
    matchStateToProps,
    mapDispatchToProps
)(PaginatedReportView);

export default PaginatedReportViewPage;

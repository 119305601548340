import * as React from "react";
import { Input, Table } from "reactstrap";
import { settings } from "../../constants";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";

export interface IPaginatedReportDetailsTableProps {
  paginatedReport: IPbiPaginatedReport;
}

const PaginatedReportDetailsTable: React.SFC<IPaginatedReportDetailsTableProps> = (
  props: IPaginatedReportDetailsTableProps
) => {
  const { paginatedReport } = props;
  const {
    displayName,
    name,
    groupId,
    reportId,    
    isEnabled
  } = paginatedReport;
  const { allowDisablePaginatedReports } = settings;
  return (
    <Table striped={true}>
      {/* <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead> */}
      <tbody>
        <tr>
          <th scope="row">Display Name</th>
          <td> {displayName}</td>
        </tr>
        <tr>
          <th scope="row">Name</th>
          <td> {name}</td>
        </tr>
        <tr>
          <th scope="row">Group ID</th>
          <td> {groupId}</td>
        </tr>
        <tr>
          <th scope="row">Report Id</th>
          <td> {reportId}</td>
        </tr>       
        {allowDisablePaginatedReports && (
          <tr>
            <th scope="row">Enabled</th>
            <td>
              <Input type="checkbox" checked={isEnabled} readOnly={true} />
              {isEnabled}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PaginatedReportDetailsTable;

import { AnyAction } from "redux";
import {
    ADD_SCHEDULE,
    LOAD_SCHEDULES,
    REMOVE_SCHEDULE,
    UPDATE_SCHEDULE,
    GET_EDIT_SCHEDULE_DETAILS_SUMMARY,
    COPY_SCHEDULE,
    RUN_SCHEDULE
} from "../actions/schedules";
import ISchedule from "../models/ISchedule";

export const schedules = (state = [] as ISchedule[], action: AnyAction) => {
    switch (action.type) {
        case ADD_SCHEDULE:
            const newState: ISchedule[] = state.slice();
            newState.push(action.schedule as ISchedule);
            return newState;
        case REMOVE_SCHEDULE:
            return Object.assign(
                [],
                state.filter((schedule: ISchedule) => schedule.id !== (action.id as number))
            ) as ISchedule[];
        case UPDATE_SCHEDULE:
            return Object.assign(
                [],
                state.map((schedule: ISchedule) => {
                    if (schedule.id === (action.schedule as ISchedule).id) {
                        return action.schedule as ISchedule;
                    }
                    return schedule;
                })
            ) as ISchedule[];
        case LOAD_SCHEDULES:
            return action.schedules as ISchedule[];

        case COPY_SCHEDULE:
            return state;

        case GET_EDIT_SCHEDULE_DETAILS_SUMMARY:
            return action.summary as string;

        case RUN_SCHEDULE:
            return action.message as string;

        default:
            return state;
    }
};

export default schedules;

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { clearEditRoleDashboardIds } from "../../../actions/editRoleDashboardIds";
import { clearEditRolePaginatedReportIds } from "../../../actions/editRolePaginatedReportIds";
import {
  clearEditRoleDetails,
  setEditRoleDetails
} from "../../../actions/editRoleDetails";
import { clearEditRolePredicates } from "../../../actions/editRolePredicates";
import { clearEditRoleReportIds } from "../../../actions/editRoleReportIds";
import { clearEditRoleUserIds } from "../../../actions/editRoleUserIds";
import { handleLoadRoles } from "../../../actions/roles";
import AddRoleDetails from "../../../components/templates/admin/AddRoleDetails";
import AssignRoleDashboards from "../../../components/templates/admin/AssignRoleDashboards";
import AssignRolePaginatedReports from "../../../components/templates/admin/AssignRolePaginatedReports";
import AssignRolePredicates from "../../../components/templates/admin/AssignRolePredicates";
import AssignRoleReports from "../../../components/templates/admin/AssignRoleReports";
import AssignRoleUsers from "../../../components/templates/admin/AssignRoleUsers";
import EditRoleDetails from "../../../components/templates/admin/EditRoleDetails";
import RoleSummary from "../../../components/templates/admin/RoleSummary";
import paths, { roleWizardStages } from "../../../constants";
import IRole from "../../../models/IRole";
import { IStore } from "../../../models/IStore";
import RoleWizardProgress from "../../molecules/RoleWizardProgress";

export interface IRoleWizardPageProps extends RouteComponentProps {
  accessToken: string;
  roles: IRole[];
  roleId: number;
  onHandleLoadEditRole: (role: IRole) => void;
  onHandleLoadRoles: (accessToken: string) => void;
  onHandleClearEditRole: () => void;
  onHandleClearEditDashboards: () => void;
  onHandleClearEditPaginatedReports: () => void;
  onHandleClearEditReports: () => void;
  onHandleClearEditUsers: () => void;
  onHandleClearEditPredicates: () => void;
}

export interface IRoleWizardPageState {
  currentStage: number;
  role: IRole;
}

class RoleWizardPage extends React.Component<
  IRoleWizardPageProps,
  IRoleWizardPageState
> {
  public state = {
    currentStage: 0,
    role: {
      id: 0,
      // tslint:disable-next-line:object-literal-sort-keys
      displayName: "",
      name: "",
      currencyCodeId: 1,
      fyStartMonthId: 1
    } as IRole
  };
  constructor(props: IRoleWizardPageProps) {
    super(props);
    this.incrementStage = this.incrementStage.bind(this);
    this.decrementStage = this.decrementStage.bind(this);
    this.renderCurrentStage = this.renderCurrentStage.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  public incrementStage = (e: React.MouseEvent<HTMLElement>) => {
    const { currentStage } = this.state;
    this.setState(() => ({ currentStage: currentStage + 1 }));
  };

  public decrementStage = (e: React.MouseEvent<HTMLElement>) => {
    const { currentStage } = this.state;
    this.setState(() => ({ currentStage: currentStage - 1 }));
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public renderCurrentStage = (currentStage: number) => {
    switch (currentStage) {
      case roleWizardStages.addInitialRoleDetails:
        if (this.state.role.id === 0) {
          return <AddRoleDetails />;
        } else {
          return <EditRoleDetails {...this.props} />;
        }

      case roleWizardStages.addPredicateParameters:
        return <AssignRolePredicates />;
      case roleWizardStages.assignDashboards:
        return <AssignRoleDashboards />;
      case roleWizardStages.assignReports:
        return <AssignRoleReports />;
      case roleWizardStages.assignUsers:
        return <AssignRoleUsers />;
      case roleWizardStages.reviewAndComplete:
            return <RoleSummary {...this.props} />;
        case roleWizardStages.assignPaginatedReports:
            return <AssignRolePaginatedReports />;
      default:
        return (
          <Card>
            <CardBody>Not sure what stage we're at</CardBody>
          </Card>
        );
    }
  };

  public async componentDidMount() {
    const {
      accessToken,
      roles,
      onHandleLoadRoles,
      onHandleLoadEditRole,
      onHandleClearEditRole,
      roleId
    } = this.props;
    if (roles.length === 0) {
      await onHandleLoadRoles(accessToken);
    }
    if (roleId !== 0) {
      const role = roles.find(r => r.id === roleId);
      if (role !== undefined) {
        this.setState(() => ({ role }));
        await onHandleLoadEditRole(role);
      }
    } else {
      await onHandleClearEditRole();
      this.setState(() => ({
        role: {
          id: 0,
          // tslint:disable-next-line:object-literal-sort-keys
          displayName: "",
          name: "",
          currencyCodeId: 1,
          fyStartMonthId: 1
        } as IRole
      }));
    }
  }

  public async componentDidUpdate(prevProps: IRoleWizardPageProps) {
    const {
      roleId,
      onHandleLoadEditRole,
      onHandleClearEditRole,
      roles
    } = this.props;
    if (roleId !== prevProps.roleId) {
      if (roleId !== 0) {
        const role = roles.find(r => r.id === roleId);
        if (role !== undefined) {
          this.setState(() => ({ role }));
          await onHandleLoadEditRole(role);
        } else {
          await onHandleClearEditRole();
        }
      } else {
        await onHandleClearEditRole();
        this.setState(() => ({
          role: {
            id: 0,
            // tslint:disable-next-line:object-literal-sort-keys
            displayName: "",
            name: "",
            currencyCodeId: 1,
            fyStartMonthId: 1
          } as IRole
        }));
      }
    }
  }
  public componentWillUnmount() {
    this.props.onHandleClearEditRole();
    this.props.onHandleClearEditReports();
    this.props.onHandleClearEditPaginatedReports();
    this.props.onHandleClearEditDashboards();
    this.props.onHandleClearEditUsers();
    this.props.onHandleClearEditPredicates();
    this.setState(() => ({
      role: {
        id: 0,
        // tslint:disable-next-line:object-literal-sort-keys
        displayName: "",
        name: "",
        currencyCodeId: 1,
        fyStartMonthId: 1
      } as IRole
    }));
  }
  public render() {
    const { currentStage } = this.state;
    const { roles, roleId } = this.props;
    return (
      <React.Fragment>
        <Container style={{ backgroundColor: "white" }}>
          <Row>
            <Col>
              <h1>
                Client Role Wizard{" "}
                {(roles.find(role => role.id === roleId) as IRole) === undefined
                  ? ""
                  : ": " +
                    (roles.find(role => role.id === roleId) as IRole)
                      .displayName}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <RoleWizardProgress currentStage={currentStage} />
            </Col>
          </Row>
          <Row>
            <Col>
              {currentStage > roleWizardStages.addInitialRoleDetails && (
                <Button
                  onClick={this.decrementStage}
                  style={{ padding: 4, margin: 4 }}
                >
                  Previous
                </Button>
              )}
              {currentStage < roleWizardStages.reviewAndComplete && (
                <React.Fragment>
                  {this.props.roleId !== 0 && (
                    <Button
                      onClick={this.incrementStage}
                      style={{ padding: 4, margin: 4 }}
                    >
                      Next
                    </Button>
                  )}
                </React.Fragment>
              )}

              {/* <Button onClick={this.cancel} style={{ padding: 4, margin: 4 }}>
                Cancel
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderCurrentStage(currentStage)}</Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  const roleId =
    (match.params as any).id !== undefined
      ? parseInt((match.params as any).id as string, 10) // we're editing an existing role
      : // we're adding a new role
      state.editRoleDetails.id === undefined
      ? 0 // we haven't saved the role yet so not yet in store
      : state.editRoleDetails.id;

  return {
    accessToken: state.auth0.accessToken,
    roleId,
    roles: state.roles
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadEditRole: (role: IRole) => {
    dispatch(setEditRoleDetails(role));
  },
  onHandleLoadRoles: (accessToken: string) => {
    dispatch(handleLoadRoles(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearEditRole: () => {
    dispatch(clearEditRoleDetails());
  },
  onHandleClearEditDashboards: () => {
    dispatch(clearEditRoleDashboardIds());
    },
 onHandleClearEditPaginatedReports: () => {
        dispatch(clearEditRolePaginatedReportIds());
    },    
  onHandleClearEditReports: () => {
    dispatch(clearEditRoleReportIds());
  },
  onHandleClearEditUsers: () => {
    dispatch(clearEditRoleUserIds());
  },
  onHandleClearEditPredicates: () => {
    dispatch(clearEditRolePredicates());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleWizardPage);

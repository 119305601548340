import * as React from "react";
import { Card, CardFooter, CardTitle, Spinner } from "reactstrap";

export interface IDataLoadingProps{
  dataTitle?: string;
}

const DataLoading:React.SFC<IDataLoadingProps> = (props:IDataLoadingProps) => {
  const {dataTitle} = props;
    return (
        /*  <Card Style="width: 48rem; border-radius: 20% center">*/
        <Card style={{ width: "48rem", borderRadius:"20% center"}}>
      <CardTitle>{dataTitle===undefined?"":dataTitle} {" "} Data is loading...please wait...</CardTitle>
      <CardFooter size="sm">
        <Spinner color="info" type="grow" size = "sm"/>
              <Spinner color="info" type="grow" size="sm"/>
              <Spinner color="info" type="grow" size="sm"/>
              <Spinner color="info" type="grow" size="sm"/>
              <Spinner color="info" type="grow" size="sm"/>
      </CardFooter>
    </Card>
  );
};

export default DataLoading;

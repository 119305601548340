import IScheduleFrequency from "../models/IScheduleFrequency";
import { fetchSecureData } from "../utils";

export const getScheduleFrequencies = async (accessToken: string) => {
    const data = await fetchSecureData(
        "GET",
        "/api/ScheduleFrequency/GetScheduleFrequencies",
        accessToken
    );
    const scheduleFrequencies = ((await data.json()) as any).value as IScheduleFrequency[];
    if (scheduleFrequencies !== undefined) {
        return scheduleFrequencies;
    } else {
        return [] as IScheduleFrequency[];
    }
};

export default getScheduleFrequencies;

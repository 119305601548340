import * as Redux from "redux";
import getScheduleUsers from "../api/getScheduleUsers";
import removeScheduleUser from "../api/removeScheduleUser";
import saveScheduleUser from "../api/saveScheduleUser";
import { setEditScheduleUsersLoaded } from "./loadingEditScheduleUsers";
import IScheduleRoleUserIds from "../models/IScheduleRoleUserIds";


export const ADD_EDIT_SCHEDULE_USER_ID = "ADD_EDIT_SCHEDULE_USER_ID";
export const REMOVE_EDIT_SCHEDULE_USER_ID = "REMOVE_EDIT_SCHEDULE_USER_ID";
export const LOAD_EDIT_SCHEDULE_USER_IDS = "LOAD_EDIT_SCHEDULE_USER_IDS";
export const CLEAR_EDIT_SCHEDULE_USER_IDS = "CLEAR_EDIT_SCHEDULE_USER_IDS";





export const addEditScheduleUserId = (scheduleId: number, roleId: number, userId: number) => {

    let id = { scheduleId: scheduleId, roleId: roleId, userId: userId } as IScheduleRoleUserIds

  return {
      id ,
    type: ADD_EDIT_SCHEDULE_USER_ID
  };
};

export const removeEditScheduleUserId = (scheduleId: number, roleId: number, userId: number) => {
    let id = { scheduleId: scheduleId, roleId: roleId, userId: userId } as IScheduleRoleUserIds
  return {
    id,
    type: REMOVE_EDIT_SCHEDULE_USER_ID
  };
};

export const loadEditScheduleUserIds = (ids: IScheduleRoleUserIds[]) => {
  return {
    ids,
    type: LOAD_EDIT_SCHEDULE_USER_IDS
  };
};

export const clearEditScheduleUserIds = () => {
  return {
    type: CLEAR_EDIT_SCHEDULE_USER_IDS
  };
};

export const handleLoadEditScheduleUserIds = (
  accessToken: string,
  scheduleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const scheduleUsers = await getScheduleUsers(accessToken, scheduleId);
    if (scheduleUsers === undefined) {
      dispatch(clearEditScheduleUserIds());
      dispatch(setEditScheduleUsersLoaded(false));
    } else {
      if (scheduleUsers.length > 0) {
        dispatch(loadEditScheduleUserIds(scheduleUsers));
      }
      dispatch(setEditScheduleUsersLoaded(true));
    }
  };
};

export const handleSaveEditScheduleUserId = (
  accessToken: string,
    scheduleId: number,
    roleId: number,
    userId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        dispatch(setEditScheduleUsersLoaded(false));
      await saveScheduleUser(accessToken, scheduleId, roleId, userId);
        dispatch(addEditScheduleUserId(scheduleId, roleId, userId));
        dispatch(setEditScheduleUsersLoaded(true));
  };
};

export const handleDeleteEditScheduleUserId = (
  accessToken: string,
    scheduleId: number,
    roleId: number,
    userId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        dispatch(setEditScheduleUsersLoaded(false));
      await removeScheduleUser(accessToken, scheduleId, roleId, userId);
        dispatch(removeEditScheduleUserId(scheduleId, roleId, userId));
        dispatch(setEditScheduleUsersLoaded(true));
  };
};

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Input,
 
    Col,
    Row,
    Form,
 
} from "reactstrap";
import {
    handleDeleteSchedule as handleRemoveScheduleProfile,
    handleUpdateSchedule
} from "../../actions/schedules";


import AddTextField from "../../components/molecules/AddTextField";
import ScheduleTable from "../../components/molecules/ScheduleDetailsTable";
import paths, { settings } from "../../constants";
import { IStore } from "../../models/IStore";
import ISchedule from "../../models/ISchedule";
import { validateScheduleName } from "../../utils";
//mport Col from "../atoms/Col";

export interface IEditScheduleProps extends RouteComponentProps {
    accessToken: string;
    schedule: ISchedule;
    existingSchedules: ISchedule[];
    onHandleUpdateSchedule: (accessToken: string, schedule: ISchedule) => void;
    onHandleDeleteSchedule: (accessToken: string, id: number) => void;
}

export interface IEditScheduleState {
    editModeOn: boolean;
    name: string;
    isActive: boolean;
    showDeleteModal: boolean;
}

class EditSchedule extends React.Component<
    IEditScheduleProps,
    IEditScheduleState
> {
    public state = {
        editModeOn: true,
        // tslint:disable-next-line:object-literal-sort-keys
        name: this.props.schedule.name,
        isActive: this.props.schedule.isActive,
        showDeleteModal: false
    };
    constructor(props: IEditScheduleProps) {
        super(props);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.delete = this.delete.bind(this);
        this.readyToSave = this.readyToSave.bind(this);
        this.save = this.save.bind(this);
        this.isActiveChange = this.isActiveChange.bind(this);
    }
    public toggleEdit() {
        this.setState({ editModeOn: !this.state.editModeOn });
    }
    public toggleDeleteModal() {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
    }
    public delete = (e: React.MouseEvent) => {
        const { history, accessToken, schedule, onHandleDeleteSchedule } = this.props;
        // do delete
        onHandleDeleteSchedule(accessToken, schedule.id);
        history.push(paths.home);
    };
    public readyToSave = () => {
        const { name } = this.state;
        if (
            name === undefined ||
            name === "" ||
            !validateScheduleName(name)
        ) {
            return false;
        }

        return true;
    };
    public save = (e: React.MouseEvent<HTMLElement>) => {
        const { accessToken, schedule, onHandleUpdateSchedule } = this.props;
        const { name, isActive} = this.state;

        schedule.isActive = isActive;
        schedule.name = name;

        if (this.readyToSave()) {
            // do save
            onHandleUpdateSchedule(accessToken, schedule);
        }
        this.toggleEdit();
        return true;
    };
    public isActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isActive = e.target.checked;
        this.setState(() => ({ isActive }));
    };

    public render() {
        const { existingSchedules } = this.props;
        const { name, isActive } = this.state;
        return (
            <React.Fragment>
                <Card>
                    <CardBody>   <Form>
                        <Row>
                            <Col>
                                <AddTextField
                                    noDuplicates={true}
                                    isValid={validateScheduleName}
                                    title="Schedule Name"
                                    value={name}
                                    existingValues={existingSchedules.map(usr => usr.name)}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(email: string) =>
                                        this.setState(() => ({ name: email }))
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col >

                                <div style={{ margin: "0 0 0 1.30em" }}>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={isActive}
                                        onChange={this.isActiveChange}                                        
                                    />Is Active


                                </div>
                            </Col>
                        </Row>
                    </Form>

                        {/*{!editModeOn && <ScheduleTable schedule={schedule} />}*/}
                        {/*{editModeOn && (*/}
                        {/*    <React.Fragment>*/}
                        {/*        <div>*/}
                        {/*        <AddTextField*/}
                        {/*            noDuplicates={true}*/}
                        {/*            isValid={validateScheduleName}*/}
                        {/*            title="Schedule Name"*/}
                        {/*            value={name}*/}
                        {/*            existingValues={existingSchedules.map(usr => usr.name)}*/}
                        {/*            // tslint:disable-next-line:jsx-no-lambda*/}
                        {/*            callback={(email: string) =>*/}
                        {/*                this.setState(() => ({ name: email }))*/}
                        {/*            }*/}
                        {/*            />*/}
                        {/*            </div>*/}
                        {/*        <div>*/}
                        {/*            <Input*/}
                        {/*            type="checkbox"*/}
                        {/*            defaultChecked={isActive}*/}
                        {/*            onChange={this.isActiveChange}*/}
                        {/*            name="Is Active"*/}
                        {/*        />                                */}
                        {/*        </div>*/}
                        {/*    </React.Fragment>*/}
                        {/*)}*/}
                        {/*<CardFooter>*/}
                        {/*    {!editModeOn && (*/}
                        {/*        <React.Fragment>*/}
                        {/*            <Button*/}
                        {/*                onClick={this.toggleEdit}*/}
                        {/*                style={{ margin: 4, padding: 4 }}*/}
                        {/*            >*/}
                        {/*                Edit*/}
                        {/*            </Button>*/}
                        {/*            <Button*/}
                        {/*                color="danger"*/}
                        {/*                onClick={this.toggleDeleteModal}*/}
                        {/*                style={{*/}
                        {/*                    margin: 4,*/}
                        {/*                    padding: 4*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <strong>Delete Schedule</strong>*/}
                        {/*            </Button>*/}
                        {/*        </React.Fragment>*/}
                        {/*    )}*/}
                        {/*    {editModeOn && (*/}
                        {/*        <React.Fragment>*/}
                        {/*            {this.readyToSave() && (*/}
                        {/*                <Button*/}
                        {/*                    onClick={this.save}*/}
                        {/*                    style={{ margin: 4, padding: 4 }}*/}
                        {/*                >*/}
                        {/*                    Save Changes*/}
                        {/*                </Button>*/}
                        {/*            )}*/}
                        {/*            <Button*/}
                        {/*                onClick={this.toggleEdit}*/}
                        {/*                style={{ margin: 4, padding: 4 }}*/}
                        {/*            >*/}
                        {/*                Cancel*/}
                        {/*            </Button>*/}
                        {/*        </React.Fragment>*/}
                        {/*    )}*/}
                        {/*</CardFooter>*/}
                    </CardBody>
                    <CardFooter>
                        <React.Fragment>
                            {this.readyToSave() && (
                                <Button
                                    onClick={this.save}
                                    style={{ margin: 4, padding: 4 }}
                                >
                                    Save Changes
                                </Button>
                            )}
                            <Button
                                onClick={this.toggleEdit}
                                style={{ margin: 4, padding: 4 }}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>

                    </CardFooter>
                </Card>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        existingSchedules: state.schedules.filter(schedule => schedule.id !== state.editScheduleDetails.id
        ),
        schedule: state.editScheduleDetails
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleUpdateSchedule: (accessToken: string, schedule: ISchedule) => {

        dispatch(handleUpdateSchedule(accessToken, schedule));
       
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleDeleteSchedule: (accessToken: string, id: number) => {
        dispatch(handleRemoveScheduleProfile(accessToken, id));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);

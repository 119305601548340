import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Table
} from "reactstrap";
import {
  handleLoadPaginatedReports,
  handleSavePaginatedReport
} from "../../../actions/paginatedReports";
import AddTextField from "../../molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IPbiPaginatedReport from "../../../models/IPbiPaginatedReport";
import { IStore } from "../../../models/IStore";
import {
  validatePaginatedReportDisplayName,
  validatePaginatedReportName,
  validateGuid
} from "../../../utils";

export interface IAddPaginatedReportPageProps extends RouteComponentProps {
  accessToken: string;
  existingPaginatedReports: IPbiPaginatedReport[];
  onHandleLoadPaginatedReports: (accessToken: string) => void;
  onHandleSavePaginatedReport: (
    accessToken: string,
    name: string,
    displayName: string,
    groupId: string,
    reportId: string,    
    isEnabled: boolean
  ) => void;
}
export interface IAddPaginatedReportPageState {
  displayName: string;
  name: string;
  groupId: string;
  reportId: string;  
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddPaginatedReportPage extends React.Component<
  IAddPaginatedReportPageProps,
  IAddPaginatedReportPageState
> {
  public isLoaded = false;
  public state = {
    displayName: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    groupId: "",
    reportId: "",    
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddPaginatedReportPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const {
      displayName,
      name,
      groupId,
      reportId
    } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validatePaginatedReportDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (name === undefined || name === "" || !validatePaginatedReportName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (groupId === undefined || groupId === "" || !validateGuid(groupId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (reportId === undefined || reportId === "" || !validateGuid(reportId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }    
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSavePaginatedReport, history } = this.props;
    const {
      name,
      displayName,
      groupId,
      reportId,      
      isEnabled
    } = this.state;
    if (this.readyToSave()) {
      onHandleSavePaginatedReport(
        accessToken,
        name,
        displayName,
        groupId,
        reportId,        
        isEnabled
      );
      if (!addMore) {
          history.push(paths.searchPaginatedReports);
      } else {
        this.setState(() => ({
          displayName: "",
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          groupId: "",
          reportId: "",     
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.searchPaginatedReports);
  };

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadPaginatedReports,
      existingPaginatedReports
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingPaginatedReports.length === 0) {
        onHandleLoadPaginatedReports(accessToken);
      }
    }
    }

  public render() {
    const { existingPaginatedReports } = this.props;
    const {
      displayName,
      name,
      groupId,
      reportId, 
      isEnabled
    } = this.state;
    const { allowDisablePaginatedReports } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New Report Details</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validatePaginatedReportDisplayName}
                title="Display Name"
                value={displayName}
                existingValues={existingPaginatedReports.map(d => d.displayName)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dn: string) => {
                  await this.setState(() => ({ displayName: dn }));
                  this.readyToSave();
                }}
              />

              <AddTextField
                noDuplicates={true}
                isValid={validatePaginatedReportName}
                title="Name"
                value={name}
                existingValues={existingPaginatedReports.map(d => d.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={false}
                isValid={validateGuid}
                title="Group ID"
                value={groupId}
                existingValues={existingPaginatedReports.map(d => d.groupId)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (gId: string) => {
                  await this.setState(() => ({ groupId: gId }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={true}
                isValid={validateGuid}
                title="Report ID"
                value={reportId}
                existingValues={existingPaginatedReports.map(d => d.reportId)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (rId: string) => {
                  await this.setState(() => ({ reportId: rId }));
                  this.readyToSave();
                }}
              /> 
              {allowDisablePaginatedReports && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingPaginatedReports: state.paginatedReports
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadPaginatedReports: (accessToken: string) => {
    dispatch(handleLoadPaginatedReports(accessToken));
  },
  onHandleSavePaginatedReport: (
    accessToken: string,
    name: string,
    displayName: string,
    groupId: string,
    reportId: string,    
    isEnabled: boolean
  ) => {
    dispatch(
      handleSavePaginatedReport(
        accessToken,
        name,
        displayName,
        groupId,
        reportId,
        isEnabled
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaginatedReportPage);

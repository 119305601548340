import { AnyAction } from "redux";
import { SET_USER_SCHEDULES_LOADED } from "../actions/loadingUserSchedules";

export const loadingUserSchedules = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_SCHEDULES_LOADED:
      return action.userSchedulesLoaded;
    default:
      return state;
  }
};

export default loadingUserSchedules;

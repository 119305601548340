import * as Redux from "redux"; 
import saveDashboardShared from "../api/saveDashboardShared"; 
import IPbiDashboardShared from "../models/IPbiDashboardShared";
import getEmailedDashboard from "../api/getEmailedDashboard";
import { setEmailDashboardLoaded } from "./loadingEmailedDashboard";
import { setActiveRoleId } from "./activeRole";


export const ADD_DASHBOARD_SHARED = "ADD_DASHBOARD_SHARED";
export const LOAD_EMAILED_DASHBOARD = "LOAD_EMAILED_DASHBOARD";
 
export const addDashboardShared = (dashboardShared: IPbiDashboardShared) => {
    return {
        dashboardShared,
        type: ADD_DASHBOARD_SHARED
    };
};

export const loadDashboardEmailed = (emailedPbiDashboard: IPbiDashboardShared) => {
    return {
        emailedPbiDashboard,
        type: LOAD_EMAILED_DASHBOARD
    };
};
  

export const handleSaveDashboardShared = (
    accessToken: string,
    pbiDashboardId: number,
    roleId: number,
    userProfileId: number,
    createdBy: number,
    capturedBookmark: string,
    message: string 
) => {
    return async (dispatch: Redux.Dispatch) => {
        const dashboard = await saveDashboardShared(
            accessToken,
            pbiDashboardId,
            roleId,
            userProfileId,
            createdBy,
            capturedBookmark,
            message
        );
        if (dashboard !== null && dashboard !== undefined) {
            dispatch(addDashboardShared(dashboard));
        }
    };
};

export const handleLoadEmailedDashboard = (accessToken: string) => {
    return async (dispatch: Redux.Dispatch) => {
        var uid = localStorage.getItem('emailedDashboardUid');      
        if (uid === undefined ||  uid === null) {
            return true;
        } 

        const emailedPbiDashboard = await getEmailedDashboard(accessToken, uid);
        if (emailedPbiDashboard !== undefined) {
            dispatch(loadDashboardEmailed(emailedPbiDashboard));
            dispatch(setEmailDashboardLoaded(true));
            await dispatch(setActiveRoleId(emailedPbiDashboard.roleId));
             
        }
    };
};


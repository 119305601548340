import ISchedule from "../models/ISchedule";
import { fetchSecureData } from "../utils";

export const getSchedules = async (accessToken: string, userId:number) => {
    try {
        const data = await fetchSecureData(
            "GET",
            `/api/Schedule/GetSchedulesByCreatedBy?userProfileId=${userId}`,  
            accessToken
        );
        const schedules = ((await data.json()) as any).value as ISchedule[];
             if (schedules !== undefined) {
            return schedules;
        } else {
            return [] as ISchedule[];
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for /api/Schedule/GetSchedulesByCreatedBy?userProfileId=${userId}`, err);
    }
    return [] as ISchedule[];
};

export default getSchedules;

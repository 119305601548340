import ISchedulePaginatedReportParameter from "../models/ISchedulePaginatedReportParameter";
import { fetchSecureData } from "../utils";

export const getSchedulePaginatedReportParameters = async (
    accessToken: string,
    scheduleId: number
) => {
    try {
        const data = await fetchSecureData(
            "GET",
            `/api/Schedule/GetScheduleReportPatametersTree?scheduleId=${scheduleId}`,
            accessToken
        );

        const reportParameters = ((await data.json()) as any).value as ISchedulePaginatedReportParameter[];
        if (reportParameters !== undefined) {
            return reportParameters;
        } else {
            return [] as ISchedulePaginatedReportParameter[];
        } 
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
            `Fetch error for /api/Schedule/GetScheduleReportPatametersTree?scheduleId=${scheduleId}`,
            err
        );
    }
    return [] as ISchedulePaginatedReportParameter[];
};

export default getSchedulePaginatedReportParameters;

import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils";

export const saveRolePaginatedReport = async (
  accessToken: string,
  roleId: number,
  paginatedReportId: number,
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/RolePaginatedReport/Add?roleId=${roleId}&paginatedReportId=${paginatedReportId}`,
      accessToken
    );
    const rolePaginatedReport = ((await data.json()) as any).value as IPbiPaginatedReport;
    return rolePaginatedReport;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RolePaginatedReport/Add?roleId=${roleId}&&paginatedReportId=${paginatedReportId}`
    );
  }
  return {} as IPbiPaginatedReport;
};

export default saveRolePaginatedReport;

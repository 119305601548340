import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import { handleSetActiveRolePredicateClient } from "../../actions/shared";
import paths from "../../constants";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";
import RoleIcon from "../molecules/RoleIcon";

export interface IActiveRoleDropDownMenuProps extends RouteComponentProps {
    selectableRoles: IRole[];
    activeRoleId: number;
    accessToken: string;
    userId: number;
    onHandleSelectActiveRoleId: (accessToken: string, roleId: number, userId: number) => void;
}

export interface IActiveRoleDropDownMenuState {
    activeRoleId: number;
    dropdownOpen: boolean;
}

class ActiveRoleDropDownMenu extends React.Component<
    IActiveRoleDropDownMenuProps,
    IActiveRoleDropDownMenuState
> {
    public state: IActiveRoleDropDownMenuState = {
        activeRoleId: 1,
        dropdownOpen: false
    };
    constructor(props: IActiveRoleDropDownMenuProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.activeRoleIdChange = this.activeRoleIdChange.bind(this);
        this.state = {
            activeRoleId: 1,
            dropdownOpen: false
        };
    }

    public toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    public activeRoleIdChange = (newRoleId: number) => {
        const { accessToken, onHandleSelectActiveRoleId, history, userId } = this.props;
        const activeRoleId = newRoleId;
        onHandleSelectActiveRoleId(accessToken, activeRoleId, userId);
        history.push(paths.home);
        this.setState(() => ({ activeRoleId }));
    };
    public async componentDidUpdate(prevProps: IActiveRoleDropDownMenuProps) {
        const { activeRoleId } = this.props;
        if (
            activeRoleId !== prevProps.activeRoleId &&
            activeRoleId !== undefined &&
            activeRoleId !== 0
        ) {
            await this.setState(() => ({ activeRoleId }));
        }
    }
    public async componentDidMount() {
        const { activeRoleId } = this.props;
        if (activeRoleId !== undefined && activeRoleId !== 0) {
            await this.setState(() => ({ activeRoleId }));
        }
    }
    public render() {
        const { selectableRoles } = this.props;
        const { activeRoleId } = this.state;
        const activeRole = selectableRoles.find(role => role.id === activeRoleId);
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="span"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    style={{ cursor: "pointer" }}
                >
                    <RoleIcon /> <Text style={{ color: "#FFF", paddingLeft: "8px" }}>Roles</Text>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header={true}>Active Role</DropdownItem>
                    {activeRole && <DropdownItem>{activeRole.displayName}</DropdownItem>}
                    <DropdownItem header={true}>Available Roles</DropdownItem>
                    {selectableRoles.map(
                        role =>
                            role.id !== activeRoleId && (
                                <DropdownItem key={role.id}>
                                    <Link
                                        to="#"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            this.activeRoleIdChange(role.id);
                                        }}
                                    >
                                        {role.displayName}
                                    </Link>
                                </DropdownItem>
                            )
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        activeRoleId: state.activeRoleId,
        selectableRoles: state.userRoles,
        userId: state.userProfile.id
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleSelectActiveRoleId: (accessToken: string, roleId: number, userId: number) => {
        dispatch(handleSetActiveRolePredicateClient(accessToken, roleId, userId));
    }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ActiveRoleDropDownMenu)
);

import * as React from "react";
export interface INavListLeftProps {
  children: React.ReactNode;
}
const NavListLeft: React.SFC<INavListLeftProps> = (
  props: INavListLeftProps
) => {
  const { children } = props;
  return (
      <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">{children}</ul>
    </div>
  );
};

export default NavListLeft;

import * as React from "react";

export interface ILoginButtonProps {
    login: () => void;
    outline?: boolean;
}

const LoginButton = (props: ILoginButtonProps) => {
    const { login, outline } = props;
    return (
        <button
            onClick={login}
            style={{ height: '45px', width:'106px' ,fontSize:'18px'}}
            className={
                outline ? "btn btn-outline-success" : "btn { margin: 0rem;` padding: 0rem 0rem; } btn-primary"
            }
        >
            <strong>Sign-in</strong>
        </button>
    );
};

export default LoginButton;


import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import ScheduleDetailsTable from "../../components/organisms/ScheduleDetailsTable";
import paths from "../../constants";
import { IStore } from "../../models/IStore";
import IUserProfile from "../../models/IUserProfile";
import UsersBadge from "../../components/molecules/UsersBadge";
import ScheduleUsersBadge from "../../components/molecules/ScheduleUsersBadge";


import AddScheduleReportFormats from "../templates/AddScheduleReportFormats";
import EditSchedulePaginatedReportParametersDetails from "../templates/EditSchedulePaginatedReportParametersDetails";
import IScheduleRoleUserIds from "../../models/IScheduleRoleUserIds";
import PaginatedReportsBadge from "../../components/molecules/PaginatedReportsBadge";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import IScheduleRoleUser from "../../models/IScheduleRoleUser";

export interface IScheduleSummaryState {
    activeEditRoleUsers: IUserProfile[];
}

export interface IScheduleSummaryProps extends RouteComponentProps {
    user: IUserProfile;
    users: IUserProfile[];
    editScheduleUserIds: IScheduleRoleUserIds[];
    paginatedReports: IPbiPaginatedReport[];
    editSchedulePaginatedReportIds: number[];
    userRolesWithUserProfiles: IScheduleRoleUser[];
}

class ScheduleSummary extends React.Component<IScheduleSummaryProps> {
    public render() {
        const { history, users, editScheduleUserIds, paginatedReports, editSchedulePaginatedReportIds, userRolesWithUserProfiles } = this.props;
        return (
            <Card>
                <CardTitle tag="h3">Review and Complete</CardTitle>
                <CardBody>
                    <h1>Schedule Details:</h1>
                    <ScheduleDetailsTable />
                    <br />
                   {/* <UsersBadge users={users} userIds={editScheduleUserIds.map(r => r.userId)} />*/}
                    <ScheduleUsersBadge users={userRolesWithUserProfiles} userIds={editScheduleUserIds} />
                    <br />
                    {/*<PaginatedReportsBadge
                        paginatedReports={paginatedReports}
                        paginatedReportIds={editSchedulePaginatedReportIds}
                    />*/}
                    <br />
                    <EditSchedulePaginatedReportParametersDetails />
                    <br />
                    <AddScheduleReportFormats />
                </CardBody>
                <CardFooter>
                    <Button
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                            history.push(paths.searchSchedules)
                        }
                    >
                        Complete
                    </Button>
                </CardFooter>
            </Card>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    return {
        user: state.editUserDetails,
        users: state.profiles,
        editScheduleUserIds: state.editScheduleUserIds,
        paginatedReports: state.paginatedReports,
        editSchedulePaginatedReportIds: state.editSchedulePaginatedReportIds,
        userRolesWithUserProfiles: state.userRolesWithUserProfiles
    };
};

export default connect(mapStateToProps)(ScheduleSummary);

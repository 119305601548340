import { AnyAction } from "redux";
import {
    ADD_PAGINATED_REPORT,
    LOAD_PAGINATED_REPORTS,
    REMOVE_PAGINATED_REPORT,
    UPDATE_PAGINATED_REPORT,
    ADD_PAGINATED_REPORT_PARAMETERS,
} from "../actions/paginatedReports";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";

export const paginatedReports = (
    state = [] as IPbiPaginatedReport[],
    action: AnyAction
) => {


    switch (action.type) {
        case ADD_PAGINATED_REPORT_PARAMETERS:
            //var newState2: IPbiPaginatedReport[] = state.slice();
            //newState2 = newState2.splice(0);
            //newState2.push(action.paginatedReport as IPbiPaginatedReport);
            //return newState2;
             return Object.assign(
                [],
                state.map((rpt: IPbiPaginatedReport) => {
                    if (rpt.id === (action.paginatedReport as IPbiPaginatedReport).id) {
                        return action.paginatedReport as IPbiPaginatedReport;
                    }
                    return rpt;
                })
            ) as IPbiPaginatedReport[];
        case ADD_PAGINATED_REPORT:
            const newState: IPbiPaginatedReport[] = state.slice();
            newState.push(action.paginatedReport as IPbiPaginatedReport);
            return newState;


        case REMOVE_PAGINATED_REPORT:
            return Object.assign(
                [],
                state.filter(
                    (rpt: IPbiPaginatedReport) => rpt.id !== (action.id as number)
                )
            ) as IPbiPaginatedReport[];
        case UPDATE_PAGINATED_REPORT:
            return Object.assign(
                [],
                state.map((rpt: IPbiPaginatedReport) => {
                    if (rpt.id === (action.paginatedReport as IPbiPaginatedReport).id) {
                        return action.paginatedReport as IPbiPaginatedReport;
                    }
                    return rpt;
                })
            ) as IPbiPaginatedReport[];
        case LOAD_PAGINATED_REPORTS:
            return action.paginatedReports as IPbiPaginatedReport[];
        default:
            return state;
    }
};

export default paginatedReports;


import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    FormFeedback,    
    Input
} from "reactstrap";
import { handleLoadReports } from "../../actions/reports";


import DataLoading from "../organisms/DataLoading";
import paths from "../../constants";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore"; 
import FavoritesIcon from "../molecules/FavoritesIcon";
import NotFavoritesIcon from "../molecules/NotFavoritesIcon";
import IUserFavorite from "../../models/IUserFavorite";
import { handleAddFavorite, handleDeleteFavorite } from "../../actions/userFavorites";



export interface IListUserReportsPageProps {
    accessToken: string;
    onHandleLoadReports: (accessToken: string) => void;
    Reports: ISqlReport[];
    ReportsLoaded: boolean;
    userId: number;
    activeRoleId: number;
    userFavoritesReports: IUserFavorite[];
    onHandleAddFavorite: (accessToken: string, favorite: IUserFavorite) => void;
    onHandleDeleteFavorite: (accessToken: string, favoriteId: number) => void;
}

export interface IListUserReportsPageState {
    filteredReports: ISqlReport[];
    matchedNameExact: boolean;
    name: string;
    noMatchingNames: boolean;
    userFavorites: IUserFavorite[];
}

class ListUserReportsPage extends React.Component<
    IListUserReportsPageProps,
    IListUserReportsPageState
> {
    public isLoaded = false;
    public state = {
        filteredReports: [] as ISqlReport[],
        matchedNameExact: false,
        name: "",
        noMatchingNames: false,
        userFavorites: [] as IUserFavorite[]
    };

    constructor(props: IListUserReportsPageProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.state.userFavorites = props.userFavoritesReports;
    }




    public handleInputChange(e: any): void {
        e.preventDefault();
        const { Reports } = this.props;

        const searchReportDisplayName = e.target.value.toLowerCase();
        if (!searchReportDisplayName) {
            this.setState({
                filteredReports: Reports
            });
        } else {
            const filteredReports: ISqlReport[] = Reports.filter(
                (Report: ISqlReport) =>
                    Report.displayName
                        .toLowerCase()
                        .includes(searchReportDisplayName)
            );
            if (filteredReports.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredReports.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredReports
            });
        }
    }



    public toggleFavorite = (ReportId: number) => {
        const {
            accessToken,
            userId,
            activeRoleId,
            onHandleAddFavorite,
            onHandleDeleteFavorite
        } = this.props;


        if (this.state.userFavorites.find(fav => fav.reportId === ReportId)) {

            const newUserFavorites: IUserFavorite[] = this.state.userFavorites.filter(fav => fav.reportId === ReportId);
            onHandleDeleteFavorite(accessToken, newUserFavorites[0].id);


           // this.setState({ userFavorites: newUserFavorites  });

        
        }
        else {
                  

            const newFav = {
                userProfileId: userId,
                roleId: activeRoleId,
                reportId: ReportId,
                reportType: 'REPORT',
                isEnabled: true
            } as IUserFavorite

            onHandleAddFavorite(accessToken, newFav);

            //this.setState({ userFavorites: newUserFavorites }, () => {
            //    console.log(this.state.userFavorites.length)
            //});

          
        };

        
         

    };
     


    public async componentDidMount() {
        const {
            accessToken,
            onHandleLoadReports,
            Reports,
            ReportsLoaded
        } = this.props;
        this.isLoaded = true;
        if (this.isLoaded) {
            if (!ReportsLoaded) {
                onHandleLoadReports(accessToken);
            }
            this.setState({ filteredReports: Reports });
        }
    }

    public componentDidUpdate(prevProps: IListUserReportsPageProps) {
        const { Reports, userFavoritesReports } = this.props;

        if (prevProps.Reports !== Reports) {
            this.setState({
                filteredReports: Reports
            });
        }

         if (prevProps.userFavoritesReports !== userFavoritesReports) {
            this.setState({
                userFavorites: userFavoritesReports 
            })
    }
        }

    public render() {
        const { Reports, ReportsLoaded } = this.props;
        const {
            noMatchingNames,
            matchedNameExact,
            name,
            filteredReports, userFavorites
        } = this.state;
        return (
            <React.Fragment>
                <h1 style={{ backgroundColor: "white" }}>SSRS Reports:</h1>
                {!ReportsLoaded && <DataLoading dataTitle="Reports" />}
                {ReportsLoaded && (
                    <React.Fragment>
                        <Card Style="width: 48rem; border-radius: 20% center">
                            <CardTitle tag="span">
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Search SSRS Report"
                                    onChange={this.handleInputChange}
                                    valid={matchedNameExact}
                                    invalid={noMatchingNames}
                                />
                                <FormFeedback valid={true}>
                                    {matchedNameExact && (
                                        <span>
                                            Report found! Select to edit
                                            {name}
                                        </span>
                                    )}
                                </FormFeedback>
                                <FormFeedback invalid="true">
                                    {noMatchingNames && (
                                        <span>No Reports match name entered </span>
                                    )}
                                </FormFeedback>
                            </CardTitle>
                            <CardBody>
                                {Reports.length > 0 &&
                                    filteredReports.map((Report: ISqlReport) => (
                                        <div key={Report.id}>
                                            <span onClick={() => this.toggleFavorite(Report.id)} className="mr-2">
                                                {
                                                    userFavorites.find(fav => fav.reportId === Report.id) ?
                                                        <FavoritesIcon />
                                                        :
                                                        <NotFavoritesIcon />
                                                }
                                            </span>
                                            <Link to={paths.sqlReports.concat(Report.id.toString())}>
                                                {Report.displayName}
                                            </Link>
                                        </div>



                                    ))}
                            </CardBody>
                        </Card>

                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {

    return {
        accessToken: state.auth0.accessToken,
        Reports: state.userReports,
        ReportsLoaded: state.loadingReports,
        userFavoritesReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "REPORT") : state.userFavorites,
        userId: state.userProfile.id,
        activeRoleId: state.activeRoleId,
       // userFavorites: state.userFavorites.filter(fav => fav.reportType === "REPORT")
    };
};

const mapDispatchToProps = (dispatch: any) => ({

    onHandleLoadReports: (accessToken: string) => {
        dispatch(handleLoadReports(accessToken));
    },

    onHandleAddFavorite: (accessToken: string, newFav: IUserFavorite) => {             
            dispatch(handleAddFavorite(accessToken, newFav));
        },
     
    onHandleDeleteFavorite: (accessToken: string, id: number) => {
        dispatch(handleDeleteFavorite(accessToken, id));
    },

 
                
});






export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListUserReportsPage);

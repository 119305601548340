import * as React from "react";
import { connect } from "react-redux";
import { 
    Table
} from "reactstrap";
import {
    
    handleLoadEditScheduleReportFormats,    
    handleUpdateScheduleReportFormat
} from "../../actions/editScheduleReportFormats";
import DataLoading from "../../components/organisms/DataLoading";
 import IScheduleReportFormat from "../../models/IScheduleReportFormat";
import { IStore } from "../../models/IStore";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";


export interface IAssignScheduleReportFormatsState {  
    editScheduleReportFormats: IScheduleReportFormat[];
}

export interface IAssignScheduleReportFormatsProps {
    accessToken: string;
    editScheduleReportFormats: IScheduleReportFormat[];
    editScheduleReportFormatsLoaded: boolean;
    scheduleId: number;
    paginatedReports: IPbiPaginatedReport[];
    onHandleUpdateScheduleReportFormat: (
        accessToken: string,
        id: string,
        formatName: string,
        formatValue: boolean          
    ) => void;



    onHandleLoadScheduleReportFormats: (accessToken: string, scheduleId: number) => void;

}

export class AssignScheduleReportFormats extends React.Component<
    IAssignScheduleReportFormatsProps,
    IAssignScheduleReportFormatsState
> {
    public state = {
    
        editScheduleReportFormats: [] as IScheduleReportFormat[]
    };
    constructor(props: IAssignScheduleReportFormatsProps) {
        super(props);
        this.onFormatClick = this.onFormatClick.bind(this);
        this.getReportName = this.getReportName.bind(this);
    } 
     

  

    public onFormatClick = async (event: React.ChangeEvent<HTMLInputElement> )  => {
        const {       
            editScheduleReportFormats
        } = this.state;
        const {
            accessToken,             
            onHandleUpdateScheduleReportFormat
        } = this.props;
        event.preventDefault();

        const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;  
        const name = target.name;
        const id = target.id;

        onHandleUpdateScheduleReportFormat(accessToken, id, name, target.checked );
         
 
    };



    public async componentWillMount() {

        await this.setState(() => ({ editScheduleReportFormats: [] as IScheduleReportFormat[] }));

    }

    public async componentWillUnmount() {
        await this.setState(() => ({ editScheduleReportFormats: [] as IScheduleReportFormat[] }));
    }


    public async componentDidMount() {
        const {
            accessToken,
            scheduleId,
            onHandleLoadScheduleReportFormats,          
            editScheduleReportFormats
        } = this.props;

    
        await onHandleLoadScheduleReportFormats(accessToken, scheduleId);
        await this.setState(() => ({ editScheduleReportFormats }));
    }

    public async componentDidUpdate(prevProps: IAssignScheduleReportFormatsProps) {
        const { editScheduleReportFormats } = this.props;
        if (prevProps.editScheduleReportFormats !== editScheduleReportFormats) {
            await this.setState(() => ({ editScheduleReportFormats }));
        }
    }

    public getReportName(reportId: number) {

        const {
            paginatedReports
        } = this.props;

        let result = '';

        let report = paginatedReports.find(p => p.id === reportId) as IPbiPaginatedReport;

        if (report !== undefined) {


            result = report.displayName;
        }
        return result;
    }






    public render() {
        const {           
            editScheduleReportFormatsLoaded,
            
        } = this.props;
        const {
            editScheduleReportFormats          
        } = this.state;

        return (
            <React.Fragment>
                <h1>Report Formats</h1>
 
                {!editScheduleReportFormatsLoaded && (
                    <DataLoading dataTitle="Schedule Report Formats:" />
                )}
                {editScheduleReportFormatsLoaded && (
                 
                    <Table striped={true} bordered>
                        <thead>
                            <tr>
                                <th>Report Name</th>
                                <th>PDF (.pdf)</th>
                                <th>Microsoft PowerPoint (.pptx)</th>
                                <th>Microsoft Excel (.xlsx)</th>
                            </tr>
                        </thead>
                        <tbody> 
                                   {
                                editScheduleReportFormats.length > 0 &&
                                editScheduleReportFormats.map((fmt: IScheduleReportFormat) => (
                                    <tr key={fmt.id}>
                                        <td>

                                            {
                                                this.getReportName(fmt.pbiPaginatedReportId)

                                            }

                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="inPdf"
                                                checked={fmt.inPdf}
                                                id={fmt.id.toString()}                                               
                                                onChange={this.onFormatClick}  
                                               
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                name="inPowerPoint"
                                                checked={fmt.inPowerPoint}
                                                id={fmt.id.toString()}                                                
                                                onChange={this.onFormatClick}
                                            />

                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                name="inExcel"
                                                checked={fmt.inExcel}
                                                id={fmt.id.toString()}                                              
                                                onChange={this.onFormatClick}
                                            />

                                        </td> 
                                         

                                    </tr>

                                ))}
                             



                        </tbody>
                    </Table>
                )}
                
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        editScheduleReportFormats: state.editScheduleReportFormats,
        editScheduleReportFormatsLoaded: state.loadingEditScheduleReportFormats,
        predicates: state.predicates,
        scheduleReportFormatsLoaded: state.loadingPredicates,
        scheduleId: state.editScheduleDetails.id,
        paginatedReports:state.paginatedReports
        // tslint:disable-next-line:object-literal-sort-keys
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadScheduleReportFormats: (accessToken: string, scheduleId: number) => {
        dispatch(handleLoadEditScheduleReportFormats(accessToken, scheduleId));
    },
   
    onHandleUpdateScheduleReportFormat: (
        accessToken: string,
        id: string,
        formatName: string,
        formatValue: boolean        
    ) => {
        dispatch(handleUpdateScheduleReportFormat(accessToken, id, formatName, formatValue));
    } 
    // tslint:disable-next-line:object-literal-sort-keys
     
  
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignScheduleReportFormats);

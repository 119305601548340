import * as React from "react";

export interface IJumbotronProps {
  fluid?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Jumbotron: React.SFC<IJumbotronProps> = (props: IJumbotronProps) => {
  const { children, fluid, style } = props;
   return (
    <div
          className={fluid ? "jumbotron jumbotron-fluid  bg-transparent" : "jumbotron  bg-transparent" }
          style={style === undefined ? { backgroundColor: "#f8f9fa;" } : style}
    >
      {children}
    </div>
  );
};

export default Jumbotron;

import * as React from "react";
import Graph from "../../assets/icons/noun_chart bar.svg";
import Icon from "./Icon";

const ReportIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Graph} text="Reports" id="Reports" />
    </React.Fragment>
  );
};

export default ReportIcon;



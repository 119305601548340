import * as React from "react";
import { connect } from "react-redux";
import IScheduleRoleUser from "../../models/IScheduleRoleUser";
import { IStore } from "../../models/IStore";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row,
    Badge,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button
} from "reactstrap";
import DataLoading from "../../components/organisms/DataLoading";
import { auto } from "@popperjs/core";
import UsersBadge from "../../components/molecules/UsersBadge";
import { handleSaveDashboardShared } from "../../actions/emailedDashboard";
import { StringLiteral } from "typescript";
import TickIcon from "../molecules/TickIcon";
import IPbiDashboard from "../../models/IPbiDashboard";


export interface IAssignDashboardShareUsersState {

    dashboardShareUserIds: number[];
    filteredUsers: IScheduleRoleUser[];
    showMe: boolean;
    capturedBookmark: string;
    message: string;
    isShared: boolean;
}


export interface IAssignDashboardShareUsersProps {
    accessToken: string;
    roleId: number;
    activeUser: number;
    currentRoleUsers: IScheduleRoleUser[];
    usersLoaded: boolean;
    showDashboardShareModal: boolean;
    pbiDashboardId: number;
    dashboard: IPbiDashboard;

    getBookmarkCallback: () => Promise<string>;

    toggleSelectUserModalCallback: () => void;

    onHandleSaveDashboardShared: (
        accessToken: string,
        pbiDashboardId: number,
        roleId: number,
        userProfileId: number,
        createdBy: number,
        capturedBookmark: string,
        message: string,

    ) => void;
}

class AssignDashboardShareUsers extends React.Component<
    IAssignDashboardShareUsersProps,
    IAssignDashboardShareUsersState
> {
    public state = {
        dashboardShareUserIds: [] as number[],
        filteredUsers: [] as IScheduleRoleUser[],
        showSelectUserModal: false,
        showMe: false,
        capturedBookmark: '',
        message: '',
        isShared: false
    };

    constructor(props: IAssignDashboardShareUsersProps) {
        super(props);
        this.saveDashboardShared = this.saveDashboardShared.bind(this);
        this.messageChange = this.messageChange.bind(this);
    }


    public messageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const message =
            e.target.value === undefined ? "" : (e.target.value as string);
        this.setState(() => ({ message }));
    };


    public saveDashboardShared = async () => {
        const { accessToken, activeUser, roleId, pbiDashboardId, getBookmarkCallback, onHandleSaveDashboardShared } = this.props;
        const { filteredUsers, dashboardShareUserIds, message } = this.state;


        let emails: string = '';

        dashboardShareUserIds.map(userId =>
            emails = emails + filteredUsers.find(u => u.userProfileId === userId)?.emailAddress + ', '
        );

        const bookmark = await (this.props.getBookmarkCallback());

        dashboardShareUserIds.map(userId =>

            onHandleSaveDashboardShared(
                accessToken,
                pbiDashboardId,
                roleId,
                userId,
                activeUser,
                bookmark,
                message
            )
        );


        await this.setState(() => ({ dashboardShareUserIds: [] as number[] }));
        await this.setState(() => ({ message: '' }));

        await this.setState(() => ({ isShared: true }));

        //   this.props.toggleSelectUserModalCallback();
    }

    public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { currentRoleUsers } = this.props;
        const filteredUsers = currentRoleUsers.filter(user =>
            user.emailAddress.toLowerCase().includes(e.target.value.toLowerCase())
        );
        await this.setState(() => ({ filteredUsers }));
    };


    public userCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const {
            accessToken,
            roleId
        } = this.props;
        const {
            dashboardShareUserIds
        } = this.state;

        //e.preventDefault();

        if (e.target.checked) {
            dashboardShareUserIds.push(Number(e.target.value));
            this.setState({ dashboardShareUserIds: dashboardShareUserIds });
        }
        else {
            this.setState({ dashboardShareUserIds: dashboardShareUserIds.filter(user => user != Number(e.target.value)) });
        }


        //if (e.target.checked) {
        //    await onHandleSaveEditRoleUserId(
        //        accessToken,
        //        roleId,
        //        Number(e.target.value)
        //    );
        //} else {
        //    await onHandleDeleteEditRoleUserId(
        //        accessToken,
        //        roleId,
        //        Number(e.target.value)
        //    );
        //}
    };

    public async componentDidMount() {
        const {
            accessToken,
            currentRoleUsers,
            usersLoaded,
            roleId,
            showDashboardShareModal
        } = this.props;

        //var filteredUsers = users.filter(user => user.roleId === roleId);
        this.setState({ filteredUsers: currentRoleUsers });
    }


    public async componentDidUpdate(prevProps: IAssignDashboardShareUsersProps) {

        const { showDashboardShareModal, currentRoleUsers } = this.props;
        const { showMe } = this.state;
        if (prevProps.showDashboardShareModal != showDashboardShareModal) {
            this.setState({ showMe: showDashboardShareModal });
            if (showDashboardShareModal === false) {
                //if close modal form - clean data
                await this.setState(() => ({ isShared: false }));
                await this.setState(() => ({ dashboardShareUserIds: [] as number[] }));
                await this.setState(() => ({ message: '' }));
            } else {

                await this.setState(() => ({ filteredUsers: currentRoleUsers  }));

            }

        } 
    } 


    public render() {
        const { filteredUsers, dashboardShareUserIds, showMe, message, isShared } = this.state;
        const { currentRoleUsers, usersLoaded, dashboard } = this.props;
    
        return (
            <React.Fragment>
                {!usersLoaded && <DataLoading dataTitle="Users " />}


                {usersLoaded && !isShared && (
                    <Modal
                        isOpen={showMe}
                        toggle={this.props.toggleSelectUserModalCallback}
                    >
                        <ModalHeader  >
                            Share Dashboard To:
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col>
                                        {dashboardShareUserIds.map(userId =>
                                            <Badge key={userId} className="badge" >{filteredUsers.find(user => user.userProfileId === userId)?.emailAddress} </Badge>
                                        )
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label for="txtFilter">Message: </Label>
                                        <Input
                                            type="textarea"
                                            maxLength={512}
                                            id="txtMessage"
                                            placeholder="Add a message (optional)"
                                            value={message}
                                            onChange={this.messageChange}
                                            onBlur={
                                                // tslint:disable-next-line:jsx-no-lambda
                                                async (e: React.ChangeEvent<HTMLElement>) => {
                                                    await this.setState(() => ({ message: message.trim() }));

                                                }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: '15px' }}>
                                        <Input
                                            type="text"
                                            id="txtFilter"
                                            onChange={this.txtFilterChange}
                                            placeholder="Search for..."
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ListGroup style={{ height: '500px', overflowY: auto }}>
                                            {filteredUsers.map(user => (
                                                <ListGroupItem key={user.userProfileId}>
                                                    <FormGroup check={true}>
                                                        <Label check={true}>
                                                            <Input
                                                                type="checkbox"
                                                                value={user.userProfileId}
                                                                onChange={this.userCheckboxChange}
                                                                checked={
                                                                    dashboardShareUserIds.find(
                                                                        id => id === user.userProfileId
                                                                    ) !== undefined
                                                                }
                                                            />
                                                            {user.emailAddress}
                                                        </Label>
                                                    </FormGroup>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger"
                                onClick={this.saveDashboardShared}>
                                Send
                            </Button>
                            <Button color="secondary"
                                onClick={this.props.toggleSelectUserModalCallback}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}

                {usersLoaded && isShared && (
                    <Modal
                        isOpen={showMe}
                        toggle={this.props.toggleSelectUserModalCallback}
                    >
                        <ModalHeader  >
                           Send link
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col center>
                                        <div className='shared-dashboard-ok'><TickIcon /></div>
                                        <span className='shared-dashboard-ok'>Link to <b> {dashboard.displayName} </b> sent</span>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary"
                                onClick={
                                    this.props.toggleSelectUserModalCallback
                                }>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    return {
        accessToken: state.auth0.accessToken,
        roleId: state.activeRoleId,
        activeUser: state.userProfile.id,
        currentRoleUsers: state.userRolesWithUserProfiles.filter(user => user.roleId === state.activeRoleId),
        usersLoaded: state.loadingUserRolesWithUserProfiles,
        showDashboardShareModal: ownProps.showDashboardShareModal,
        toggleSelectUserModalCallback: ownProps.toggleSelectUserModalCallback,
        getBookmarkCallback: ownProps.getBookmarkCallback,
        pbiDashboardId: ownProps.pbiDashboardId,
        dashboard: state.userDashboards.find(dash => {
            return dash.id === parseInt(ownProps.pbiDashboardId, 10);
        }) as IPbiDashboard

    };
};

const mapDispatchToProps = (dispatch: any) => ({
    //onHandleLoadUsers: (accessToken: string) => {
    //    dispatch(handleLoadUserProfiles(accessToken));
    //} 
    onHandleSaveDashboardShared: (
        accessToken: string,
        pbiDashboardId: number,
        roleId: number,
        userProfileId: number,
        createdBy: number,
        capturedBookmark: string,
        message: string,
    ) => {
        dispatch(
            handleSaveDashboardShared(
                accessToken,
                pbiDashboardId,
                roleId,
                userProfileId,
                createdBy,
                capturedBookmark,
                message
            )
        );
    }


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignDashboardShareUsers);

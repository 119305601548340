import { AnyAction } from "redux";
import {
  ADD_EDIT_SCHEDULE_USER_ID,
  CLEAR_EDIT_SCHEDULE_USER_IDS,
  LOAD_EDIT_SCHEDULE_USER_IDS,
  REMOVE_EDIT_SCHEDULE_USER_ID
} from "../actions/editScheduleUserIds";

import IScheduleRoleUserIds from "../models/IScheduleRoleUserIds";



export const editScheduleUserIds = (
    state = [] as IScheduleRoleUserIds[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_EDIT_SCHEDULE_USER_ID:
          const newState: IScheduleRoleUserIds[] = state.slice();
          
          newState.push(action.id as IScheduleRoleUserIds);
      return newState;
      case REMOVE_EDIT_SCHEDULE_USER_ID:
          let result = [] as IScheduleRoleUserIds[];

          let deletedItem = (action.id as IScheduleRoleUserIds);

          for (var i = 0; i < state.length - 1; i++) {



              if (state[i].scheduleId === deletedItem.scheduleId && state[i].roleId === deletedItem.roleId && state[i].userId === deletedItem.userId) {
              }
              else {

                  result.push(state[i]);
              }
          }

          return result as IScheduleRoleUserIds[];


          //return Object.assign(
          //    [],

            
      //    state.filter((id: IScheduleRoleUserIds) => (id.scheduleId !== (action.id as IScheduleRoleUserIds).scheduleId && id.roleId !== (action.id as IScheduleRoleUserIds).roleId && id.userId !== (action.id as IScheduleRoleUserIds).userId))
      //) as IScheduleRoleUserIds[];
    case LOAD_EDIT_SCHEDULE_USER_IDS:
          return action.ids as IScheduleRoleUserIds[];
    case CLEAR_EDIT_SCHEDULE_USER_IDS:
      return [] as number[];
    default:
      return state;
  }
};

export default editScheduleUserIds;

import * as React from "react";
import { Link } from "react-router-dom";
 
const PageFooter = () => {
    return (
        <React.Fragment>          
            <footer className="footer fixed-bottom text-left" style={{ margin: "0px 0px 150px 0px", paddingLeft:'400px' }}>
                <p >
                    <span style={{ paddingRight: "15px" }}><Link to="https://www.travelctm.com/global-privacy-policy/"><small>Privacy Policy</small></Link></span>
                    <span><Link to="https://www.travelctm.com/terms-conditions/"><small>Terms</small></Link></span>
                </p>
                <p className="text-muted">
                    <small>&copy; {new Date().getFullYear()} Corporate Travel Management</small>
                </p>             

              
            </footer>
        </React.Fragment>
    );
};

export default PageFooter;

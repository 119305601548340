import IScheduleReportFormat from "../models/IScheduleReportFormat";
import { fetchSecureData } from "../utils";

export const getScheduleReportFormats = async (
  accessToken: string,
  scheduleId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/PbiPaginatedReport/GetPbiPaginatedReportsFormatsForScheduleId?scheduleId=${scheduleId}`,
      accessToken
    );
    const scheduleReportFormats = ((await data.json()) as any)
      .value as IScheduleReportFormat[];
    if (scheduleReportFormats !== undefined) {
      return scheduleReportFormats;
    } else {
      return [] as IScheduleReportFormat[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/PbiPaginatedReportController/GetPbiPaginatedReportsFormatsForScheduleId?scheduleId=${scheduleId}`,
      err
    );
  }
  return [] as IScheduleReportFormat[];
};

export default getScheduleReportFormats;

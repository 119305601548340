import getClient from "api/getClient";
import * as Redux from "redux";
import { getInitialData } from "../api";
import getDefaultRolePredicateClient from "../api/getDefaultRolePredicateClient";
import IUserProfile from "../models/IUserProfile";
import { setActiveDashboardDefaultClient } from "./activeDashboardDefaultClient";
import { setActiveDashboardDefaultPredicateId } from "./activeDashboardDefaultPredicate";
import { setActiveRoleId } from "./activeRole";
import { setActiveUserProfile } from "./activeUserProfile";
import { handleLoadCurrencyCodes } from "./currencyCodes";
import { handleLoadFyStartMonths } from "./fyStartMonths";
import { handleLoadGroups } from "./groups";
import { setDataLoaded } from "./loading";
import { handleLoadPredicates } from "./predicates";
import { setPbiAccessToken } from "./tokenData";
import { handleLoadUserDashboards } from "./userDashboards";
import { handleLoadUserFavorites } from "./userFavorites";
import { handleLoadUserPaginatedReports } from "./userPaginatedReports";
import { handleLoadUserGroups } from "./userGroups";
import { handleLoadUserReports } from "./userReports";
import { handleLoadUserRoles } from "./userRoles";
import { handleLoadScheduleFrequencies } from "./scheduleFrequencies";
import { handleLoadSchedulePeriods } from "./schedulePeriods";
import { handleLoadScheduleEvents } from "./scheduleEvents";
import { handleLoadUserRolesWithUserProfiles } from "./userRolesWithUserProfiles";
import { handleLoadUserSchedules } from "./userSchedules";
import { handleLoadEmailedDashboard} from "./emailedDashboard";
import { handleSaveRole } from "./activeRole";
 

export const handleLoadUserData = (
    userId: number,
    userProfiles: IUserProfile[]
) => {
    return async (dispatch: Redux.Dispatch) => {
        const user = await userProfiles.find(usr => usr.id === userId);
        if (user === undefined) {
            // tslint:disable-next-line:no-console
            console.log(
                "This user not found, or current user does not have access to edit this user"
            );
        } else {
            dispatch(setActiveUserProfile(user));
        }
    };
};

export const handleLoadInitialData = (
    accessToken: string,
    emailAddress: string
) => {
    return async (dispatch: Redux.Dispatch<any>) => {
        const { user, tokenData } = await getInitialData(accessToken, emailAddress);

        await dispatch(handleLoadFyStartMonths(accessToken));
        await dispatch(handleLoadCurrencyCodes(accessToken));
        await dispatch(handleLoadGroups(accessToken));
        await dispatch(handleLoadPredicates(accessToken));

        await dispatch(setActiveUserProfile(user));
         await dispatch(handleLoadEmailedDashboard(accessToken));
        await dispatch(handleLoadUserRoles(accessToken, user.id));
        await dispatch(handleLoadUserDashboards(accessToken, user.id));
        await dispatch(handleLoadUserFavorites(accessToken, user.id));

        await dispatch(handleLoadUserPaginatedReports(accessToken, user.id));

      //  await dispatch(handleLoadUserSchedules(accessToken, user.id));


        await dispatch(handleLoadUserReports(accessToken, user.id));
        await dispatch(handleLoadUserGroups(accessToken, user.id));

        await dispatch(setPbiAccessToken(tokenData));

        await dispatch(handleLoadScheduleFrequencies(accessToken));
        await dispatch(handleLoadSchedulePeriods(accessToken));
        await dispatch(handleLoadScheduleEvents(accessToken)); 

        await dispatch(handleLoadUserRolesWithUserProfiles(accessToken, user.id));

        await dispatch(handleLoadEmailedDashboard(accessToken));
        
        await dispatch(setDataLoaded(true));
    };
};

export const handleSetActiveRolePredicateClient = (
    accessToken: string,
    roleId: number,
    userId: number
) => {
    return async (dispatch: Redux.Dispatch<any>) => {

        await dispatch(handleSaveRole(accessToken, roleId, userId))
        await dispatch(setActiveRoleId(roleId));
        //
        // get the default RolePredicateClient mapping for the role
        const rolePredicateClient = await getDefaultRolePredicateClient(
            accessToken,
            roleId
        );
        if (rolePredicateClient) {
            // set the active Dashboard Default Client
            const client = await getClient(accessToken, rolePredicateClient.clientId);
            await dispatch(setActiveDashboardDefaultClient(client));
            // set the active Dashboard Default Predicate ID
            await dispatch(
                setActiveDashboardDefaultPredicateId(rolePredicateClient.predicateId)
            );
        } else {
            console.error(
                `Cannot find default predicate or default client values for currently selected roleId: ${roleId}`
            );
        }
    };
};

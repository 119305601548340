import * as React from "react";
import Manage from "../../assets/icons/arrow-repeat.svg";
import Icon from "./Icon";

const UpdateIcon = () => {
    var smallStyle = {
        height: 18,
        margin: "0px 8px 0px 0px" 
    }; 

  return (
    <React.Fragment>
          <Icon icon={Manage} text="Update" id="updateIcon" style={smallStyle} tooltip={true}  />
    </React.Fragment>
  );
};

export default UpdateIcon;

import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const executeSchedule = async (
    accessToken: string,
    eventId: number,
    scheduleId: number,
    userId: number
) => {
    try { 
        const url = 'https://ctmsmartdatagenerateschedulefunctionprod.azurewebsites.net/api/Function1?eventId=' + eventId + '&scheduleId=' + scheduleId + '&userId=' + userId + '&code=ItDOaV_kuuLjPbv-16FQWaCGUG3qTJk7qC1PGqnVgtDNAzFuCBn3Vw==';      
         
        const data = await fetch(
           url, {
               method: 'get',
               mode: 'cors',
               headers: {
                   'access-control-allow-origin': '*',
                   'accept': 'application/json',
                   'content-type': 'application/json'
               }
           })
        const message = ((await data.json()) as any).value as string;
        if (message !== undefined) {
            return message;
        } else {
            return "";
        } 
 
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        "https://ctmsmartdatagenerateschedulefunctionprod.azurewebsites.net/api/Function1?eventId=" + eventId + "&scheduleId=" + scheduleId + "&userId=" + userId + "&code=ItDOaV_kuuLjPbv-16FQWaCGUG3qTJk7qC1PGqnVgtDNAzFuCBn3Vw==",
      err
    );
    }
    return "";
 
};

export default executeSchedule;

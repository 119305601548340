import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import ISchedulePeriod from "../../models/ISchedulePeriod";
import { IStore } from "../../models/IStore";

export interface IAddSchedulePeriodProps {
  schedulePeriods: ISchedulePeriod[];
  schedulePeriodId: number;
  callback: (schedulePeriodId: number) => void;
}

export interface IAddSchedulePeriodState {
  schedulePeriodId: number;
}

class AddSchedulePeriod extends React.Component<IAddSchedulePeriodProps> {
  public state = {
    schedulePeriodId: 0
  };
  constructor(props: IAddSchedulePeriodProps) {
    super(props);
    this.schedulePeriodIdChange = this.schedulePeriodIdChange.bind(this);
  }
  public schedulePeriodIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { callback } = this.props;
    e.preventDefault();
    const schedulePeriodId =
      e.target.value === undefined ? 1 : Number(e.target.value as string);
    this.setState(() => ({ schedulePeriodId }));
    callback(schedulePeriodId);
  };
  public async componentDidUpdate(prevProps: IAddSchedulePeriodProps) {
    const { schedulePeriodId } = this.props;
    if (
      schedulePeriodId !== prevProps.schedulePeriodId &&
      schedulePeriodId !== undefined &&
      schedulePeriodId !== 0
    ) {
      await this.setState(() => ({ schedulePeriodId }));
    }
  }
  public async componentDidMount() {
    const { schedulePeriodId } = this.props;
    if (schedulePeriodId !== undefined && schedulePeriodId !== 0) {
      await this.setState(() => ({ schedulePeriodId }));
    }
  }
  public render() {
    const { schedulePeriods } = this.props;
    const { schedulePeriodId } = this.state;
    return (
      <FormGroup>
        <Label for="schedulePeriodId">Schedule Period:</Label>
        <Input
          id="schedulePeriodId"
          type="select"
          placeholder="schedulePeriod"
          value={schedulePeriodId.toString()}
          onChange={this.schedulePeriodIdChange}
            >
         <option value='0'>---</option>
          {schedulePeriods.map(cc => (
            <option key={cc.id} value={cc.id}>
              {cc.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    callback: ownProps.callback,
    schedulePeriodId: ownProps.schedulePeriodId,
    schedulePeriods: state.schedulePeriods
  };
};

export default connect(mapStateToProps)(AddSchedulePeriod);

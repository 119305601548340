import * as Redux from "redux";
import getScheduleFrequencies from "../api/getScheduleFrequencies";
import IScheduleFrequency from "../models/IScheduleFrequency";
import { setScheduleFrequenciesLoaded } from "./loadingScheduleFrequencies";

export const ADD_SCHEDULE_FREQUENCY = "ADD_SCHEDULE_FREQUENCY";
export const REMOVE_SCHEDULE_FREQUENCY = "REMOVE_SCHEDULE_FREQUENCY";
export const UPDATE_SCHEDULE_FREQUENCY = "UPDATE_SCHEDULE_FREQUENCY";
export const LOAD_SCHEDULE_FREQUENCYS = "LOAD_SCHEDULE_FREQUENCYS";

export const addScheduleFrequency = (scheduleFrequency: IScheduleFrequency) => {
    return {
        scheduleFrequency,
        type: ADD_SCHEDULE_FREQUENCY
    };
};

export const removeScheduleFrequency = (id: number) => {
    return {
        id,
        type: REMOVE_SCHEDULE_FREQUENCY
    };
};

//export const updateScheduleFrequency = (scheduleFrequency: IScheduleFrequency) => {
//    return {
//        scheduleFrequency,
//        type: UPDATE_SCHEDULE_FREQUENCY
//    };
//};

export const loadScheduleFrequencies = (scheduleFrequencies: IScheduleFrequency[]) => {
    return {
        scheduleFrequencies,
        type: LOAD_SCHEDULE_FREQUENCYS
    };
};

export const handleLoadScheduleFrequencies = (accessToken: string) => {
    return async (dispatch: Redux.Dispatch) => {
        const scheduleFrequencies = await getScheduleFrequencies(accessToken);
        if (scheduleFrequencies !== undefined) {
            if (scheduleFrequencies.length > 0) {
                scheduleFrequencies.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                );
                dispatch(loadScheduleFrequencies(scheduleFrequencies));
            }
            dispatch(setScheduleFrequenciesLoaded(true));
        }
    };
};

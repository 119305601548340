import * as React from "react";
import { Router } from "react-router";
import PageBody from "./components/templates/PageBody";
import PageBodyRoutes from "./components/templates/PageBodyRoutes";
import PageContainer from "./components/templates/PageContainer";
import PageHeader from "./components/templates/PageHeader";
import PageHeaderRoutes from "./components/templates/PageHeaderRoutes";
import history from "./routes/history";

class App extends React.Component {
    public render() {

        const DebugRouter = ({ children }: { children: any }) => {

            const location = window.location;
            //if (process.env.NODE_ENV === 'development') {
            //console.log(
            //    `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location)}`,
            //)
            //}

            return children
        }

        return (
            <Router history={history}>
                <DebugRouter>
                    <PageContainer>
                        <PageHeader rowStyle={{ padding: 0, backgroundColor: '#141B22' }}>
                            <PageHeaderRoutes />
                        </PageHeader>
                        <PageBody>
                            <PageBodyRoutes />
                        </PageBody>
                    </PageContainer>
                </DebugRouter>
            </Router>
        );
    }
}

export default App;

import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils";

export const saveSchedulePaginatedReport = async (
    accessToken: string,
    scheduleId: number,
    paginatedReportId: number,
) => {
    try {
        const data = await fetchSecureData(
            "POST",
            `/api/SchedulePaginatedReport/Add?scheduleId=${scheduleId}&paginatedReportId=${paginatedReportId}`,
            accessToken
        );
        const schedulePaginatedReport = ((await data.json()) as any).value as IPbiPaginatedReport;
        return schedulePaginatedReport;
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
            `Fetch error for /api/SchedulePaginatedReport/Add?scheduleId=${scheduleId}&&paginatedReportId=${paginatedReportId}`
        );
    }
    return {} as IPbiPaginatedReport;
};

export default saveSchedulePaginatedReport;

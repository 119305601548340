import * as React from "react";
import Logo from "../../assets/icons/CTM Data Hub White.svg";
import Icon from "./Icon";

const HeaderLogoIcon = () => {
    var iconStyle = {
        height: 30 
    };
    return (
        <React.Fragment>
            <div>
                <Icon icon={Logo} text="Logo" id="logo" style={iconStyle} />
            </div>
        </React.Fragment>
    );
};

export default HeaderLogoIcon;

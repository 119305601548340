import * as Redux from "redux";
import getSchedulePaginatedReports from "../api/getSchedulePaginatedReports";
import removeSchedulePaginatedReport from "../api/removeSchedulePaginatedReport";
import saveSchedulePaginatedReport from "../api/saveSchedulePaginatedReport";

import { setEditSchedulePaginatedReportsLoaded } from "./loadingEditSchedulePaginatedReports";

export const ADD_EDIT_SCHEDULE_PAGINATED_REPORT_ID = "ADD_EDIT_SCHEDULE_PAGINATED_REPORT_ID";
export const REMOVE_EDIT_SCHEDULE_PAGINATED_REPORT_ID = "REMOVE_EDIT_SCHEDULE_PAGINATED_REPORT_ID";
export const LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_IDS = "LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_IDS";
export const CLEAR_EDIT_SCHEDULE_PAGINATED_REPORT_IDS = "CLEAR_EDIT_SCHEDULE_PAGINATED_REPORT_IDS";

export const addEditSchedulePaginatedReportId = (id: number) => {
    return {
        id,
        type: ADD_EDIT_SCHEDULE_PAGINATED_REPORT_ID
    };
};

export const removeEditSchedulePaginatedReportId = (id: number) => {
    return {
        id,
        type: REMOVE_EDIT_SCHEDULE_PAGINATED_REPORT_ID
    };
};

export const loadEditSchedulePaginatedReportIds = (ids: number[]) => {
    return {
        ids,
        type: LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_IDS
    };
};

export const clearEditSchedulePaginatedReportIds = () => {
    return {
        type: CLEAR_EDIT_SCHEDULE_PAGINATED_REPORT_IDS
    };
};

export const handleLoadEditSchedulePaginatedReportIds = (
    accessToken: string,
    scheduleId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const schedulePaginatedReports = await getSchedulePaginatedReports(accessToken, scheduleId);
        if (schedulePaginatedReports === undefined) {
            dispatch(clearEditSchedulePaginatedReportIds());
            dispatch(setEditSchedulePaginatedReportsLoaded(false));
        } else {
            if (schedulePaginatedReports.length > 0) {
                dispatch(
                    loadEditSchedulePaginatedReportIds(
                        schedulePaginatedReports.map(paginatedReport => paginatedReport.id)
                    )
                );
            }
            dispatch(setEditSchedulePaginatedReportsLoaded(true));
        }
    };
};

export const handleSaveEditSchedulePaginatedReportId = (
    accessToken: string,
    scheduleId: number,
    paginatedReportId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        dispatch(setEditSchedulePaginatedReportsLoaded(false));
        await saveSchedulePaginatedReport(accessToken, scheduleId, paginatedReportId);
        dispatch(addEditSchedulePaginatedReportId(paginatedReportId));
        dispatch(setEditSchedulePaginatedReportsLoaded(true));
    };
};

export const handleDeleteEditSchedulePaginatedReportId = (
    accessToken: string,
    scheduleId: number,
    paginatedReportId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        dispatch(setEditSchedulePaginatedReportsLoaded(false));
        await removeSchedulePaginatedReport(accessToken, scheduleId, paginatedReportId);
        dispatch(removeEditSchedulePaginatedReportId(paginatedReportId));
        dispatch(setEditSchedulePaginatedReportsLoaded(true));
    };
};

import IPaginatedReportParameter from "../models/IPaginatedReportParameter";
import { fetchSecureData } from "../utils";

export const getPaginatedReportParameters = async (
  accessToken: string,
  reportId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/PbiPaginatedReport/GetPaginatedReportParametersForReport?reportId=${reportId}`,
      accessToken
    );
    const reportParameters = ((await data.json()) as any)
      .value as IPaginatedReportParameter[];
    if (reportParameters !== undefined) {
      return reportParameters;
    } else {
      return [] as IPaginatedReportParameter[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/PaginatedReportParameter/GetPaginatedReportParametersForReport?reportId=${reportId}`,
      err
    );
  }
  return [] as IPaginatedReportParameter[];
};

export default getPaginatedReportParameters;

import { AnyAction } from "redux";
import {
//  ADD_SCHEDULE_PERIOD,
  LOAD_SCHEDULE_PERIODS,
  //REMOVE_SCHEDULE_PERIOD,
  //UPDATE_SCHEDULE_PERIOD
} from "../actions/schedulePeriods";
import ISchedulePeriod from "../models/ISchedulePeriod";

export const schedulePeriods = (
  state = [] as ISchedulePeriod[],
  action: AnyAction
) => {
  switch (action.type) {
    //case ADD_SCHEDULE_PERIOD:
    //  const newState: ISchedulePeriod[] = state.slice();
    //  newState.push(action.schedulePeriod as ISchedulePeriod);
    //  return newState;
    //case REMOVE_SCHEDULE_PERIOD:
    //  return Object.assign(
    //    {},
    //    state.filter((cc: ISchedulePeriod) => cc.id !== (action.id as number))
    //  );
    //case UPDATE_SCHEDULE_PERIOD:
    //  return state.map((cc: ISchedulePeriod) => {
    //    if (cc.id === (action.schedulePeriod as ISchedulePeriod).id) {
    //      return action.schedulePeriod as ISchedulePeriod;
    //    }
    //    return cc;
    //  });

    case LOAD_SCHEDULE_PERIODS:
      return action.schedulePeriods as ISchedulePeriod[];
    default:
      return state;
  }
};

export default schedulePeriods;

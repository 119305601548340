import * as Redux from "redux";
import getDashboards from "../api/getDashboards";
import saveDashboard from "../api/saveDashboard";
 
import saveUpdatedDashboard from "../api/saveUpdatedDashboard";
import { removeDashboard as deleteDashboard } from "../api/removeDashboard";
import IPbiDashboard from "../models/IPbiDashboard";
 
import { setDashboardsLoaded } from "./loadingDashboards";
 

export const ADD_DASHBOARD = "ADD_DASHBOARD";
export const REMOVE_DASHBOARD = "REMOVE_DASHBOARD";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const LOAD_DASHBOARDS = "LOAD_DASHBOARDS";
 
export const addDashboard = (dashboard: IPbiDashboard) => {
  return {
    dashboard,
    type: ADD_DASHBOARD
  };
};

export const removeDashboard = (id: number) => {
  return {
    id,
    type: REMOVE_DASHBOARD
  };
};

export const updateDashboard = (dashboard: IPbiDashboard) => {
  return {
    dashboard,
    type: UPDATE_DASHBOARD
  };
};

export const loadDashboards = (dashboards: IPbiDashboard[]) => {
  return {
    dashboards,
    type: LOAD_DASHBOARDS
  };
};
 

export const handleSaveDashboard = (
  accessToken: string,
  name: string,
  displayName: string,
  groupId: string,
  reportId: string,
  dashboardViewerVersion:number,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const dashboard = await saveDashboard(
      accessToken,
      name,
      displayName,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    );
    if (dashboard !== null && dashboard !== undefined) {
      dispatch(addDashboard(dashboard));
    }
  };
};

export const handleUpdateDashboard = (
  accessToken: string,
  dashboard: IPbiDashboard
) => {
  return async (dispatch: Redux.Dispatch) => {
    const dsh = await saveUpdatedDashboard(accessToken, dashboard);
    if (dsh !== null && dsh !== undefined) {
      dispatch(updateDashboard(dashboard));
    }
  };
};

export const handleDeleteDashboard = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteDashboard(accessToken, id);
    if (result === 200) {
      dispatch(removeDashboard(id));
    }
  };
};

export const handleLoadDashboards = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const dashboards = await getDashboards(accessToken);
    if (dashboards !== undefined) {
      if (dashboards.length > 0) {
        dashboards.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadDashboards(dashboards));
      }
      dispatch(setDashboardsLoaded(true));
    }
  };
};
 
 

import ISchedule from "../models/ISchedule";
import { fetchSecureData } from "../utils";

export const saveSchedule = async (
  accessToken: string,
  name: string,
  isActive: boolean,
  isEnabled: boolean,
  userId: number
) => {
  try {
    const data = await fetchSecureData(
      "POST",
        `/api/Schedule/Add?name=${encodeURIComponent(name)}&isActive=${isActive}&isEnabled=${isEnabled}&userId=${userId}`,
      accessToken
    );
    const schedule = ((await data.json()) as any).value as ISchedule;
    return schedule;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/Schedule/Add?name=${name}&isActive=${isActive}&isEnabled=${isEnabled} `,
      err
    );
  }
  return {} as ISchedule;
};

export default saveSchedule;

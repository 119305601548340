import * as Redux from "redux";
import getBookmarks from "../api/getBookmarks";
import saveBookmark from "../api/saveBookmark";
import saveUpdatedBookmark from "../api/saveUpdatedBookmark";
import { removeBookmark as deleteBookmark } from "../api/removeBookmark";
import IPbiBookmark from "../models/IPbiBookmark";
import { setBookmarksLoaded } from "./loadingBookmarks";

export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";
export const LOAD_BOOKMARKS = "LOAD_BOOKMARKS";

export const addBookmark = (bookmark: IPbiBookmark) => {
    return {
        bookmark,
        type: ADD_BOOKMARK
    };
};

export const removeBookmark = (id: number) => {
    return {
        id,
        type: REMOVE_BOOKMARK
    };
};

export const updateBookmark = (bookmark: IPbiBookmark) => {
    return {
        bookmark,
        type: UPDATE_BOOKMARK
    };
};

export const loadBookmarks = (bookmarks: IPbiBookmark[]) => {
    return {
        bookmarks,
        type: LOAD_BOOKMARKS
    };
};

export const handleSaveBookmark = (
    accessToken: string,
    bookmark: IPbiBookmark
) => {
    return async (dispatch: Redux.Dispatch) => {

        const bkmrk = await saveBookmark(
            accessToken,
            bookmark
        );

        if (bkmrk !== null && bkmrk !== undefined) {
            dispatch(addBookmark(bkmrk));
        }
    };
};

export const handleUpdateBookmark = (
    accessToken: string,
    bookmark: IPbiBookmark
) => {
    return async (dispatch: Redux.Dispatch) => {
        const bkmrk = await saveUpdatedBookmark(accessToken, bookmark);
        if (bkmrk !== null && bkmrk !== undefined) {
            dispatch(updateBookmark(bookmark));
        }
    };
};

export const handleDeleteBookmark = (accessToken: string, id: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const result = await deleteBookmark(accessToken, id);
        if (result === 200) {
            dispatch(removeBookmark(id));
        }
    };
};

export const handleLoadBookmarks = (accessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const bookmarks = await getBookmarks(accessToken, roleId, userProfileId, pbiDashboardId);
        if (bookmarks !== undefined) {
            dispatch(loadBookmarks(bookmarks));
        }
        dispatch(setBookmarksLoaded(true));
    }
};


import * as React from "react";
import bgImage from "../../assets/images/ctm-bg1-min1.svg";
import Container from "../atoms/Container";

export interface IPageContainerProps {
  fluid?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  id?: string;
}
const PageContainer: React.SFC<IPageContainerProps> = (
  props: IPageContainerProps
) => {
  const { children, fluid, id, style } = props;
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
                  backgroundPositionX: `right`,
                  backgroundRepeat: `no-repeat`,
                  backgroundSize: `auto`,
          // height: "100vh",
          minHeight: "100vh"
        }}
      >
        <Container
          fluid={fluid === undefined ? true : fluid}
          style={style === undefined ? { width: "100vw" } : style}
          id={id}
        >
          {children}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageContainer;

import { fetchSecureData } from "../utils";

export const removeSchedulePaginatedReport = async (accessToken: string, scheduleId: number, paginatedReportId: number) => {
    try {
        const data = await fetchSecureData(
            "DELETE",
            `/api/SchedulePaginatedReport/Remove?scheduleId=${scheduleId}&paginatedReportId=${paginatedReportId}`,
            accessToken
        );
        const result = await data.json();
        return result.statusCode;
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for /api/SchedulePaginatedReport/Remove?scheduleId=${scheduleId}&reportId=${paginatedReportId}`, err);
    }
    return 404;
};

export default removeSchedulePaginatedReport;

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Form,
    Row,
    Col,
    Label,
    FormGroup
} from "reactstrap";
import {
    handleDeleteSchedule as handleRemoveScheduleProfile,
    handleUpdateSchedule
} from "../../actions/schedules";
import AddTextField from "../../components/molecules/AddTextField";
import AddTextFieldForDate from "../../components/molecules/AddTextFieldForDate";
import { IStore } from "../../models/IStore";
import ISchedule from "../../models/ISchedule";
import { validateDate, validateNumber, validateDateWithFormat } from "../../utils";
import AddScheduleFrequency from "../../components/molecules/AddScheduleFrequency";
import AddSchedulePeriod from "../../components/molecules/AddSchedulePeriod";
import AddScheduleEvent from "../../components/molecules/AddScheduleEvent";
import { format, parse, addYears, isDate, isValid } from 'date-fns';
import IRole from "../../models/IRole";
import ICurrencyCode from "../../models/ICurrencyCode";


export interface IEditScheduleFrequencyProps {
    accessToken: string;
    userId: number;
    schedule: ISchedule;
    onHandleUpdateSchedule: (accessToken: string, schedule: ISchedule) => void;
    onHandleDeleteSchedule: (accessToken: string, id: number) => void;
    currencyCodes: ICurrencyCode[];
    activeUserRole: IRole;
}

export interface IEditScheduleFrequencyState {
    schedule: ISchedule;
    lastRunDateDayOfWeek: string;
    firstRunDateDayOfWeek: string;
    currency: ICurrencyCode;
}

class EditScheduleFrequency extends React.Component<
    IEditScheduleFrequencyProps,
    IEditScheduleFrequencyState
> {
    public state = {
        schedule: {} as ISchedule,
        lastRunDateDayOfWeek: "",
        firstRunDateDayOfWeek: "",
        currency: {} as ICurrencyCode
    };


    constructor(props: IEditScheduleFrequencyProps) {
        super(props);

        this.delete = this.delete.bind(this);
        this.readyToSave = this.readyToSave.bind(this);
        this.save = this.save.bind(this);
        this.getFormattedDateStr = this.getFormattedDateStr.bind(this);
        this.getOriginalDateStr = this.getOriginalDateStr.bind(this);
    }


    public delete = (e: React.MouseEvent) => {
        const { accessToken, schedule, onHandleDeleteSchedule } = this.props;
        // do delete
        onHandleDeleteSchedule(accessToken, schedule.id);
        //     history.push(paths.home);
    };


    public readyToSave = () => {

        const { schedule } = this.state;

        if (schedule.lastRunDate < schedule.firstRunDate) {
            return false; 
        }

        return true;
    };


    public save = () => {
        const { accessToken, onHandleUpdateSchedule, userId } = this.props;
        const { schedule } = this.state;
        schedule.updatedBy = userId;

        schedule.firstRunDate = this.getOriginalDateStr(schedule.firstRunDate);
        schedule.lastRunDate = this.getOriginalDateStr(schedule.lastRunDate);

        if (this.readyToSave()) {
            // do save
            onHandleUpdateSchedule(accessToken, schedule);
        } 
    };

    public componentWillMount() {
        const {
            schedule
        } = this.props;

    }

    public async componentDidMount() {
        const {
            schedule,
            activeUserRole,
            currencyCodes } = this.props;


      

        if (activeUserRole === undefined) {

            const currency = (currencyCodes.find(
                cc => cc.id === 1
            ) as ICurrencyCode);

            throw new Error(
                "At least one role has not been defined, so default CurrencyCode to AUD"
            );
        } else {
            const currency = (currencyCodes.find(
                cc => cc.id === activeUserRole.currencyCodeId
            ) as ICurrencyCode);


            await this.setState(() => ({ currency })); 

            schedule.firstRunDate = this.getFormattedDateStr(schedule.firstRunDate);
            schedule.lastRunDate = this.getFormattedDateStr(schedule.lastRunDate);

            

            // schedule.firstRunDate = stringToDate(schedule.firstRunDate,"yyyy/MM/dd","/" )
            await this.setState(() => ({ schedule }));
        }

    }

    public async componentDidUpdate(prevProps: IEditScheduleFrequencyProps) {
        const { schedule } = this.props;

        if (prevProps.schedule !== schedule) {
            await this.setState(() => ({ schedule }));
        }
        else {
            if (schedule.scheduleFrequencyId > 0 && schedule.scheduleFrequencyDays === 0) {
                schedule.scheduleFrequencyDays = 1;
                await this.setState(() => ({ schedule }));
            }
        }
    }


    public componentWillUnmount() {
        this.save();
    }

    public getOriginalDateStr(dateStr: string) {
        const { currency } = this.state;
        var delemeter = '/';
        if (dateStr.includes("-") ){
            delemeter = '-';
        }

        var formatLowerCase = currency.dateFormat.toLowerCase();
        var formatItems = formatLowerCase.split(delemeter);
        var dateItems = dateStr.split(delemeter);

        var monthIndex: number = formatItems.indexOf("mm") ;
        var dayIndex: number = formatItems.indexOf("dd");
        var yearIndex: number = formatItems.indexOf("yyyy");
         
        var result = '';

        var dateItems = dateStr.split("/");
        var year = dateItems[yearIndex];
        var month = dateItems[monthIndex];
        var day = dateItems[dayIndex];

            result = [
                year,
                month,
                day
        ].join('-');

        return result;
    }




    public getFormattedDateStr(dateStr: string) {
        const { currency } = this.state;

        if (dateStr === undefined) return '';
        
        var result = '';
        var delemeter = '/';
        if (dateStr.includes("-")) {
            delemeter = '-';
        } 

        var dateItems = dateStr.split(delemeter);
        var year = parseInt(dateItems[0]);
        var month = parseInt(dateItems[1]);
        var day = parseInt(dateItems[2]);


        if (currency.dateFormat === 'MM/dd/yyyy') { 
            result = [
                month,
                day,                
                year
            ].join('/');
        }

        if (currency.dateFormat === 'dd/MM/yyyy') {
            result = [
                day,
                month,
                year
            ].join('/');
        }

        if (currency.dateFormat === 'yyyy-MM-dd') {
            result = [
                year,
                month,
                day
            ].join('-');
        }





        return result;

    }


    public render() {
        const { schedule, currency } = this.state;
        return (
            <Card style={{ width: "100%" }}>
                <CardBody>
                    <Form>
                        <Row>
                            <Col>
                                <AddScheduleFrequency
                                    scheduleFrequencyId={schedule.scheduleFrequencyId}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(cc: number) => {
                                        schedule.scheduleFrequencyId = cc;
                                        this.setState(() => ({ schedule: schedule }));
                                    }}
                                />
                            </Col>
                            <Col>
                                {schedule.scheduleFrequencyId !== 4 && <AddTextField
                                    noDuplicates={false}
                                    isValid={validateNumber}
                                    title="Every:"
                                    existingValues={[] as string[]}
                                    value={schedule.scheduleFrequencyDays === undefined ? "" : schedule.scheduleFrequencyDays.toString()}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(days: string) => {
                                        schedule.scheduleFrequencyDays = parseInt(days);
                                        this.setState(() => ({ schedule: schedule }));
                                    }
                                    }
                                />
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <FormGroup>
                                    <AddTextFieldForDate
                                        noDuplicates={false}
                                        isValid={validateDateWithFormat}
                                        dateFormat={currency.dateFormat}
                                        title={"Start Date (" + currency.dateFormat + ")"}
                                        value={schedule.firstRunDate }
                                        existingValues={[] as string[]}
                                        // tslint:disable-next-line:jsx-no-lambda
                                        callback={async (fistrundate: string) => {
                                            schedule.firstRunDate = fistrundate ;
                                            this.setState(() => ({ schedule: schedule }));
                                            this.readyToSave();
                                        }}
                                    />
                                    {/*<p className="badge bg-info text-wrap">*/}
                                    {/*    {this.getDateAsDayOfWeekString(schedule.firstRunDate)}</p>*/}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    {schedule.scheduleFrequencyId !== 4 && <AddTextFieldForDate
                                        noDuplicates={false}
                                        isValid={validateDateWithFormat}
                                        dateFormat={currency.dateFormat}
                                        title={"End Date (" + currency.dateFormat + ")"}
                                        value={ schedule.lastRunDate  }
                                        existingValues={[] as string[]}
                                        // tslint:disable-next-line:jsx-no-lambda
                                        callback={async (lastrundate: string) => {
                                            schedule.lastRunDate = lastrundate;
                                            this.setState(() => ({ schedule: schedule }));
                                            this.readyToSave();
                                        }

                                        }
                                    />
                                    }
                                    {/*<p className="badge bg-info text-wrap">*/}
                                    {/*    {this.getDateAsDayOfWeekString(schedule.lastRunDate)}*/}
                                    {/*</p>*/}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AddSchedulePeriod
                                    schedulePeriodId={schedule.schedulePeriodId}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(cc: number) => {
                                        schedule.schedulePeriodId = cc;
                                        this.setState(() => ({ schedule: schedule }));
                                    }

                                    }
                                />

                            </Col>
                            <Col>
                                {schedule.schedulePeriodId === 4 && <AddTextField
                                    noDuplicates={false}
                                    isValid={validateNumber}
                                    title="Days:"
                                    existingValues={[] as string[]}
                                    value={schedule.schedulePeriodDays === undefined ? "" : schedule.schedulePeriodDays.toString()}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(days: string) => {
                                        schedule.schedulePeriodDays = parseInt(days);
                                        this.setState(() => ({ schedule: schedule }));
                                    }
                                    }
                                />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AddScheduleEvent
                                    scheduleEventId={schedule.scheduleEventId}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    callback={(cc: number) => {
                                        schedule.scheduleEventId = cc;
                                        this.setState(() => ({ schedule: schedule }));
                                    }}
                                />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>

            </Card>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        accessToken: state.auth0.accessToken,
        schedule: state.editScheduleDetails,
        userId: state.userProfile.id,
        activeUserRole: state.userRoles.find(
            role => role.id === state.activeRoleId
        ) as IRole,
        currencyCodes: state.currencyCodes
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onHandleUpdateSchedule: (accessToken: string, schedule: ISchedule) => {
        dispatch(handleUpdateSchedule(accessToken, schedule));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleDeleteSchedule: (accessToken: string, id: number) => {
        dispatch(handleRemoveScheduleProfile(accessToken, id));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleFrequency);

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";


import {
    clearEditScheduleDetails,
    setEditScheduleDetails
} from "../../actions/editScheduleDetails";


import { handleLoadSchedules } from "../../actions/schedules";

import AddScheduleDetails from "../../components/templates/AddScheduleDetails";
import EditScheduleDetails from "../../components/templates/EditScheduleDetails";

import paths, { scheduleWizardStages } from "../../constants";
import ISchedule from "../../models/ISchedule";
import { IStore } from "../../models/IStore";
import ScheduleWizardProgress from "../../components/molecules/ScheduleWizardProgress";
import AssignSchedulePaginatedReports from "../templates/AssignSchedulePaginatedReports";
import EditScheduleFrequencyDetails from "../templates/EditScheduleFrequencyDetails";

import AssignScheduleUsers from "../templates/AssignScheduleUsers";

import AddScheduleReportFormats from "../templates/AddScheduleReportFormats";
import EditSchedulePaginatedReportParametersDetails from "../templates/EditSchedulePaginatedReportParametersDetails";

import ScheduleSummary from "../../components/templates/ScheduleSummary";




export interface IScheduleWizardPageProps extends RouteComponentProps {
    accessToken: string;
    schedules: ISchedule[];
    scheduleId: number;
    userProfileId: number;
    onHandleLoadEditSchedule: (schedule: ISchedule) => void;
    onHandleLoadSchedules: (accessToken: string, userId: number) => void;
    onHandleClearEditSchedule: () => void;
}

export interface IScheduleWizardPageState {
    currentStage: number;
    schedule: ISchedule;
}

class ScheduleWizardPage extends React.Component<
    IScheduleWizardPageProps,
    IScheduleWizardPageState
> {
    public state = {
        currentStage: 0,
        schedule: {
            id: 0,
            // tslint:disable-next-line:object-literal-sort-keys

            name: "",

        } as ISchedule
    };
    constructor(props: IScheduleWizardPageProps) {
        super(props);
        this.incrementStage = this.incrementStage.bind(this);
        this.decrementStage = this.decrementStage.bind(this);
        this.renderCurrentStage = this.renderCurrentStage.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    public incrementStage = (e: React.MouseEvent<HTMLElement>) => {
        const { currentStage } = this.state;
        this.setState(() => ({ currentStage: currentStage + 1 }));
    };

    public decrementStage = (e: React.MouseEvent<HTMLElement>) => {
        const { currentStage } = this.state;
        this.setState(() => ({ currentStage: currentStage - 1 }));
    };
    public cancel = (e: React.MouseEvent) => {
        const { history } = this.props;
        e.preventDefault();
        history.push(paths.home);
    };
    public renderCurrentStage = (currentStage: number) => {
        switch (currentStage) {
            case scheduleWizardStages.addInitialScheduleDetails:
                if (this.state.schedule.id === 0) {
                    return <AddScheduleDetails />;
                } else {
                    return <EditScheduleDetails {...this.props} />;
                }

            case scheduleWizardStages.assignReports:
                return <AssignSchedulePaginatedReports />;

            case scheduleWizardStages.addFrequency:
                return <EditScheduleFrequencyDetails />

            case scheduleWizardStages.assignRecipients:
                return <AssignScheduleUsers />;

            case scheduleWizardStages.addReportFormats:
                return <AddScheduleReportFormats />;

            case scheduleWizardStages.editParameters:
                return <EditSchedulePaginatedReportParametersDetails />

            case scheduleWizardStages.reviewAndComplete:
                return <ScheduleSummary {...this.props} />;

            default:
                return (
                    <Card>
                        <CardBody>Not sure what stage we're at</CardBody>
                    </Card>
                );
        }
    };

    public async componentDidMount() {
        const {
            accessToken,
            schedules,
            onHandleLoadSchedules,
            onHandleLoadEditSchedule,
            onHandleClearEditSchedule,
            scheduleId,
            userProfileId
        } = this.props;
        if (schedules.length === 0) {
            await onHandleLoadSchedules(accessToken, userProfileId);
        }
        if (scheduleId !== 0) {
            const schedule = schedules.find(r => r.id === scheduleId);
            if (schedule !== undefined) {
                this.setState(() => ({ schedule }));
                await onHandleLoadEditSchedule(schedule);
            }
        } else {
            await onHandleClearEditSchedule();
            this.setState(() => ({
                schedule: {
                    id: 0,
                    // tslint:disable-next-line:object-literal-sort-keys                    
                    name: ""
                } as ISchedule
            }));
        }
    }

    public async componentDidUpdate(prevProps: IScheduleWizardPageProps) {
        const {
            scheduleId,
            onHandleLoadEditSchedule,
            onHandleClearEditSchedule,
            schedules
        } = this.props;
        if (scheduleId !== prevProps.scheduleId) {
            if (scheduleId !== 0) {
                const schedule = schedules.find(r => r.id === scheduleId);
                if (schedule !== undefined) {
                    this.setState(() => ({ schedule }));
                    await onHandleLoadEditSchedule(schedule);
                } else {
                    await onHandleClearEditSchedule();
                }
            } else {
                await onHandleClearEditSchedule();
                this.setState(() => ({
                    schedule: {
                        id: 0,
                        // tslint:disable-next-line:object-literal-sort-keys                       
                        name: ""
                    } as ISchedule
                }));
            }
        }
    }
    public componentWillUnmount() {
        this.props.onHandleClearEditSchedule();

        this.setState(() => ({
            schedule: {
                id: 0,
                // tslint:disable-next-line:object-literal-sort-keys        
                name: ""
            } as ISchedule
        }));
    }
    public render() {
        const { currentStage } = this.state;
        const { schedules, scheduleId } = this.props;
        return (
            <React.Fragment>
                <Container style={{ border: '1px solid darkgrey', backgroundColor: 'white'}}>
                    <Row>
                        <Col>
                            <h1>
                                Subscription Wizard{" "}
                                {(schedules.find(schedule => schedule.id === scheduleId) as ISchedule) === undefined
                                    ? ""
                                    : ": " +
                                    (schedules.find(schedule => schedule.id === scheduleId) as ISchedule)
                                        .name}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ScheduleWizardProgress currentStage={currentStage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {currentStage > scheduleWizardStages.addInitialScheduleDetails && (
                                <Button
                                    onClick={this.decrementStage}
                                    style={{ padding: 4, margin: 4 }}
                                >
                                    Previous
                                </Button>
                            )}
                            {currentStage < scheduleWizardStages.reviewAndComplete && (
                                <React.Fragment>
                                    {this.props.scheduleId !== 0 && (
                                        <Button
                                            onClick={this.incrementStage}
                                            style={{ padding: 4, margin: 4 }}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}

                            {/* <Button onClick={this.cancel} style={{ padding: 4, margin: 4 }}>
                Cancel
              </Button> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>{this.renderCurrentStage(currentStage)}</Col>
                    </Row>
                    <Row>
                        <Col><br/></Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
    const { match } = ownProps;
    const scheduleId =
        (match.params as any).id !== undefined
            ? parseInt((match.params as any).id as string, 10) // we're editing an existing schedule
            : // we're adding a new schedule
            state.editScheduleDetails.id === undefined
                ? 0 // we haven't saved the schedule yet so not yet in store
                : state.editScheduleDetails.id;

    return {
        accessToken: state.auth0.accessToken,
        scheduleId,
        schedules: state.schedules,
        userProfileId: state.userProfile.id,

    };
};
 

const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadEditSchedule: (schedule: ISchedule) => {
        dispatch(setEditScheduleDetails(schedule));
    },
    onHandleLoadSchedules: (accessToken: string, userId: number) => {
        dispatch(handleLoadSchedules(accessToken, userId));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleClearEditSchedule: () => {
        dispatch(clearEditScheduleDetails());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleWizardPage);

import { AnyAction } from "redux";
import { SET_SCHEDULE_PERIODS_LOADED } from "../actions/loadingSchedulePeriods";

export const loadingSchedulePeriods = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_SCHEDULE_PERIODS_LOADED:
      return action.schedulePeriodsLoaded;
    default:
      return state;
  }
};

export default loadingSchedulePeriods;

import IPbiBookmark from "../models/IPbiBookmark";
import { fetchSecureData } from "../utils";

export const getBookmarks = async (accessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => {
  const data = await fetchSecureData(
    "GET",
      `/api/PbiBookmark/GetPbiBookmarksForUserIdAndRoleAndDashboard?roleId=${roleId}&userProfileId=${userProfileId}&pbiDashboardId=${pbiDashboardId}`,
    accessToken
  );
  const bookmarks = ((await data.json()) as any).value as IPbiBookmark[];
  if (bookmarks !== undefined) {
    return bookmarks;
  } else {
    return [] as IPbiBookmark[];
  }
};

export default getBookmarks;

import IScheduleRoleUser from "../models/IScheduleRoleUser";
import { fetchSecureData } from "../utils/index";

export const getUserRolesWithUserProfiles = async (accessToken: string, userId: number) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/Role/GetRolesWithUserProfilesForUserId?userId=${userId}`,
      accessToken
    );
      const userRoles = ((await data.json()) as any).value as IScheduleRoleUser[];
    if (userRoles !== undefined) {
      return userRoles;
    } else {
        return [] as IScheduleRoleUser[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Role/GetRolesForUserId?userId=${userId}`,
      err
    );
  }
    return [] as IScheduleRoleUser[];
};

export default getUserRolesWithUserProfiles;

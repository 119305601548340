import * as React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import IPbiDashboard from "../../models/IPbiDashboard";
import IRole from "../../models/IRole";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import ActiveRoleBadge from "./ActiveRoleBadge";


export interface IWelcomeCardProps {
  name: string;
  activeRoleDisplayName: string;
 lastDashboard: IPbiDashboard;
 //  lastPaginatedReport: IPbiPaginatedReport;
  lastReport: ISqlReport;
  userRoles: IRole[];
}
const WelcomeCard: React.SFC<IWelcomeCardProps> = (
  props: IWelcomeCardProps
) => {
  const {
    activeRoleDisplayName,
    name,
    userRoles /*lastDashboard, lastReport*/
  } = props;
  return (
    <Card>
      <CardBody>
        <CardTitle tag="div">
          <h1>
            Welcome <strong>{name ? name : "!"}</strong>
          </h1>
        </CardTitle>
        <CardText>
          {activeRoleDisplayName !== "" && userRoles.length > 1 && (
            <span>
              Your active role is <ActiveRoleBadge />.
            </span>
          )}
          
          {/*
          <span>
            Your last accessed dashboard is{" "}
            <Link to={paths.pbiDashboards.concat(lastDashboard.id.toString())}>
              {lastDashboard.name}
            </Link>
          </span>
          <br />
          <span>
            Your last accessed report is{" "}
            <Link to={paths.sqlReports.concat(lastReport.id.toString())}>
              {lastReport.name}
            </Link>
          </span>
          <br /> */}
          <span>To get started select a report to view.</span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IStore) => {
  const activeRole = state.userRoles.find(
    role => role.id === state.activeRoleId
  ) as IRole;

  return {
    activeRoleDisplayName:
      activeRole === undefined ? "" : activeRole.displayName,
    name: state.auth0.getProfile().name,
    // tslint:disable-next-line:object-literal-sort-keys
    lastDashboard: state.userDashboards.find(
      dashboard => dashboard.id === state.activeDashboardId
      ) as IPbiDashboard,

    //TODO
      lastPaginatedReport: state.userDashboards.find(
          dashboard => dashboard.id === state.activeDashboardId
      ) as IPbiDashboard,

      //lastPaginatedReport: state.userPaginatedReports.find(
      //    paginatedReport => paginatedReport.id === state.activePaginatedReportId
      //) as IPbiPaginatedReport,
    lastReport: state.userReports.find(
      report => report.id === state.activeReportId
    ) as ISqlReport,
    userRoles: state.userRoles
  };
};
export default connect(mapStateToProps)(WelcomeCard);

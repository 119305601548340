import IFavorite from "../models/IUserFavorite";
import { fetchSecureData } from "../utils";

export const saveUserFavorite = async (accessToken: string, favorite: IFavorite) => {
  
  try {
    const data = await fetchSecureData(
      "POST",
        `/api/UserProfileFavorite/Add?userId=${favorite.userProfileId}&roleId=${favorite.roleId}&reportId=${favorite.reportId}&reportType=${favorite.reportType}&isEnabled=true`,
        accessToken
    
    );
    const result = ((await data.json()) as any).value as IFavorite;
     
    return result;
  } catch (err) {
 
      console.log(`Fetch error for /api/UserProfileFavorite/Add?userId=${favorite.userProfileId}&roleId=${favorite.roleId}&reportId=${favorite.reportId}&reportType=${favorite.reportType}&isEnabled=true`, err);
  }
    return {} as IFavorite;
};

export default saveUserFavorite;

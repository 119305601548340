import * as React from "react";

export interface INavbarProps {
    children: React.ReactNode;
}

const Navbar: React.SFC<INavbarProps> = (props: INavbarProps) => {
    const { children } = props;
    return (

        <nav className="navbar navbar-expand-md navbarDark">
            {children}
        </nav>
    );
};

export default Navbar;

import { AnyAction } from "redux";
import {
  ADD_USER_SCHEDULE,
  LOAD_USER_SCHEDULES,
  REMOVE_USER_SCHEDULE
} from "../actions/userSchedules";
import IUserSchedule from "../models/IUserSchedule";

export const userSchedules = (
  state = [] as IUserSchedule[],
  action: AnyAction
) => {
  switch (action.type) {   
    case LOAD_USER_SCHEDULES:
      return action.userSchedules as IUserSchedule[];
    default:
      return state;
  }
};

export default userSchedules;

import { AnyAction } from "redux";
import {
    ADD_EDIT_ROLE_PAGINATED_REPORT_ID,
    CLEAR_EDIT_ROLE_PAGINATED_REPORT_IDS,
    LOAD_EDIT_ROLE_PAGINATED_REPORT_IDS,
    REMOVE_EDIT_ROLE_PAGINATED_REPORT_ID
} from "../actions/editRolePaginatedReportIds";

export const editRolePaginatedReportIds = (
    state = [] as number[],
    action: AnyAction
) => {
    switch (action.type) {
        case ADD_EDIT_ROLE_PAGINATED_REPORT_ID:
            const newState: number[] = state.slice();
            newState.push(action.id as number);
            return newState;
        case REMOVE_EDIT_ROLE_PAGINATED_REPORT_ID:
            return Object.assign(
                [],
                state.filter((id: number) => id !== (action.id as number))
            ) as number[];
        case LOAD_EDIT_ROLE_PAGINATED_REPORT_IDS:
            return action.ids as number[];
        case CLEAR_EDIT_ROLE_PAGINATED_REPORT_IDS:
            return [] as number[];
        default:
            return state;
    }
};

export default editRolePaginatedReportIds;

import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils/index";

export const saveUpdatedPaginatedReport = async (
  accessToken: string,
    paginatedReport: IPbiPaginatedReport
) => {
  const payload = {
      id: paginatedReport.id,
      name: paginatedReport.name,
    // tslint:disable-next-line:object-literal-sort-keys
      displayName: paginatedReport.displayName,
      groupId: paginatedReport.groupId,
      reportId: paginatedReport.reportId,     
      isEnabled: paginatedReport.isEnabled,
      isForScheduling: paginatedReport.isForScheduling
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/PbiPaginatedReport/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IPbiPaginatedReport;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
      console.log(`Fetch error for /api/PbiPaginatedReport/Edit`, err);
  }
    return {} as IPbiPaginatedReport;
};

export default saveUpdatedPaginatedReport;

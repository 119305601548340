import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils";

export const createPaginatedReportParameters = async (
  accessToken: string,   
  groupId: string,
  reportId: string,
  id: string
) => {
  try {
    const data = await fetchSecureData(
      "POST",
        `/api/PbiPaginatedReport/CreateParameters?reportId=${reportId}&id=${id}`,
      accessToken
      ); 
      const paginatedReport = ((await data.json()) as any).value as IPbiPaginatedReport;
      return paginatedReport;
 
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `/api/PbiPaginatedReport/CreateParameters?reportId=${reportId}&id=${id}`,
      err
    );
  } 
    return {} as IPbiPaginatedReport;
};

export default createPaginatedReportParameters;

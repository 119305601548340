import * as Redux from "redux";
import getUserRoles from "../api/getUserRoles";
import IRole from "../models/IRole";
import { setUserRolesLoaded } from "./loadingUserRoles";
import { handleSetActiveRolePredicateClient } from "./shared";

export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const REMOVE_USER_ROLE = "REMOVE_USER_ROLE";
export const LOAD_USER_ROLES = "LOAD_USER_ROLES";

export const addUserRole = (userRole: IRole) => {
  return {
    userRole,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_USER_ROLE
  };
};

export const removeUserRole = (id: number) => {
  return {
    id,
    type: REMOVE_USER_ROLE
  };
};

export const loadUserRoles = (userRoles: IRole[]) => {
  return {
    userRoles,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_USER_ROLES
  };
};

export const handleLoadUserRoles = (accessToken: string, userId: number) => {
  return async (dispatch: Redux.Dispatch<any>) => {
    const roles = await getUserRoles(accessToken, userId);
    if (roles !== undefined) {
      if (roles.length > 0) {
        roles.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
          dispatch(loadUserRoles(roles));
          var isActiveRole = roles.find(role => role.isActive == true);

          if (isActiveRole === undefined || isActiveRole === null) {
              isActiveRole = roles[0];
          }

           
          dispatch(handleSetActiveRolePredicateClient(accessToken, isActiveRole.id, userId));
      }
      dispatch(setUserRolesLoaded(true));
    }
  };
};

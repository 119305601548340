import { AnyAction } from "redux";
import { SET_EDIT_SCHEDULE_PAGINATED_REPORTS_LOADED } from "../actions/loadingEditSchedulePaginatedReports";

export const loadingEditSchedulePaginatedReports = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_SCHEDULE_PAGINATED_REPORTS_LOADED:
      return action.editSchedulePaginatedReportsLoaded;
    default:
      return state;
  }
};

export default  loadingEditSchedulePaginatedReports;

import IPaginatedReportParameter from "../models/IPaginatedReportParameter";
import { fetchSecureData } from "../utils/index";

export const saveUpdatedEditPaginatedReportParameter = async (
    accessToken: string,
    editPaginatedReportParameter: IPaginatedReportParameter
) => {
   
    try {
        const data = await fetchSecureData(
            "PUT",
            `/api/PbiPaginatedReport/UpdateParameter?id=${editPaginatedReportParameter.id}&defaultValueJson=${editPaginatedReportParameter.defaultValueJson}&isHidden=${editPaginatedReportParameter.isHidden}&isEnabled=${editPaginatedReportParameter.isEnabled}`,
            accessToken 
        );
        const result = ((await data.json()) as any).value as IPaginatedReportParameter;
        // tslint:disable-next-line:no-console
        return result;
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(`Fetch error for //api/PbiPaginatedReport/UpdateParameter?id=${editPaginatedReportParameter.id}&defaultValueJson=${editPaginatedReportParameter.defaultValueJson}&isHidden=${editPaginatedReportParameter.isEnabled}&isHidden=${editPaginatedReportParameter.isEnabled}`, err);
    }
    return {} as IPaginatedReportParameter;
};

export default saveUpdatedEditPaginatedReportParameter;

import * as React from "react";
import { Progress } from "reactstrap";
import { scheduleWizardStages } from "../../constants";



export interface IScheduleWizardProgressProps {
    currentStage: number;
}
const ScheduleWizardProgress: React.SFC<IScheduleWizardProgressProps> = (
    props: IScheduleWizardProgressProps
) => {
    const { currentStage } = props;
    return (
        <Progress multi={true}>

            {currentStage >= scheduleWizardStages.addInitialScheduleDetails && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.addInitialScheduleDetails}
                    value="20"
                >

                    {currentStage === scheduleWizardStages.addInitialScheduleDetails && (
                        <span>Subscription Name</span>
                    )}
                </Progress>
            )}


            {currentStage >= scheduleWizardStages.addFrequency && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.addFrequency}
                    value="10"
                >
                    {currentStage === scheduleWizardStages.addFrequency && (
                        <span>Add Frequency</span>
                    )}
                </Progress>
            )}

            {currentStage >= scheduleWizardStages.assignReports && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.assignReports}
                    value="10"
                >
                    {currentStage === scheduleWizardStages.assignReports && (
                        <span>Assign Reports</span>
                    )}
                </Progress>
            )}


            {currentStage >= scheduleWizardStages.editParameters && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.editParameters}
                    value="20"
                >
                    {currentStage === scheduleWizardStages.editParameters && (
                        <span>Edit Report Parameters</span>
                    )}
                </Progress>
            )}
             

            {currentStage >= scheduleWizardStages.assignRecipients && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.assignRecipients}
                    value="10"
                >
                    {currentStage === scheduleWizardStages.assignRecipients && (
                        <span>Assign Recipients</span>
                    )}
                </Progress>
            )}


            {currentStage >= scheduleWizardStages.addReportFormats && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.addReportFormats}
                    value="10"
                >
                    {currentStage === scheduleWizardStages.addReportFormats && (
                        <span>Add Report Formats</span>
                    )}
                </Progress>
            )}

             

            {currentStage >= scheduleWizardStages.reviewAndComplete && (
                <Progress
                    color="success"
                    bar={true}
                    animated={currentStage === scheduleWizardStages.reviewAndComplete}
                    value="20"
                >
                    {currentStage === scheduleWizardStages.reviewAndComplete && (
                        <span>Review and Complete</span>
                    )}
                </Progress>
            )}
        </Progress>
    );
};

export default ScheduleWizardProgress;

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import {
  handleDeleteDashboard,
  handleLoadDashboards,
  handleUpdateDashboard
} from "../../../actions/dashboards";
import AddTextField from "../../../components/molecules/AddTextField";
import DashboardDetailsTable from "../../../components/molecules/DashboardDetailsTable";
import { paths, settings } from "../../../constants";
import IPbiDashboard from "../../../models/IPbiDashboard";
import { IStore } from "../../../models/IStore";
import {
  validateDashboardDisplayName,
  validateDashboardName,
  validateGuid,
  validateNumber
} from "../../../utils";
export interface IDashboardPageProps extends RouteComponentProps {
  accessToken: string;
  dashboard: IPbiDashboard;
  existingDashboards: IPbiDashboard[];
  onHandleLoadDashboards: (accessToken: string) => void;
  onHandleUpdateDashboard: (
    accessToken: string,
    dashboard: IPbiDashboard
  ) => void;
  onHandleDeleteDashboard: (accessToken: string, id: number) => void;
}
export interface IDashboardPageState {
  editModeOn: boolean;
  displayName: string;
  name: string;
  groupId: string;
  reportId: string;
  dashboardViewerVersion: number;
  isEnabled: boolean;
  showDeleteModal: boolean;
  isReadyToSave: boolean;
}

class EditDashboardPage extends React.Component<
  IDashboardPageProps,
  IDashboardPageState
> {
  public isLoaded = false;
  public state = {
    editModeOn: false,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: "",
    name: "",
    groupId: "",
    reportId: "",
    dashboardViewerVersion: 0,
    isEnabled: true,
    showDeleteModal: false,
    isReadyToSave: false
  };
  constructor(props: IDashboardPageProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }

  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (e: React.MouseEvent) => {
    e.preventDefault();
    const {
      accessToken,
      onHandleUpdateDashboard,
      history,
      dashboard
    } = this.props;
    const {
      name,
      displayName,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled,
      isReadyToSave
    } = this.state;
    const updatedDashboard = {
      id: dashboard.id,
      name,
      // tslint:disable-next-line:object-literal-sort-keys
      displayName,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    } as IPbiDashboard;
    if (this.readyToSave() && isReadyToSave) {
      onHandleUpdateDashboard(accessToken, updatedDashboard);
        history.push(paths.searchPaginatedReports);
    }
  };
  public delete = (e: React.MouseEvent) => {
    const {
      accessToken,
      dashboard,
      history,
      onHandleDeleteDashboard
    } = this.props;
    onHandleDeleteDashboard(accessToken, dashboard.id);
    history.push(paths.home);
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public readyToSave = () => {
    const {
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion
    } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateDashboardDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (name === undefined || name === "" || !validateDashboardName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (groupId === undefined || groupId === "" || !validateGuid(groupId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (reportId === undefined || reportId === "" || !validateGuid(reportId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    if (
      dashboardViewerVersion === undefined ||
      dashboardViewerVersion < 1 
    ) {
      this.setState(() => ({ isReadyToSave: false }));
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadDashboards,
      existingDashboards,
      dashboard
    } = this.props;
    const {
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    } = dashboard;
    this.setState(() => ({
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    }));
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingDashboards.length === 0) {
        onHandleLoadDashboards(accessToken);
      }
    }
  }
  public render() {
    const { dashboard, existingDashboards } = this.props;
    const {
      editModeOn,
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled,
      isReadyToSave
    } = this.state;
    const { allowDisableDashboards } = settings;
    return (
      <Form>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Dashboard {displayName}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete Dashboard: {displayName}{" "}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardTitle>Dashboard Details for {displayName}</CardTitle>
          <CardBody>
            {!editModeOn && <DashboardDetailsTable dashboard={dashboard} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateDashboardDisplayName}
                  title="Display Name"
                  value={displayName}
                  existingValues={existingDashboards.map(d => d.displayName)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (dn: string) => {
                    await this.setState(() => ({ displayName: dn }));
                    this.readyToSave();
                  }}
                />

                <AddTextField
                  noDuplicates={true}
                  isValid={validateDashboardName}
                  title="Name"
                  value={name}
                  existingValues={existingDashboards.map(d => d.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (n: string) => {
                    await this.setState(() => ({ name: n }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={false}
                  isValid={validateGuid}
                  title="Group ID"
                  value={groupId}
                  existingValues={existingDashboards.map(d => d.groupId)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (gId: string) => {
                    await this.setState(() => ({ groupId: gId }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={true}
                  isValid={validateGuid}
                  title="Report ID"
                  value={reportId}
                  existingValues={existingDashboards.map(d => d.reportId)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (rId: string) => {
                    await this.setState(() => ({ reportId: rId }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={false}
                  isValid={validateNumber}
                  title="Dashboard Viewer Version Number"
                  value={dashboardViewerVersion.toString()}
                  existingValues={existingDashboards.map(d => d.reportId)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (dvver: string) => {
                    await this.setState(() => ({ dashboardViewerVersion: parseInt(dvver) }));
                    this.readyToSave();
                  }}
                />

                {allowDisableDashboards && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            {!editModeOn && (
              <React.Fragment>
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  onClick={this.toggleDeleteModal}
                  style={{
                    margin: 4,
                    padding: 4
                  }}
                >
                  <strong>Delete Dashboard</strong>
                </Button>
              </React.Fragment>
            )}
            {editModeOn && (
              <React.Fragment>
                {isReadyToSave && (
                  <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                    Save Changes
                  </Button>
                )}
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let dashboard: IPbiDashboard = {} as IPbiDashboard;
  const existingDashboards = state.dashboards.filter(
    dsh => dsh.id !== parseInt((match.params as any).id as string, 10)
  ) as IPbiDashboard[];
  if (match !== null) {
    dashboard = state.dashboards.find(dsh => {
      return dsh.id === parseInt((match.params as any).id as string, 10);
    }) as IPbiDashboard;
  }
  return {
    accessToken: state.auth0.accessToken,
    dashboard,
    existingDashboards
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadDashboards: (accessToken: string) => {
    dispatch(handleLoadDashboards(accessToken));
  },
  onHandleUpdateDashboard: (accessToken: string, dashboard: IPbiDashboard) => {
    dispatch(handleUpdateDashboard(accessToken, dashboard));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteDashboard: (accessToken: string, id: number) => {
    dispatch(handleDeleteDashboard(accessToken, id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDashboardPage);

import { AnyAction } from "redux";
import {
    
    LOAD_PAGINATED_REPORT_PARAMETERS
} from "../actions/paginatedReportParameters";
import IPaginatedReportParameter from "../models/IPaginatedReportParameter";

export const PaginatedReportParameters = (
  state = [] as IPaginatedReportParameter[],
  action: AnyAction
) => {
  switch (action.type) {   

      case LOAD_PAGINATED_REPORT_PARAMETERS:
          return action.pbiPaginatedReportParameters as IPaginatedReportParameter[];

      default:
          return state;
  }
};

export default PaginatedReportParameters;

import { AnyAction } from "redux";
import {
    CLEAR_ACTIVE_ROLE_ID,
    SET_ACTIVE_ROLE_ID,
    SAVE_ACTIVE_ROLE_ID
} from "../actions/activeRole";
 

export const activeRole = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_ROLE_ID:
      return action.id as number;
    case CLEAR_ACTIVE_ROLE_ID:
          return 0;
      case SAVE_ACTIVE_ROLE_ID:
          return action.id as number;
    default:
      return state;
  }
};

export default activeRole;

import * as Redux from "redux";
import getEditPaginatedReportParameters from "../api/getPaginatedReportParameters";
import saveUpdatedEditPaginatedReportParameter from "../api/saveUpdatedEditPaginatedReportParameter";
import IPaginatedReportParameter from "../models/IPaginatedReportParameter";
 

export const UPDATE_EDIT_PAGINATED_REPORT_PARAMETER = "UPDATE_EDIT_PAGINATED_REPORT_PARAMETER";
 
 

export const updateEditPaginatedReportParameter = (editPaginatedReportParameter: IPaginatedReportParameter) => {
    return {
        editPaginatedReportParameter,
        type: UPDATE_EDIT_PAGINATED_REPORT_PARAMETER
    };
};
 

export const handleUpdateEditPaginatedReportParameter = (
    accessToken: string,
    editPaginatedReportParameter: IPaginatedReportParameter
) => {
    return async (dispatch: Redux.Dispatch) => {
        const dsh = await saveUpdatedEditPaginatedReportParameter(accessToken, editPaginatedReportParameter);
        if (dsh !== null && dsh !== undefined) {
            dispatch(updateEditPaginatedReportParameter(editPaginatedReportParameter));
        }
    };
};
 
 
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Table
} from "reactstrap";
import { handleLoadReports, handleSaveReport } from "../../../actions/reports";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings, zoomModes } from "../../../constants";
import ISqlReport from "../../../models/ISqlReport";
import { IStore } from "../../../models/IStore";
import {
  validateParametersText,
  validatePathText,
  validateReportDisplayName,
  validateReportName,
  validateZoomPercent
} from "../../../utils";
export interface IAddReportPageProps extends RouteComponentProps {
  accessToken: string;
  existingReports: ISqlReport[];
  onHandleLoadReports: (accessToken: string) => void;
  onHandleSaveReport: (
    accessToken: string,
    name: string,
    displayName: string,
    path: string,
    parameters: string,
    defaultZoomMode: string,
    defaultZoomPercent: number,
    sqlReportingServerUrl: string,
    sqlReportingViewerUrl: string,
    isEnabled: boolean
  ) => void;
}
export interface IAddReportPageState {
  displayName: string;
  name: string;
  path: string;
  parameters: string;
  defaultZoomMode: string;
  defaultZoomPercent: number;
  sqlReportingServerUrl: string;
  sqlReportingViewerUrl: string;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddReportPage extends React.Component<
  IAddReportPageProps,
  IAddReportPageState
> {
  public isLoaded = false;
  public state = {
    displayName: "",
    name: "",
    path: "",
    // tslint:disable-next-line:object-literal-sort-keys
    parameters: "",
    defaultZoomMode: "Whole Page",
    defaultZoomPercent: 100,
    sqlReportingServerUrl: "",
    sqlReportingViewerUrl: "",
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddReportPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
    this.defaultZoomModeChange = this.defaultZoomModeChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const {
      displayName,
      name,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent
    } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateReportDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (name === undefined || name === "" || !validateReportName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (path === undefined || path === "" || !validatePathText(path)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (parameters === undefined || !validateParametersText(parameters)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (
      defaultZoomMode === "Percent" &&
      (defaultZoomPercent === undefined ||
        !validateZoomPercent(defaultZoomPercent.toString()))
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveReport, history } = this.props;
    const {
      name,
      displayName,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      sqlReportingServerUrl,
      sqlReportingViewerUrl,
      isEnabled
    } = this.state;
    if (this.readyToSave()) {
      onHandleSaveReport(
        accessToken,
        name,
        displayName,
        path,
        parameters,
        defaultZoomMode,
        defaultZoomPercent,
        sqlReportingServerUrl,
        sqlReportingViewerUrl,
        isEnabled
      );
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          displayName: "",
          name: "",
          path: "",
          // tslint:disable-next-line:object-literal-sort-keys
          parameters: "",
          defaultZoomMode: "Whole Page",
          defaultZoomPercent: 100,
          sqlReportingServerUrl: "",
          sqlReportingViewerUrl: "",
          isEnabled: true,
          isReadyToSave: false
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public defaultZoomModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState(() => ({ isReadyToSave: true }));
    const defaultZoomMode =
      e.target.value === undefined ? "" : (e.target.value as string);
    switch (defaultZoomMode) {
      case "Whole Page":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "Page Width":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "Percent":
        this.setState(() => ({ defaultZoomMode, defaultZoomPercent: 100 }));
        break;
      case "500%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 500
        }));
        break;
      case "200%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 200
        }));
        break;
      case "150%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 150
        }));
        break;
      case "100%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 100
        }));
        break;
      case "75%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 75
        }));
        break;
      case "50%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 50
        }));
        break;
      case "25%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 25
        }));
        break;
      case "10%":
        this.setState(() => ({
          defaultZoomMode: "Percent",
          defaultZoomPercent: 10
        }));
        break;
      default:
        this.setState(() => ({ defaultZoomMode }));
        break;
    }
  };

  public async componentDidMount() {
    const { accessToken, onHandleLoadReports, existingReports } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingReports.length === 0) {
        onHandleLoadReports(accessToken);
      }
    }
  }
  public render() {
    const { existingReports } = this.props;
    const {
      displayName,
      name,
      path,
      parameters,
      defaultZoomMode,
      defaultZoomPercent,
      isEnabled,
      isReadyToSave
    } = this.state;
    const { allowDisableReports } = settings;
    return (
      <Form>
        <Card>
                <CardHeader>Add New SSRS Report Details</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validateReportDisplayName}
                title="Display Name"
                value={displayName}
                existingValues={existingReports.map(d => d.displayName)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dn: string) => {
                  await this.setState(() => ({ displayName: dn }));
                  this.readyToSave();
                }}
              />

              <AddTextField
                noDuplicates={true}
                isValid={validateReportName}
                title="Name"
                value={name}
                existingValues={existingReports.map(d => d.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={false}
                isValid={validatePathText}
                title="Path"
                value={path}
                existingValues={existingReports.map(d => d.path)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (p: string) => {
                  await this.setState(() => ({ path: p }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                allowEmptyValues={true}
                noDuplicates={false}
                isValid={validateParametersText}
                title="Parameters"
                value={parameters}
                existingValues={existingReports.map(d => d.parameters)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (params: string) => {
                  await this.setState(() => ({ parameters: params }));
                  this.readyToSave();
                }}
              />
              <FormGroup>
                <Label for="defaultZoomMode">Default Zoom Mode</Label>
                <Input
                  id="defaultZoomMode"
                  type="select"
                  placeholder="Default Zoom Mode"
                  value={defaultZoomMode}
                  onChange={this.defaultZoomModeChange}
                >
                  {zoomModes.map(zm => (
                    <option key={zm} value={zm}>
                      {zm}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {defaultZoomMode === "Percent" && (
                <AddTextField
                  noDuplicates={false}
                  isValid={validateZoomPercent}
                  title="Zoom Percent"
                  value={
                    defaultZoomPercent !== undefined
                      ? defaultZoomPercent.toString()
                      : ""
                  }
                  existingValues={existingReports.map(d => d.path)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (zpct: string) => {
                    await this.setState(() => ({
                      defaultZoomPercent: Number(zpct)
                    }));
                    this.readyToSave();
                  }}
                />
              )}
              {allowDisableReports && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              {isReadyToSave && (
                <React.Fragment>
                  <Button
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => this.save()}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => this.save(true)}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Save And Add Another
                  </Button>
                </React.Fragment>
              )}
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingReports: state.reports
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadReports: (accessToken: string) => {
    dispatch(handleLoadReports(accessToken));
  },
  onHandleSaveReport: (
    accessToken: string,
    name: string,
    displayName: string,
    path: string,
    parameters: string,
    defaultZoomMode: string,
    defaultZoomPercent: number,
    sqlReportingServerUrl: string,
    sqlReportingViewerUrl: string,
    isEnabled: boolean
  ) => {
    // tslint:disable-next-line:no-console
    console.log("parameters is:[",parameters,"]")
    dispatch(
      handleSaveReport(
        accessToken,
        name,
        displayName,
        defaultZoomMode,
        defaultZoomPercent,
        parameters,
        path,
        sqlReportingServerUrl,
        sqlReportingViewerUrl,
        isEnabled
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddReportPage);

import { AnyAction } from "redux";
import {
  ADD_USER_ROLES_WITH_USER_PROFILE,
  LOAD_USER_ROLES_WITH_USER_PROFILES,
  REMOVE_USER_ROLES_WITH_USER_PROFILE
} from "../actions/userRolesWithUserProfiles";
import IScheduleRoleUser from "../models/IScheduleRoleUser";

export const userRolesWithUserProfiles = (state = [] as IScheduleRoleUser[], action: AnyAction) => {
  switch (action.type) {
    case ADD_USER_ROLES_WITH_USER_PROFILE:
      const newState: IScheduleRoleUser[] = state.slice();
      newState.push(action.userRolesWithUserProfile as IScheduleRoleUser);
      return newState;
    case REMOVE_USER_ROLES_WITH_USER_PROFILE:
      return Object.assign(
        {},
        state.filter((r: IScheduleRoleUser) => r.userProfileId !== (action.id as number))
      );
    case LOAD_USER_ROLES_WITH_USER_PROFILES:
      return action.userRolesWithUserProfiles as IScheduleRoleUser[];

    default:
      return state;
  }
};

export default userRolesWithUserProfiles;

import * as React from "react";
import { Badge, Label } from "reactstrap";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";

export interface IPaginatedReportsBadgeProps {
  paginatedReportIds: number[];
  paginatedReports: IPbiPaginatedReport[];
}

const PaginatedReportsBadge = (props: IPaginatedReportsBadgeProps) => {
  const { paginatedReportIds, paginatedReports } = props;
  return (
    <React.Fragment>
          <Label for="grpSelectedPaginatedReports"><div className="labelH1">Selected Reports:</div></Label>
      <div id="grpSelectedPaginatedReports">
        {paginatedReportIds.map(id => (
            <Badge key={id} className="badge">
            {
              (paginatedReports.find(paginatedReport => paginatedReport.id === id) as IPbiPaginatedReport)
                .displayName
            }
          </Badge>
        ))}
      </div>
      {paginatedReportIds.length === 0 && <div>None</div>}
    </React.Fragment>
  );
};

export default PaginatedReportsBadge;

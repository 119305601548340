import { AnyAction } from "redux";
import {
    LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS,
    UPDATE_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETER
} from "../actions/editSchedulePaginatedReportParameters";
import ISchedulePaginatedReportParameter from "../models/ISchedulePaginatedReportParameter";

export const editSchedulePaginatedReportParameters = (
    state = [] as ISchedulePaginatedReportParameter[],
    action: AnyAction
) => {
    switch (action.type) {     
        case UPDATE_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETER:
            return Object.assign(
                [],
                state.map((dshbd: ISchedulePaginatedReportParameter) => {
                    if (dshbd.scheduleOverridedParameterId === (action.editSchedulePaginatedReportParameter as ISchedulePaginatedReportParameter).scheduleOverridedParameterId) {
                        return action.editSchedulePaginatedReportParameter as ISchedulePaginatedReportParameter;
                    }
                    return dshbd;
                })
            ) as ISchedulePaginatedReportParameter[];
        case LOAD_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS:
            return action.editSchedulePaginatedReportParameters as ISchedulePaginatedReportParameter[];
        default:
            return state;
    }
};

export default editSchedulePaginatedReportParameters;

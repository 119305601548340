import * as React from "react";
import Manage from "../../assets/icons/share_dashboard.svg";

import Icon from "./Icon";

const ShareIcon = () => {
    var smallStyle = {
        height: 18,
        margin: "0px 8px 0px 0px" 
    }; 

  return (
    <React.Fragment>
          <Icon icon={Manage} text="Bookmark Icon" id="ShareIcon" style={smallStyle} />
    </React.Fragment>
  );
};

export default ShareIcon;

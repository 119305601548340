import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlQaAsiaProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlQaAsia extends React.Component<
  ICallbackSamlQaAsiaProps
> {
    public constructor(props: ICallbackSamlQaAsiaProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

    auth.loginSAMLQaAsia().then(() => {
      console.log("Login via SAML QA Asia");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlQaAsia);

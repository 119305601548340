import * as Redux from "redux";
import getUserRolesWithUserProfiles from "../api/getUserRolesWithUserProfiles";
import IScheduleRoleUser from "../models/IScheduleRoleUser";
import { setUserRolesWithUserProfilesLoaded } from "./loadingUserRolesWithUserProfiles";
import { handleSetActiveRolePredicateClient } from "./shared";

export const ADD_USER_ROLES_WITH_USER_PROFILE = "ADD_USER_ROLES_WITH_USER_PROFILE";
export const REMOVE_USER_ROLES_WITH_USER_PROFILE = "REMOVE_USER_ROLES_WITH_USER_PROFILE";
export const LOAD_USER_ROLES_WITH_USER_PROFILES = "LOAD_USER_ROLES_WITH_USER_PROFILES";

export const addUserRolesWithUserProfile = (userRolesWithUserProfile: IScheduleRoleUser) => {
  return {
    userRolesWithUserProfile,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_USER_ROLES_WITH_USER_PROFILE
  };
};

export const removeUserRolesWithUserProfile = (id: number) => {
  return {
    id,
    type: REMOVE_USER_ROLES_WITH_USER_PROFILE
  };
};

export const loadUserRolesWithUserProfiles = (userRolesWithUserProfiles: IScheduleRoleUser[]) => {
  return {
    userRolesWithUserProfiles,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_USER_ROLES_WITH_USER_PROFILES
  };
};

export const handleLoadUserRolesWithUserProfiles = (accessToken: string, userId: number) => {
  return async (dispatch: Redux.Dispatch<any>) => {
    const roles = await getUserRolesWithUserProfiles(accessToken, userId);
    if (roles !== undefined) {
      if (roles.length > 0) {
        //roles.sort((a, b) =>
        //    a.roleDisplayName.toLowerCase() > b.roleDisplayName.toLowerCase() ? 1 : -1 &&
        //        a.emailAddress.toLowerCase() > b.emailAddress.toLowerCase() ? 1 : -1
        //);
        dispatch(loadUserRolesWithUserProfiles(roles));        
      }
      dispatch(setUserRolesWithUserProfilesLoaded(true));
    }
  };
};

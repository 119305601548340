import ISchedulePeriod from "../models/ISchedulePeriod";
import { fetchSecureData } from "../utils";

export const getSchedulePeriods = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    "/api/SchedulePeriod/GetSchedulePeriods",
    accessToken
  );
  const schedulePeriods = ((await data.json()) as any).value as ISchedulePeriod[];
  if (schedulePeriods !== undefined) {
    return schedulePeriods;
  } else {
    return [] as ISchedulePeriod[];
  }
};

export default getSchedulePeriods;

import { AnyAction } from "redux";
import {
    
    ADD_DASHBOARD_SHARED,
    LOAD_EMAILED_DASHBOARD
} from "../actions/emailedDashboard";
import IPbiDashboard from "../models/IPbiDashboard";
import IPbiDashboardShared from "../models/IPbiDashboardShared";


export const emailedPbiDashboard = (
    state = {} as IPbiDashboardShared,
    action: AnyAction
) => {
    switch (action.type) {       
        case ADD_DASHBOARD_SHARED:
            return true;

        case LOAD_EMAILED_DASHBOARD:
            var result = action.emailedPbiDashboard as IPbiDashboardShared;
            return action.emailedPbiDashboard === undefined ? null : result;

        default:
            return state;
    }

};

export default emailedPbiDashboard;

import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import IScheduleFrequency from "../../models/IScheduleFrequency";
import { IStore } from "../../models/IStore";

export interface IAddScheduleFrequencyProps {
    scheduleFrequencies: IScheduleFrequency[];
    scheduleFrequencyId: number;
    callback: (scheduleFrequencyId: number) => void;
}

export interface IAddScheduleFrequencyState {
    scheduleFrequencyId: number;
}

class AddScheduleFrequency extends React.Component<IAddScheduleFrequencyProps> {
    public state = {
        scheduleFrequencyId: 0
    };
    constructor(props: IAddScheduleFrequencyProps) {
        super(props);
        this.scheduleFrequencyIdChange = this.scheduleFrequencyIdChange.bind(this);
    }
    public scheduleFrequencyIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { callback } = this.props;
        e.preventDefault();
        const scheduleFrequencyId =
            e.target.value === undefined ? 1 : Number(e.target.value as string);
        this.setState(() => ({ scheduleFrequencyId }));
        callback(scheduleFrequencyId);
    };
    public async componentDidUpdate(prevProps: IAddScheduleFrequencyProps) {
        const { scheduleFrequencyId } = this.props;
        if (
            scheduleFrequencyId !== prevProps.scheduleFrequencyId &&
            scheduleFrequencyId !== undefined &&
            scheduleFrequencyId !== 0
        ) {
            await this.setState(() => ({ scheduleFrequencyId }));
        }
    }
    public async componentDidMount() {
        const { scheduleFrequencyId } = this.props;
        if (scheduleFrequencyId !== undefined && scheduleFrequencyId !== 0) {
            await this.setState(() => ({ scheduleFrequencyId }));
        }
    }
    public render() {
        const { scheduleFrequencies } = this.props;
        const { scheduleFrequencyId } = this.state;
        return (
            <FormGroup>
                <Label for="scheduleFrequencyId">Schedule Frequency:</Label>
                <Input
                    id="scheduleFrequencyId"
                    type="select"
                    placeholder="schedule frequency"
                    value={scheduleFrequencyId.toString()}
                    onChange={this.scheduleFrequencyIdChange}
                >
                    <option value='0'>---</option>
                    {scheduleFrequencies.map(cc => (
                        <option key={cc.id} value={cc.id}>
                            {cc.name}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    return {
        callback: ownProps.callback,
        scheduleFrequencyId: ownProps.scheduleFrequencyId,
        scheduleFrequencies: state.scheduleFrequencies
    };
};

export default connect(mapStateToProps)(AddScheduleFrequency);

import ISchedule from "../models/ISchedule";
import { fetchSecureData } from "../utils";

export const saveUpdatedSchedule = async (
  accessToken: string,
  schedule: ISchedule
) => {
  const payload = {
      id: schedule.id,
      name: schedule.name, 
      isEnabled: schedule.isEnabled,
      isActive: schedule.isActive,
      scheduleEventId: schedule.scheduleEventId,
      schedulePeriodId: schedule.schedulePeriodId,
      scheduleFrequencyId: schedule.scheduleFrequencyId,
      schedulePeriodDays: schedule.schedulePeriodDays,
      scheduleFrequencyDays: schedule.scheduleFrequencyDays,
      firstRunDate: schedule.firstRunDate ,
      lastRunDate: schedule.lastRunDate ,
      createdBy: schedule.createdBy,
      updatedBy: schedule.updatedBy
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/Schedule/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as ISchedule;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Schedule/Edit`, err);
  }
  return {} as ISchedule;
};

export default saveUpdatedSchedule;

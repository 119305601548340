import * as React from "react";
import { Badge, Label } from "reactstrap";
//import IUserProfile from "../../models/IUserProfile";
import IScheduleRoleUser from "../../models/IScheduleRoleUser";
import IScheduleRoleUserIds from "../../models/IScheduleRoleUserIds";

export interface IScheduleUsersBadgeProps {
    userIds: IScheduleRoleUserIds[];
    users: IScheduleRoleUser[];
}

const ScheduleUsersBadge = (props: IScheduleUsersBadgeProps) => {
    const { userIds, users } = props;
    var badgeList = [] as IScheduleRoleUser[];


    userIds.map((userId) => {
        var badgeUser: IScheduleRoleUser = users.find(user => userId.roleId === user.roleId && userId.userId === user.userProfileId) as IScheduleRoleUser;
        console.log(badgeUser);
        badgeList.push(badgeUser);
    });

    return (
        <React.Fragment>
            <Label for="grpSelectedScheduleUsers" style={{ fontSize: '1.5em', color: '#028cca', margin: 'margin: 15px 0px 10px 0px', width: '100%' }}>Selected Schedule Users:</Label>
            <div id="grpSelectedScheduleUsers">
                {badgeList.length > 0 &&
                    badgeList.map(id => (
                        <Badge key={id.scheduleId + '/' + id.roleId + '/' + id.userProfileId} className="badge">
                            {id.roleName}/{id.emailAddress}                      </Badge>
                    ))}
            </div>
            {userIds.length === 0 && <div>None</div>}
        </React.Fragment>
    );
};

export default ScheduleUsersBadge;

import { AnyAction } from "redux";
import { SET_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS_LOADED } from "../actions/loadingEditSchedulePaginatedReportParameters";

export const loadingEditSchedulePaginatedReportParameters = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_EDIT_SCHEDULE_PAGINATED_REPORT_PARAMETERS_LOADED:
            return action.editSchedulePaginatedReportParametersLoaded;
        default:
            return state;
    }
};

export default loadingEditSchedulePaginatedReportParameters;

import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";
import Avatar from "../molecules/Avatar";
export interface IProfileBarProps {
  name: string;
  picture: string;
}
const ProfileBar: React.SFC<IProfileBarProps> = (props: IProfileBarProps) => {
    const { name, picture } = props;    
  return (
      <React.Fragment>
          <Avatar name={name} picture={picture}/>
          <Text style={{ color: "#FFF",  paddingLeft: "8px" }}>{name}</Text>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore) => {
 
  return {
      name: state.auth0.getProfile().name,
      picture: state.auth0.getProfile().picture
  };
};

export default connect(mapStateToProps)(ProfileBar);

import * as React from "react";
import NavbarBrand from "../atoms/NavbarBrand";
import HeaderLogoIcon from "../molecules/HeaderLogoIcon";
import Span from "../atoms/Span";

export interface ILogoProps {
  to: string;
}

const Logo: React.SFC<ILogoProps> = (props: ILogoProps) => {
  const { to } = props;
  // tslint:disable-next-line:no-consolew
  return (
    <NavbarBrand to={to}>
      <React.Fragment>
        <Span>
          <React.Fragment>
                     <HeaderLogoIcon />
          </React.Fragment>
        </Span>
      </React.Fragment>
    </NavbarBrand>
  );
};

export default Logo;

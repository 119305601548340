import * as React from "react";
import { connect } from "react-redux";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import Text from "../atoms/Text";

export interface IActiveRoleBadgeProps {
  activeRoleId: number;
  availableRoles: IRole[];
}

const ActiveRoleBadge: React.SFC<IActiveRoleBadgeProps> = (
  props: IActiveRoleBadgeProps
) => {
  const { activeRoleId, availableRoles } = props;
  return (      
      <Text style={{ color: "#eb87bf" }}>
      {
        (availableRoles.find(role => role.id === activeRoleId) as IRole)
          .displayName
      }
      </Text>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    activeRoleId: state.activeRoleId,
    availableRoles: state.userRoles
  };
};
export default connect(mapStateToProps)(ActiveRoleBadge);

import { AnyAction } from "redux";
import { SET_EMAILED_DASHBOARDS_LOADED } from "../actions/loadingEmailedDashboard";

export const loadingEmailedPbiDashboard = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_EMAILED_DASHBOARDS_LOADED:
            return action.loadingEmailedPbiDashboard;
        default:
            return state;
    }
};

export default loadingEmailedPbiDashboard;

import { AnyAction } from "redux";
import { SET_EDIT_SCHEDULE_USERS_LOADED } from "../actions/loadingEditScheduleUsers";

export const loadingEditScheduleUsers = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_SCHEDULE_USERS_LOADED:
      return action.editScheduleUsersLoaded;
    default:
      return state;
  }
};

export default loadingEditScheduleUsers;

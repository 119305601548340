import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Auth from "../../auth/Auth";
import paths, { adminGroupName, kpiAdminGroupName, scheduleAdminGroupName,superAdminGroupName} from "../../constants";
import IGroup from "../../models/IGroup";
import { IStore } from "../../models/IStore";
import Navbar from "../atoms/Navbar";
import NavLink from "../atoms/NavLink";
import NavListItem from "../atoms/NavListItem";
import NavListLeft from "../atoms/NavListLeft";
import NavListRight from "../atoms/NavListRight";
import Span from "../atoms/Span";
import KpiIcon from "../molecules/KpiIcon";
import LoginButton from "../molecules/LoginButton";
import Logo from "../molecules/Logo";
import LogoutButton from "../molecules/LogoutButton";
import ActiveRoleDropDownMenu from "../organisms/ActiveRoleDropDownMenu";
import AdminDropDownMenu from "../organisms/AdminDropDownMenu";
import ProfileModal from "../organisms/ProfileModal";
import SupportModal from "../organisms/SupportModal";
import DashboardIcon from "../molecules/DashboardIcon";
import PaginatedReportIcon from "../molecules/PaginatedReportIcon";
import ReportIcon from "../molecules/ReportIcon";
import SchedulersDropDownMenu from "../organisms/ScheduleDropDownMenu";


export interface ITopNavBarProps {
    auth: Auth;
    isAuthenticated: boolean;
    isSuperAdministrator: boolean;
    isAdministrator: boolean;
    isKpiAdministrator: boolean;
    isScheduleAdministrator: boolean;
    hasMultipleRoles: boolean;
    
}

const TopNavBar: React.SFC<ITopNavBarProps> = (props: ITopNavBarProps) => {
    const {
        isAuthenticated,
        isSuperAdministrator,
        isAdministrator,
        isKpiAdministrator,
        isScheduleAdministrator,        
        auth,
        hasMultipleRoles
    } = props;
         return (
        <React.Fragment>
            {isAuthenticated && (
                <Navbar>
                    <Logo to={paths.root} />
                    <NavListLeft>
                        {isAuthenticated && hasMultipleRoles && (
                            <NavListItem>
                                <ActiveRoleDropDownMenu />
                            </NavListItem>
                        )}

                        {/*{isAuthenticated && (isScheduleAdministrator || isAdministrator || isSuperAdministrator) && (*/}
                        {/*    <NavListItem>*/}
                        {/*        <SchedulersDropDownMenu />*/}
                        {/*    </NavListItem>*/}
                        {/*)}*/}

                        {isAuthenticated && (
                            <React.Fragment>
                                {/*<NavListItem>*/}
                                {/*  <DashboardDropDownMenu />*/}
                                {/*</NavListItem>*/}
                                <NavListItem>
                                    <Link to={paths.listUserDashboards}>
                                        <DashboardIcon />
                                        <Span style={{ color: "#FFF",  paddingLeft: "8px" }}> Dashboards</Span>
                                    </Link>
                                </NavListItem>
                                {/*<NavListItem>*/}
                                {/*    <Link to={paths.listUserPaginatedReports}>*/}
                                {/*        <PaginatedReportIcon />*/}
                                {/*        <Span style={{ color: "#FFF", paddingLeft: "8px" }}>Reports</Span>*/}
                                {/*    </Link>*/}
                                {/*</NavListItem>*/}

                                {/*<NavListItem>*/}
                                {/*    <Link to={paths.listUserReports}>*/}
                                {/*        <ReportIcon />*/}
                                {/*        <Span style={{ color: "#FFF", paddingLeft: "8px" }}>SSRS Reports</Span>*/}
                                {/*    </Link>*/}
                                {/*</NavListItem>*/}

                                <NavListItem>
                                         <Link to={paths.listUserUnitedReports}>
                                             <PaginatedReportIcon />
                                             <Span style={{ color: "#FFF", paddingLeft: "8px" }}>Reports</Span>
                                       </Link>
                               </NavListItem>

                                {/*<NavListItem>*/}
                                {/*   <PaginatedReportDropDownMenu />*/}
                                {/*</NavListItem>*/}
                                {/*<NavListItem>*/}
                                {/*  <ReportDropDownMenu />*/}
                                {/*</NavListItem>*/}
                            </React.Fragment>
                        )}
                        {isAuthenticated && (isAdministrator || isSuperAdministrator) && (
                            <NavListItem>
                                <AdminDropDownMenu />
                            </NavListItem>
                        )}
                        {isAuthenticated && isKpiAdministrator && (
                            <NavListItem>
                                <Link to={paths.kpitargets}>
                                    <KpiIcon />
                                    <Span style={{ color: "#FFF", paddingLeft: "8px" }}>KPIs</Span>
                                </Link>
                            </NavListItem>
                        )}
                    </NavListLeft>

                    {isAuthenticated && (
                        <NavListRight> 
                            <NavListItem>
                                <NavLink to="#">
                                    <ProfileModal />
                                </NavLink>
                            </NavListItem> 
                            <NavListItem>
                                <NavLink to="#">
                                    <SupportModal />
                                </NavLink>
                            </NavListItem> 
                            <NavListItem>
                                <NavLink to="#">
                                    <LogoutButton
                                        // tslint:disable-next-line:jsx-no-lambda
                                        logout={() => {
                                            auth.logout();
                                        }}
                                    /> 
                                </NavLink>
                            </NavListItem>
                        </NavListRight>
                    )}
                </Navbar>
            )
            }
        </React.Fragment>
    );
};
const mapStateToProps = (state: IStore) => {
    const adminGroup = (state.userGroups as IGroup[]).find(
        ug => ug.name === adminGroupName
    );
    const kpiAdminGroup = (state.userGroups as IGroup[]).find(
        ug => ug.name === kpiAdminGroupName
    );
    const scheduleAdminGroup = (state.userGroups as IGroup[]).find(
        ug => ug.name === scheduleAdminGroupName
    );
    const superAdminGroup = (state.userGroups as IGroup[]).find(
        ug => ug.name === superAdminGroupName
    );
    return {
        auth: state.auth0,
        isAuthenticated: state.auth0.isAuthenticated(),
        // tslint:disable-next-line:object-literal-sort-keys
        isSuperAdministrator: superAdminGroup !== undefined,
        isAdministrator: adminGroup !== undefined,
        isKpiAdministrator: kpiAdminGroup !== undefined,
        isScheduleAdministrator: scheduleAdminGroup !== undefined,        
        hasMultipleRoles: state.userRoles.length > 1 ? true : false
    };
};
export default connect(mapStateToProps)(TopNavBar);

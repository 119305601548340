import * as Redux from "redux";
import getSchedulePeriods from "../api/getSchedulePeriods";
import ISchedulePeriod from "../models/ISchedulePeriod";
import { setSchedulePeriodsLoaded } from "./loadingSchedulePeriods";

//export const ADD_SCHEDULE_PERIOD = "ADD_SCHEDULE_PERIOD";
//export const REMOVE_SCHEDULE_PERIOD = "REMOVE_SCHEDULE_PERIOD";
//export const UPDATE_SCHEDULE_PERIOD = "UPDATE_SCHEDULE_PERIOD";
export const LOAD_SCHEDULE_PERIODS = "LOAD_SCHEDULE_PERIODS";

//export const addSchedulePeriod = (schedulePeriod: ISchedulePeriod) => {
//  return {
//    schedulePeriod,
//    type: ADD_SCHEDULE_PERIOD
//  };
//};

//export const removeSchedulePeriod = (id: number) => {
//  return {
//    id,
//    type: REMOVE_SCHEDULE_PERIOD
//  };
//};

//export const updateSchedulePeriod = (schedulePeriod: ISchedulePeriod) => {
//  return {
//    schedulePeriod,
//    type: UPDATE_SCHEDULE_PERIOD
//  };
//};

export const loadSchedulePeriods = (schedulePeriods: ISchedulePeriod[]) => {
  return {
    schedulePeriods,
    type: LOAD_SCHEDULE_PERIODS
  };
};

export const handleLoadSchedulePeriods = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const schedulePeriods = await getSchedulePeriods(accessToken);
    if (schedulePeriods !== undefined) {
      if (schedulePeriods.length > 0) {
        schedulePeriods.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        dispatch(loadSchedulePeriods(schedulePeriods));
      }
      dispatch(setSchedulePeriodsLoaded(true));
    }
  };
};

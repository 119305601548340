import * as Redux from "redux";
import getSchedules from "../api/getSchedules";
import saveSchedule from "../api/saveSchedule";
import saveUpdatedSchedule from "../api/saveUpdatedSchedule";
import { removeSchedule as deleteSchedule } from "../api/removeSchedule";
import ISchedule from "../models/ISchedule";
import { clearEditScheduleDetails, setEditScheduleDetails } from "./editScheduleDetails";
import { setSchedulesLoaded } from "./loadingSchedules";
import { copySchedule as duplicateSchedule } from "../api/copySchedule";
import { executeSchedule } from "../api/executeSchedule";


export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const COPY_SCHEDULE = "COPY_SCHEDULE";
export const REMOVE_SCHEDULE = "REMOVE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const LOAD_SCHEDULES = "LOAD_SCHEDULES";
export const GET_EDIT_SCHEDULE_DETAILS_SUMMARY = "GET_EDIT_SCHEDULE_DETAILS_SUMMARY";
export const RUN_SCHEDULE = "RUN_SCHEDULE";


export const addSchedule = (schedule: ISchedule) => {
    return {
        schedule,
        type: ADD_SCHEDULE
    };
};

export const copySchedule = (id: number, userId: number) => {
    return {
        id,
        userId,
        type: COPY_SCHEDULE
    };
};

export const removeSchedule = (id: number) => {
    return {
        id,
        type: REMOVE_SCHEDULE
    };
};

export const updateSchedule = (schedule: ISchedule) => {
    return {
        schedule,
        type: UPDATE_SCHEDULE
    };
};

export const loadSchedules = (schedules: ISchedule[]) => {
    return {
        schedules,
        type: LOAD_SCHEDULES
    };
};


export const loadSummary = (id: number) => {
    return {
        id,
        type: GET_EDIT_SCHEDULE_DETAILS_SUMMARY
    };
};


export const runSchedule = (message: string) => {
    return {
        message,
        type: RUN_SCHEDULE
    };
};


export const handleSaveSchedule = (
    accessToken: string,
    name: string,
    isActive: boolean,
    isEnabled: boolean,
    userId:number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const schedule = await saveSchedule(
            accessToken,
            name,
            isActive,
            isEnabled,
            userId
        );
        if (schedule !== null && schedule !== undefined) { 
            dispatch(addSchedule(schedule));
            dispatch(setEditScheduleDetails(schedule));
        }
    };
};

export const handleUpdateSchedule = (accessToken: string, schedule: ISchedule) => {
    return async (dispatch: Redux.Dispatch) => {
        const dsh = await saveUpdatedSchedule(accessToken, schedule);
        if (dsh !== null && dsh !== undefined) {
            dispatch(updateSchedule(schedule));
            dispatch(setEditScheduleDetails(schedule));
        }
    };
};

export const handleDeleteSchedule = (accessToken: string, id: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const result = await deleteSchedule(accessToken, id);
        if (result === 200) {
            dispatch(removeSchedule(id));
            dispatch(clearEditScheduleDetails());
        }
    };
};




export const handleDuplicateSchedule = (accessToken: string, id: number, userId: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const  result = await duplicateSchedule(accessToken, id, userId);
        if (result === 200) {
            dispatch(copySchedule(id, userId));
            dispatch(clearEditScheduleDetails());
        }
    };
};

export const handleLoadSchedules = (accessToken: string, userId:number) => {
    return async (dispatch: Redux.Dispatch) => {
        const schedules = await getSchedules(accessToken, userId);
        if (schedules !== undefined) {
            if (schedules.length > 0) {
                schedules.sort((a, b) =>
                    a.id < b.id ? 1 : -1
                );
                dispatch(loadSchedules(schedules));
            }
            dispatch(setSchedulesLoaded(true));
        }
    };
};


export const handleExecuteSchedule = (accessToken: string, eventId: number, scheduleId:number, userId: number) => {
    return async (dispatch: Redux.Dispatch) => {
        const result = await executeSchedule(accessToken, eventId, scheduleId, userId);
        return result;
       // dispatch(runSchedule(result));
    };
};
import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import IScheduleEvent from "../../models/IScheduleEvent";
import { IStore } from "../../models/IStore";

export interface IAddScheduleEventProps {
  scheduleEvents: IScheduleEvent[];
  scheduleEventId: number;
  callback: (scheduleEventId: number) => void;
}

export interface IAddScheduleEventState {
  scheduleEventId: number;
}

class AddScheduleEvent extends React.Component<IAddScheduleEventProps> {
  public state = {
    scheduleEventId: 0
  };
  constructor(props: IAddScheduleEventProps) {
    super(props);
    this.scheduleEventIdChange = this.scheduleEventIdChange.bind(this);
  }
  public scheduleEventIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { callback } = this.props;
    e.preventDefault();
    const scheduleEventId =
      e.target.value === undefined ? 1 : Number(e.target.value as string);
    this.setState(() => ({ scheduleEventId }));
    callback(scheduleEventId);
  };
  public async componentDidUpdate(prevProps: IAddScheduleEventProps) {
    const { scheduleEventId } = this.props;
    if (
      scheduleEventId !== prevProps.scheduleEventId &&
      scheduleEventId !== undefined &&
      scheduleEventId !== 0
    ) {
      await this.setState(() => ({ scheduleEventId }));
    }
  }
  public async componentDidMount() {
    const { scheduleEventId } = this.props;
    if (scheduleEventId !== undefined && scheduleEventId !== 0) {
      await this.setState(() => ({ scheduleEventId }));
    }
  }
  public render() {
    const { scheduleEvents } = this.props;
    const { scheduleEventId } = this.state;
    return (
      <FormGroup>
        <Label for="scheduleEventId">Schedule Event:</Label>
        <Input
          id="scheduleEventId"
          type="select"
          placeholder="scheduleEvent"
          value={scheduleEventId.toString()}
          onChange={this.scheduleEventIdChange}
            >
            <option value='0'>---</option>
          {scheduleEvents.map(cc => (
            <option key={cc.id} value={cc.id}>
              {cc.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    callback: ownProps.callback,
    scheduleEventId: ownProps.scheduleEventId,
    scheduleEvents: state.scheduleEvents
  };
};

export default connect(mapStateToProps)(AddScheduleEvent);

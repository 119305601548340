import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlQaEuProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlQaEu extends React.Component<ICallbackSamlQaEuProps> {
  public constructor(props: ICallbackSamlQaEuProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

      auth.loginSAMLQaEu().then(() => {
        console.log("Login via SAML QA Europe");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlQaEu);
